const httpClient = () => {
  const get = async (url: string) => {
    const resp = await fetch(url, {});
    if (!String(resp?.status).startsWith('2')) {
      const res = await resp.json().catch(() => {});
      throw new Error(res.message || resp.statusText);
    }
    const res = await resp.text();
    return res ? JSON.parse(res) : res;
  };

  const put = async (url: string, body: any) => {
    const resp = await fetch(url, {
      method: 'PUT',
      body: typeof body === 'string' ? body : JSON.stringify(body),
    });
    if (!String(resp?.status).startsWith('2')) {
      const res = await resp.json().catch(() => {});
      throw new Error(res.message || resp.statusText);
    }
    const res = await resp.text();
    return res ? JSON.parse(res) : res;
  };

  return { get, put };
};

export default httpClient();
