import { NotFound } from '@chiroup/components';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import RequestComplete from './RequestComplete';
import RequestStepComponent from './RequestStep';
import RequestStepper from './RequestStepper';
import { Genders } from '@chiroup/core/enums/Genders.enum';
import { RequestStep } from '@chiroup/core/types/Request.type';
import { CarePlanComplaint } from '@chiroup/core/types/PatientVisit.type';

const verbiage = {
  en: {
    exit: 'Exit',
    sendTo: 'Send to phone/email',
    logout: 'Logout',
  },
  es: {
    exit: 'Volver',
    sendTo: 'Enviar a teléfono/correo electrónico',
    logout: 'Cerrar sesión',
  },
};

type Props = {
  data?: {
    age: number;
    ccsFollowups?: string[];
    complaints?: Partial<CarePlanComplaint>;
    gender: Genders;
    guardian: boolean;
    id: string;
    language: 'en' | 'es';
    prMap: { [key: string]: string };
    steps: RequestStep[];
    clinicId?: number;
  };
  forwardMethod?: () => void;
  guardianName?: string;
  handleReset: (changeScreen?: boolean) => void;
  hideButtons?: boolean;
  id?: string;
  kiosk: boolean;
  loading?: boolean;
  methods?: { phone: string; email: string; language: string };
  patientName?: string;
  updateRequest?: (
    data: {
      age: number;
      gender: Genders;
      id: string;
      language: 'en' | 'es';
      prMap: { [key: string]: string };
      steps: RequestStep[];
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  weeks?: number;
  encounterId: string;
};

const Request: React.FC<Props> = ({
  data,
  id,
  updateRequest,
  kiosk,
  validationCode,
  patientName,
  guardianName,
  handleReset,
  forwardMethod,
  methods,
  loading,
  hideButtons,
  weeks,
  encounterId,
}) => {
  const {
    clinicInfo: { primaryColor, accentColor },
  } = useContext(MeContext);
  const navigate = useNavigate();
  const [steps, setSteps] = useState<RequestStep[]>();
  const [remainingSteps, setRemainingSteps] = useState<number[]>([]);
  const [requestComplete, setRequestComplete] = useState(false);
  const [surveyIds, setSurveyids] = useState<string[]>();

  useEffect(() => {
    if (data) {
      const sorted = data.steps
        ?.map((step) => {
          return {
            ...step,
            disabled:
              (step.type === 'uploadPhotoId' ||
                step.type === 'uploadInsuranceCard' ||
                step.type === 'insurance') &&
              kiosk,
          };
        })
        ?.sort((a, b) => (a.survey?.order || 1) - (b.survey?.order || 1))
        .sort((a, b) => {
          return Number(a.disabled) - Number(b.disabled);
        });
      setSteps(sorted?.map((step, i) => ({ ...step, stepNumber: i + 1 })));
    }
  }, [data, kiosk]);

  useEffect(() => {
    if (steps) {
      setRemainingSteps(
        steps.reduce((arr: number[], step, i) => {
          if (!step.complete && !step.disabled) {
            arr.push(i + 1);
          }
          return arr;
        }, []),
      );
      setRequestComplete(steps.every((step) => step.complete));
    }
  }, [steps]);

  const nextStep = (stepId?: string) => {
    const nextStepIndex = stepId
      ? steps?.findIndex(
          (step) =>
            !step.complete &&
            !step.disabled &&
            step?.patientSurveyId !== stepId,
        )
      : steps?.findIndex((step) => !step.complete && !step.disabled);
    const nextStepNumber =
      typeof nextStepIndex !== 'undefined' ? nextStepIndex + 1 : 0;
    if (!nextStepNumber) {
      return navigate(kiosk ? '/kiosk' : `/requests/${id}`);
    }
    return navigate(
      kiosk ? `/kiosk/${nextStepNumber}` : `/requests/${id}/${nextStepNumber}`,
    );
  };

  useEffect(() => {
    if (steps) {
      const incomingSurveys = steps.reduce(
        (arr: string[], step: RequestStep) => {
          arr.push(step.survey?.id || '');
          return arr;
        },
        [],
      );
      setSurveyids(incomingSurveys);
    }
  }, [steps]);

  const complete = async (stepNumber: number, goToNext = false) => {
    setSteps(
      (prev) =>
        prev?.map((step) => ({
          ...step,
          complete: step.stepNumber === stepNumber ? true : step.complete,
        })),
    );
    kiosk ? navigate('/kiosk') : navigate(`/requests/${data?.id}`);
    //logic below if they want to show the thank you page instead of going back to the stepper
    // if (goToNext) {
    //   const nextStepIndex = steps?.findIndex(
    //     (step) =>
    //       !step.complete && !step.disabled && step.stepNumber !== stepNumber,
    //   );
    //   const nextStepNumber =
    //     typeof nextStepIndex !== 'undefined' ? nextStepIndex + 1 : 0;
    //   if (!nextStepNumber) {
    //     return navigate(kiosk ? '/kiosk' : `/requests/${id}`);
    //   }
    //   return navigate(
    //     kiosk
    //       ? `/kiosk/${nextStepNumber}`
    //       : `/requests/${id}/${nextStepNumber}`,
    //   );
    // }
  };

  const exit = () => {
    kiosk ? navigate('/kiosk') : navigate(`/requests/${data?.id}`);
  };

  if (!data) {
    return (
      <NotFound
        title="Request not found"
        description="The request that you are trying to access does not exist. Please contact your provider for assitance."
      />
    );
  }

  if (requestComplete || !data.steps?.length) {
    return (
      <RequestComplete
        language={data.language}
        kiosk={kiosk}
        complete={requestComplete}
        steps={steps}
      />
    );
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="w-full">
            <div className="relative flex items-center justify-center sm:min-h-screen">
              {kiosk && methods && !hideButtons ? (
                <div className="absolute top-8 right-8">
                  <span className="inline-flex rounded-md isolate">
                    <button
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out border border-gray-100 cursor-pointer rounded-l-md hover:opacity-90 focus:ring-none focus:outline-none"
                      style={{
                        width: 'fit-content',
                        backgroundColor: accentColor,
                      }}
                      onClick={() => forwardMethod?.()}
                      type="button"
                    >
                      {loading && (
                        <svg
                          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      )}
                      {verbiage[data.language === 'es' ? 'es' : 'en'].sendTo}
                    </button>
                    <button
                      className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-white transition duration-150 ease-in-out border border-gray-100 cursor-pointer rounded-r-md hover:opacity-90 focus:ring-none focus:outline-none"
                      style={{
                        width: 'fit-content',
                        backgroundColor: primaryColor,
                      }}
                      onClick={() => {
                        handleReset(true);
                      }}
                      type="button"
                    >
                      {verbiage[data.language === 'es' ? 'es' : 'en'].logout}
                    </button>
                  </span>
                </div>
              ) : kiosk ? (
                <div className="absolute top-8 right-8">
                  <button
                    className="px-3 py-2 text-base font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow cursor-pointer hover:opacity-90 focus:outline-none focus:ring sm:mt-0 sm:w-auto sm:text-sm"
                    style={{
                      width: 'fit-content',
                      backgroundColor: primaryColor,
                    }}
                    onClick={() => {
                      handleReset(true);
                    }}
                    type="button"
                  >
                    {verbiage[data.language === 'es' ? 'es' : 'en'].logout}
                  </button>
                </div>
              ) : null}
              <div className="flex items-center justify-center px-4 mt-20 sm:mt-0">
                <div className="max-w-4xl py-8">
                  {steps && (
                    <RequestStepper
                      validationCode={validationCode}
                      steps={steps}
                      nextStep={nextStep}
                      language={data.language}
                      id={id}
                      kiosk={kiosk}
                      handleReset={handleReset}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      />
      {steps?.map((step, i) => (
        <Route
          key={i}
          path={`/${i + 1}`}
          element={
            <div className="w-full">
              <div className="flex items-center justify-center px-4 sm:min-h-screen">
                <div className="flex flex-col w-full sm:min-h-full">
                  <div className="absolute inset-x-0 top-0 z-10 flex justify-between px-4 py-4">
                    <div className="flex sm:justify-center">
                      <div className="cursor-pointer" onClick={exit}>
                        <div className="flex items-center text-center">
                          <svg
                            className="w-5 h-5 mr-1"
                            style={{
                              color: primaryColor,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>

                          <div className="text-base font-medium leading-6 text-gray-500">
                            {
                              verbiage[data.language === 'es' ? 'es' : 'en']
                                .exit
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <div className="flex items-center text-center">
                        <div className="text-base font-medium text-gray-900">
                          {step.stepNumber}
                        </div>
                        <svg
                          className="w-5 h-5 mx-1"
                          style={{
                            color: primaryColor,
                          }}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M11 0h3L9 20H6l5-20z" />
                        </svg>

                        <div className="text-base font-medium leading-6 text-gray-500">
                          {steps.length}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!!id && (
                    <RequestStepComponent
                      steps={steps}
                      validationCode={validationCode}
                      step={step}
                      requestId={id}
                      complete={async (goToNext = false) => {
                        await complete(i + 1, goToNext);
                      }}
                      language={data.language}
                      gender={data.gender}
                      age={data.age}
                      guardian={data.guardian}
                      nextStep={nextStep}
                      ccsFollowups={data.ccsFollowups}
                      updateRequest={updateRequest}
                      surveyIds={surveyIds}
                      kiosk={kiosk}
                      guardianName={guardianName}
                      patientName={patientName}
                      prMap={data.prMap}
                      complaints={data.complaints}
                      weeks={weeks}
                      remainingSteps={remainingSteps}
                      encounterId={encounterId}
                      clinicId={data?.clinicId}
                    />
                  )}
                </div>
              </div>
            </div>
          }
        />
      ))}
      <Route
        path="*"
        element={<span>The route you requested does not exist.</span>}
      />
    </Routes>
  );
};

export default Request;
