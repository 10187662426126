import { Input } from '@chiroup/components';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { questionContentVerbiage } from './SurveyQuestionContent';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import {
  SurveyOption,
  SurveyQuestionAnswer,
} from '@chiroup/core/types/Survey.type';

type Props = {
  id: string;
  options?: SurveyOption[];
  onSelect: (val: SurveyQuestionAnswer) => Promise<void>;
  disabled: boolean;
  includeOther?: boolean;
  hideNext: boolean;
  language?: 'en' | 'es';
  primaryColor: string;
};

export const SurveyQuestionCheckbox: React.FC<Props> = ({
  id,
  options,
  onSelect,
  disabled,
  includeOther,
  hideNext = false,
  language = 'en',
  primaryColor,
}) => {
  const [val, setVal] = useState<{
    [key: string]: string;
  }>({});
  const [showOther, setShowOther] = useState(false);
  // const [veryDisabled, setVeryDisabled] = useState(false);
  const [otherValue, setOtherValue] = useState<string>();
  const otherRef = useRef<HTMLInputElement>(null);

  const onCheck = async (e: ChangeEvent<HTMLInputElement>) => {
    const checkboxValue: string = e.target.value;
    const isOther = checkboxValue === 'other';
    if (isOther) {
      setShowOther((prev) => !prev);
    }
    const newVal = {
      ...val,
    };
    if (hasProperty(newVal, checkboxValue)) {
      delete newVal[checkboxValue];
    } else {
      newVal[checkboxValue] = checkboxValue;
    }
    setVal(newVal);
    if (hideNext) {
      const valueToSend = Object.keys(val);
      const dataForSend = Object.entries(newVal)
        .filter(([key, value]) => key !== value)
        .reduce((obj: { [key: string]: string }, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
      await onSelect({
        type: 'checkbox',
        value: valueToSend,
        otherValue,
        data: dataForSend,
      });
      setVal({});
    }
  };

  useEffect(() => {
    if (showOther) {
      otherRef.current?.focus();
    } else {
      setOtherValue(undefined);
    }
  }, [showOther]);

  const onChangeInput = (id: string, newValue: string) => {
    setVal((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  };

  const onChangeOther = async (newOtherValue: string) => {
    setOtherValue(newOtherValue);
    if (hideNext) {
      const valueToSend = Object.keys(val);
      await onSelect({
        type: 'checkbox',
        value: valueToSend,
        otherValue: newOtherValue,
      });
      setVal({});
    }
  };

  const handleSubmit = async () => {
    const valueToSend = Object.keys(val);
    const dataForSend = Object.entries(val)
      .filter(([key, value]) => key !== value)
      .reduce((obj: { [key: string]: string }, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    await onSelect({
      type: 'checkbox',
      value: valueToSend,
      otherValue,
      data: dataForSend,
    });
    setVal({});
  };

  return (
    <>
      {options?.map((option, optionIndex: number) => (
        <div
          key={optionIndex}
          className={['flex items-center mb-2', optionIndex ? 'mt-4' : ''].join(
            ' ',
          )}
        >
          <input
            id={`question-${id}-option-${optionIndex}`}
            name={`question-${id}`}
            type="checkbox"
            className="form-checkbox h-10 w-10 transition duration-150 ease-in-out focus:ring-0"
            style={{
              color: primaryColor,
            }}
            onChange={onCheck}
            value={option.id}
            checked={!!(option.id && hasProperty(val, String(option.id)))}
            disabled={disabled}
            data-cy={option.display}
          />
          {option.data?.askForInput &&
          !!(option.id && hasProperty(val, String(option.id))) ? (
            <Input
              name={`question-${id}-input`}
              className="ml-3 w-full md:w-96"
              value={String(
                option.id &&
                  typeof val[option.id] === 'string' &&
                  val[option.id] !== option.id
                  ? val[option.id]
                  : '',
              )}
              onChange={onChangeInput.bind(null, String(option.id))}
              disabled={disabled}
              autoFocus
              placeholder={option.data?.inputPlaceholder}
            />
          ) : (
            <label
              htmlFor={`question-${id}-option-${optionIndex}`}
              className="ml-3"
            >
              <span className="block leading-5">{option.display}</span>
            </label>
          )}
        </div>
      ))}
      {includeOther && (
        <div className="flex flex-col mt-4">
          <div className="flex items-center">
            <input
              id={`question-${id}-option-other`}
              name={`question-${id}`}
              type="checkbox"
              className="form-checkbox h-10 w-10 transition duration-150 ease-in-out focus:ring-0"
              style={{
                color: primaryColor,
              }}
              onChange={onCheck}
              value="other"
              disabled={disabled}
            />
            {showOther ? (
              <Input
                name="other"
                className="ml-3 w-full md:w-96 "
                value={otherValue}
                onChange={onChangeOther}
                disabled={disabled}
                ref={otherRef}
              />
            ) : (
              <label htmlFor={`question-${id}-option-other`} className="ml-3">
                <span className="block leading-5">
                  {language === 'es' ? 'Otro' : 'Other'}
                </span>
              </label>
            )}
          </div>
        </div>
      )}
      {!hideNext && (
        <button
          disabled={disabled}
          onClick={handleSubmit}
          className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8"
          style={{
            backgroundColor: primaryColor,
          }}
          data-cy={
            !Object.keys(val)?.length
              ? 'none-of-the-above-button'
              : 'next-button'
          }
        >
          {!Object.keys(val)?.length
            ? questionContentVerbiage[language === 'es' ? 'es' : 'en']
                .noneOfTheAbove
            : questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
        </button>
      )}
    </>
  );
};
