import {
  Input,
  InputMasked,
  LegalGuardianFields,
  Phone,
  Select,
  Toggle,
  ZipCodeInput,
} from '@chiroup/components';
import {
  GLOBAL_COUNTRY_OPTIONS,
  GLOBAL_MORE_LANGUAGE_OPTIONS,
  GLOBAL_PREFERS_OPTIONS,
  GLOBAL_PREFIX_OPTIONS,
  GLOBAL_PRONOUN_OPTIONS,
  GLOBAL_PRONOUN_SPANSIH_OPTIONS,
  GLOBAL_RACE_OPTIONS,
  GLOBAL_RACE_SPANISH_OPTIONS,
  GLOBAL_SEX_AND_GENDER_OPTIONS,
  GLOBAL_SEX_AND_GENDER_SPANISH_OPTIONS,
  GLOBAL_SUFFIX_OPTIONS,
} from '@chiroup/core/constants/globals';
import {
  CANADIAN_PROVINCES,
  STATES,
} from '@chiroup/core/constants/UsStatesAndTerritories';
import { Languages } from '@chiroup/core/enums/Languages.enum';
import { getAgeInYears, isRealDate } from '@chiroup/core/functions/ageInYears';
import { classNames } from '@chiroup/core/functions/classNames';
import { Patient } from '@chiroup/core/types/Patient.type';
import { RequestStep } from '@chiroup/core/types/Request.type';
import { EmailValidator, useForm } from '@chiroup/hooks';
import React, { useContext, useEffect, useMemo } from 'react';
import { MeContext } from '../../../contexts/me.context';
import requestService from '../../../services/request.service';
import Button from '../../common/Button';
import HealthcareProviderHealthcomSelect from '../../common/selects/HealthcareProviderHealthcomSelect';

const validation = {
  fname: {
    required: {
      message: 'Legal first name is required.',
    },
  },
  lname: {
    required: {
      message: 'Legal last name is required.',
    },
  },
  email: {
    pattern: EmailValidator,
  },
  address1: {
    required: {
      message: 'Address is required.',
    },
  },
  city: {
    required: {
      message: 'City is required.',
    },
  },
  state: {
    required: {
      message: 'State/Province is required.',
    },
  },
  // country: {
  //   required: {
  //     message: 'Country is required.',
  //   },
  // },
  zip: {
    function: {
      value: (value: Partial<Patient>) => {
        const { zip, country } = value;
        if (!zip) {
          return country === 'USA'
            ? 'Zip code is required.'
            : 'Postal code is required.';
        }
        if (country === 'USA' && !!zip) {
          if (zip.length !== 5 && zip.length !== 10) {
            return 'Zip code must be 5 or 9 digits.';
          }
        }
        return false;
      },
    },
  },
  // idNumber: {
  //   required: {
  //     message: 'ID number is required.',
  //   },
  // },
  dateOfBirth: {
    function: {
      value: (values: Partial<Patient>) => {
        const { dateOfBirth } = values;
        if (dateOfBirth && !Date.parse(dateOfBirth)) {
          return 'Please enter date of birth in the format DD/MM/YYYY';
        }
        if (
          dateOfBirth?.length === 10 &&
          Date.parse(dateOfBirth) > Date.now()
        ) {
          return 'Please enter a valid date of birth';
        }
        return false;
      },
    },
    socialSecurityNumber: {
      function: {
        value: (values: Partial<Patient>) => {
          const { socialSecurityNumber } = values;
          if (socialSecurityNumber && socialSecurityNumber.length !== 11) {
            return 'Social Security Number must be 9 digits.';
          }
          return false;
        },
      },
    },
  },
  gender: {
    required: {
      message: 'Gender is required.',
    },
  },
  emergencyContactName: {
    required: {
      message: 'Emergency Contact Name is required.',
    },
  },
  emergencyContactPhone: {
    required: {
      message: 'Emergency Contact Phone Number is required.',
    },
  },
  previouslyTreated: {
    required: {
      message:
        'Answering the question about previous chiropractic care is required.',
    },
  },
  phone: {
    function: {
      value: (value: Partial<Patient>) => {
        const { phone } = value;
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
  legalGuardianName: {
    function: {
      value: (value: Partial<Patient>) => {
        if (value.guardian && !value.legalGuardianName) {
          return 'Legal guardian name is required.';
        }
        return false;
      },
    },
  },
  legalGuardianRelationship: {
    function: {
      value: (value: Partial<Patient>) => {
        if (value.guardian && !value.legalGuardianRelationship) {
          return 'Legal guardian relationship is required.';
        }
        return false;
      },
    },
  },
};

type Props = {
  step: RequestStep;
  requestId: string;
  complete: () => Promise<void>;
  nextStep: (id?: string) => void;
  validationCode: string | number;
  age: number;
  prMap: { [key: string]: string };
  language?: string;
};

const BasicInformation: React.FC<Props> = ({
  step,
  requestId,
  complete,
  nextStep,
  validationCode,
  age,
  prMap,
  language = 'en',
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const { value, registerSubmit, errors, onChange, isSubmitting, patchValue } =
    useForm<Patient>(
      {
        language: language === 'es' ? Languages.es : Languages.en,
        country: 'USA',
      },
      validation,
    );

  const onSubmit = async (val: Partial<Patient>) => {
    const provider = val.primaryProvider as any;
    if (provider?.$add) {
      val.primaryProviderText = provider?.$add;
      val.primaryProvider = undefined;
    }
    await requestService.submitInformation(
      requestId,
      val,
      validationCode,
      prMap,
    );
    return val;
  };

  const onSuccess = async () => {
    await complete();
  };

  useEffect(() => {
    if (step.complete) {
      nextStep();
    }
  }, [step.complete, nextStep]);

  // commented out in case we decide to bring it back...
  // useEffect(() => {
  //   if (photoIdData) {
  //     delete photoIdData.idNumber;
  //     patchValue(photoIdData);
  //   }
  // }, [patchValue, photoIdData]);
  const fieldsetClassName = [
      'border-2',
      'border-gray-300',
      'rounded-md',
      'pt-2',
      'pb-4',
      'px-4',
      'mb-4',
      'space-y-1',
      'sm:space-y-0',
      'sm:grid',
      'sm:grid-cols-12',
      'sm:gap-4',
    ].join(' '),
    legendClassName = [
      'text-base',
      'font-medium',
      'text-gray-400',
      'px-2',
    ].join(' ');

  const spanish = language === 'es';

  const handleAgeChange = (e: string) => {
    patchValue({
      dateOfBirth: e,
    });
    if (isRealDate(e || '')) {
      const age = getAgeInYears(e || '');
      if (age < 18) {
        patchValue({
          guardian: true,
        });
      }
    }
  };

  const ageInYears = useMemo(() => {
    if (!value.dateOfBirth) {
      return null;
    }
    return getAgeInYears(value.dateOfBirth);
  }, [value.dateOfBirth]);

  return (
    <div className="flex items-center justify-center px-4 sm:min-h-screen mt-8 sm:mt-0">
      <div className="max-w-4xl py-8">
        <h2 className="text-4xl tracking-tight leading-8 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          <span className="flex flex-row">
            {spanish && step?.esTitle ? step?.esTitle : step.title}
            {step.complete && (
              <svg
                className="ml-2 h-10 w-10 place-self-center opacity-50"
                style={{
                  color: primaryColor,
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>
        </h2>
        <h3 className="text-xl tracking-tight leading-6 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-4xl mt-2">
          <span style={{ color: primaryColor }}>
            {spanish && step?.esDescription
              ? step.esDescription
              : step.description}
          </span>
        </h3>
        {spanish ? (
          'Nuestra oficina necesita una poco mas de información acerca de usted. Por favor responda las siguientes preguntas lo mejor que pueda.'
        ) : (
          <p className="mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Our office needs a little more information about you. Please
            complete the following questions to the best of your ability.
          </p>
        )}

        <fieldset className={fieldsetClassName + ' mt-8'}>
          <legend className={legendClassName}>
            {spanish ? 'Nombre' : 'Name'}
          </legend>
          <Select
            name="prefix"
            className="col-span-2"
            label={spanish ? `Prefijo*` : `Prefix`}
            value={value.prefix}
            onChange={onChange('prefix')}
            errors={errors.fieldErrors?.prefix}
            options={GLOBAL_PREFIX_OPTIONS}
            limit={1}
            noDarkMode
          />
          <Input
            name="fname"
            className="sm:col-span-3"
            label={spanish ? `Primer nombre legal*` : `Legal First Name*`}
            value={value.fname}
            onChange={onChange('fname')}
            errors={errors.fieldErrors?.fname}
          />
          <Input
            name="mname"
            className="sm:col-span-2"
            label={spanish ? 'Segundo nombre' : 'Middle Name'}
            value={value.mname}
            onChange={onChange('mname')}
            errors={errors.fieldErrors?.mname}
          />
          <Input
            name="lname"
            className="sm:col-span-3"
            label={spanish ? 'Apellido legal*' : 'Legal Last Name*'}
            value={value.lname}
            onChange={onChange('lname')}
            errors={errors.fieldErrors?.lname}
          />
          <Select
            name="suffix"
            className="col-span-2"
            label={spanish ? 'Sufijo' : 'Suffix'}
            value={value.suffix}
            onChange={onChange('suffix')}
            errors={errors.fieldErrors?.suffix}
            options={GLOBAL_SUFFIX_OPTIONS}
            limit={1}
            noDarkMode
          />
          <Input
            name="pname"
            className="sm:col-span-6"
            label={spanish ? 'Nombre' : 'Preferred name'}
            value={value.pname}
            onChange={onChange('pname')}
            errors={errors.fieldErrors?.pname}
          />
          <Select
            name="pronouns"
            className={classNames(
              value.pronouns === 'Other' ? 'col-span-3' : 'col-span-6',
            )}
            label={spanish ? 'Pronombres' : 'Pronouns'}
            value={value.pronouns}
            onChange={(val) => {
              onChange('pronouns')(val);
            }}
            errors={errors.fieldErrors?.pronouns}
            options={
              spanish ? GLOBAL_PRONOUN_SPANSIH_OPTIONS : GLOBAL_PRONOUN_OPTIONS
            }
            autocomplete
            limit={1}
            tooltip={value?.pronouns === 'Other' ? value?.otherPronouns : ''}
            noDarkMode
          />
          <Input
            name="otherPronouns"
            className={[
              'sm:col-span-3',
              ['Other'].includes(value.pronouns || '') ? 'block' : 'hidden',
            ].join(' ')}
            label={spanish ? 'Otro' : 'Other Pronouns'}
            value={value.otherPronouns}
            onChange={onChange('otherPronouns')}
            errors={errors.fieldErrors?.otherPronouns}
          />
        </fieldset>
        <fieldset className={fieldsetClassName}>
          <legend className={legendClassName}>
            {spanish ? 'Sobre el paciente' : 'About Patient'}
          </legend>
          {!step.suppress?.includes('dateOfBirth') && (
            <InputMasked
              className="sm:col-span-12"
              name="dateOfBirth"
              label={spanish ? 'Fecha de nacimiento' : 'Date of Birth *'}
              value={value.dateOfBirth}
              onChange={onChange('dateOfBirth')}
              onBlur={handleAgeChange}
              errors={errors.fieldErrors?.dateOfBirth}
              patternFormat="##/##/####"
              placeholder="MM/DD/YYYY"
            />
          )}

          <Select
            name="language"
            className={classNames(
              step.suppress?.includes('dateOfBirth')
                ? 'sm:col-span-12'
                : 'sm:col-span-6',
            )}
            label={spanish ? 'Lengua Materna' : 'Primary Language'}
            value={value.language}
            onChange={onChange('language')}
            errors={errors.fieldErrors?.language}
            options={GLOBAL_MORE_LANGUAGE_OPTIONS}
            noDarkMode
            limit={1}
          />
          {!step.suppress?.includes('socialSecurityNumber') && (
            <InputMasked
              className="sm:col-span-6"
              name="socialSecurityNumber"
              label={
                spanish ? 'Número de seguro social' : 'Social Security Number'
              }
              value={value.socialSecurityNumber}
              onChange={onChange('socialSecurityNumber')}
              errors={errors.fieldErrors?.socialSecurityNumber}
              patternFormat="###-##-####"
              placeholder="###-##-####"
            />
          )}
          <Select
            name="gender"
            className="sm:col-span-3"
            label={spanish ? 'Sexo *' : 'Sex *'}
            value={value.gender}
            onChange={onChange('gender')}
            errors={errors.fieldErrors?.gender}
            options={
              language === 'es'
                ? GLOBAL_SEX_AND_GENDER_SPANISH_OPTIONS
                : GLOBAL_SEX_AND_GENDER_OPTIONS
            }
            limit={1}
            noDarkMode
          />
          <Input
            name="genderIdentity"
            className="sm:col-span-3"
            label={spanish ? 'Género' : 'Gender Identity'}
            value={value.genderIdentity}
            onChange={onChange('genderIdentity')}
            errors={errors?.fieldErrors?.genderIdentity}
          />
          <Select
            name="raceAndEthnicity"
            className="col-span-6"
            label={spanish ? 'Raza y etnia' : 'Race and Ethnicity'}
            value={value.raceAndEthnicity}
            onChange={onChange('raceAndEthnicity')}
            errors={errors?.fieldErrors?.raceAndEthnicity}
            options={
              language === 'es'
                ? GLOBAL_RACE_SPANISH_OPTIONS
                : GLOBAL_RACE_OPTIONS
            }
            limit={1}
            noDarkMode
          />

          <Input
            name="occupation"
            className="sm:col-span-6"
            label={spanish ? 'Ocupación' : 'Occupation'}
            value={value.occupation}
            onChange={onChange('occupation')}
            errors={errors.fieldErrors?.occupation}
          />
          <Input
            name="employer"
            className="sm:col-span-6"
            label={spanish ? 'Empleador' : 'Employer'}
            value={value.employer}
            onChange={onChange('employer')}
            errors={errors.fieldErrors?.employer}
          />
          <Input
            name="emergencyContactName"
            className="sm:col-span-6"
            label={
              spanish
                ? 'Nombre del contacto de emergencia *'
                : 'Emergency Contact Name *'
            }
            value={value.emergencyContactName}
            onChange={onChange('emergencyContactName')}
            errors={errors.fieldErrors?.emergencyContactName}
          />
          <Phone
            name="emergencyContactPhone"
            className="sm:col-span-6"
            label={
              spanish
                ? 'Número del contacto de emergencia *'
                : 'Emergency Contact Phone *'
            }
            value={value.emergencyContactPhone}
            onChange={onChange('emergencyContactPhone')}
            errors={errors.fieldErrors?.emergencyContactPhone}
          />
          <Toggle
            label="Requires Guardianship"
            onChange={(val) => {
              if (!val) {
                patchValue({
                  legalGuardianName: '',
                  legalGuardianRelationship: '',
                });
              }
              patchValue({
                guardian: val,
              });
              onChange('guardian')(val);
            }}
            value={value.guardian || false}
            className="col-span-6 pt-3"
            disabled={!!(ageInYears && ageInYears < 18)}
            errors={errors?.fieldErrors?.guardian}
          />
          {value.guardian && (
            <LegalGuardianFields
              fieldErrors={errors?.fieldErrors}
              values={value}
              onChange={onChange}
              update
            />
          )}
          <HealthcareProviderHealthcomSelect
            label={
              spanish
                ? 'Nombre de tu Doctor primario'
                : 'Select Your Primary Care Provider *'
            }
            name="primaryProvider"
            className="sm:col-span-12"
            value={value.primaryProvider}
            onChange={onChange('primaryProvider')}
            errors={errors.fieldErrors?.primaryProvider}
            requestId={requestId}
          />
        </fieldset>
        <fieldset className={fieldsetClassName}>
          <legend className={legendClassName}>
            {spanish ? '' : 'Address'}
          </legend>
          <Select
            name="country"
            className="col-span-4"
            label={spanish ? 'País' : 'Country'}
            value={value?.country}
            onChange={onChange('country')}
            errors={errors.fieldErrors?.country}
            options={GLOBAL_COUNTRY_OPTIONS}
            limit={1}
            noDarkMode
          />
          <Input
            name="address1"
            className="sm:col-span-4"
            label={spanish ? 'Dirección Principal' : 'Street Line #1 *'}
            value={value.address1}
            onChange={onChange('address1')}
            errors={errors.fieldErrors?.address1}
          />
          <Input
            name="address2"
            className="sm:col-span-4"
            label={
              spanish ? 'Información adicional (dirección)' : 'Street Line #2'
            }
            value={value.address2}
            onChange={onChange('address2')}
            errors={errors.fieldErrors?.address2}
          />
          <Input
            name="city"
            className="sm:col-span-4"
            label={spanish ? 'Ciudad *' : 'City *'}
            value={value.city}
            onChange={onChange('city')}
            errors={errors.fieldErrors?.city}
          />
          {['USA', 'CAN'].includes(value?.country || '') ? (
            <Select
              name="state"
              className="sm:col-span-4"
              label={`${value?.country === 'CAN' ? 'Province *' : 'State *'}`}
              value={value.state}
              onChange={onChange('state')}
              errors={errors.fieldErrors?.state}
              options={value?.country === 'CAN' ? CANADIAN_PROVINCES : STATES}
              limit={1}
              autocomplete
              noDarkMode
            />
          ) : (
            <Input
              name="state"
              className="sm:col-span-4"
              label={spanish ? 'Estado/Provincia *' : 'State/Province *'}
              value={value.state}
              onChange={onChange('state')}
              errors={errors.fieldErrors?.state}
            />
          )}

          <ZipCodeInput
            name="zip"
            className="sm:col-span-4"
            label={spanish ? 'Código Postal *' : 'Zip/Postal code *'}
            value={value.zip}
            onChange={onChange('zip')}
            errors={errors.fieldErrors?.zip}
            country={value.country ?? ''}
          />
        </fieldset>
        <fieldset className={fieldsetClassName}>
          <legend className={legendClassName}>
            {spanish ? 'Comunicación' : 'Communication'}
          </legend>
          <Select
            name="prefers"
            className={`sm:col-span-12`}
            label={
              spanish
                ? 'Método de comunicación preferido'
                : 'Preferred method of communication'
            }
            value={value.prefers}
            onChange={onChange('prefers')}
            errors={errors.fieldErrors?.prefers}
            options={GLOBAL_PREFERS_OPTIONS}
            limit={1}
            noDarkMode
          />
          {!step.suppress?.includes('email') && (!age || age >= 18) && (
            <Input
              name="email"
              className="sm:col-span-6"
              label={spanish ? 'Correo electrónico' : 'Email'}
              value={value.email}
              onChange={onChange('email')}
              errors={errors.fieldErrors?.email}
            />
          )}
          {!step.suppress?.includes('phone') && (!age || age >= 18) && (
            <Phone
              name="phone"
              className="sm:col-span-6"
              label={spanish ? 'Número de teléfono móvil' : 'Mobile number'}
              value={value.phone}
              onChange={onChange('phone')}
              errors={errors.fieldErrors?.phone}
            />
          )}
        </fieldset>

        <fieldset className={fieldsetClassName}>
          <legend className={legendClassName}>
            {spanish ? 'Otro' : 'Other'}
          </legend>
          <Select
            name="previouslyTreated"
            className="sm:col-span-12"
            inlineSelect={true}
            inlineLabelWidth="w-3/5"
            inlineInputWidth="w-2/5"
            label={
              spanish
                ? 'Alguna vez te has tratado con un Quiropráctico?'
                : 'Have you had chiropractic treatment before? *'
            }
            value={value.previouslyTreated}
            onChange={onChange('previouslyTreated')}
            errors={errors.fieldErrors?.previouslyTreated}
            options={[
              {
                text: 'Yes',
                value: true,
              },
              {
                text: 'No',
                value: false,
              },
            ]}
            limit={1}
            noDarkMode
          />
          <Select
            name="heardAboutUs"
            className="sm:col-span-12"
            label={
              spanish
                ? 'Como escuchaste de nuestra clínica?'
                : 'How did you hear about our office?'
            }
            value={value.heardAboutUs}
            inlineSelect={true}
            inlineLabelWidth="w-3/5"
            inlineInputWidth="w-2/5"
            onChange={onChange('heardAboutUs')}
            errors={errors.fieldErrors?.heardAboutUs}
            options={[
              {
                text: 'Another patient or individual',
                value: 'Another patient or individual',
              },
              {
                text: 'Doctor',
                value: 'Doctor',
              },
              {
                text: 'Attorney',
                value: 'Attorney',
              },
              {
                text: 'Insurance company directory',
                value: 'Insurance company directory',
              },
              {
                text: 'Practice website',
                value: 'Practice website',
              },
              {
                text: 'Social media page',
                value: 'Social media page',
              },
              {
                text: 'Sign/drive by',
                value: 'Sign/drive by',
              },
              {
                text: 'Other',
                value: 'Other',
              },
            ]}
            limit={1}
            noDarkMode
          />
          <Input
            name="heardAboutUsDetail"
            inline={true}
            inlineLabelWidth="w-3/5"
            inlineInputWidth="w-2/5"
            className={[
              'sm:col-span-12',
              [
                'Another patient or individual',
                'Doctor',
                'Attorney',
                'Other',
              ].includes(value.heardAboutUs || '')
                ? 'block'
                : 'hidden',
            ].join(' ')}
            label={
              value.heardAboutUs === 'Another patient or individual'
                ? 'Name of patient or individual:'
                : value.heardAboutUs === 'Doctor'
                  ? 'Name of doctor: '
                  : value.heardAboutUs === 'Attorney'
                    ? 'Name of attorney: '
                    : value.heardAboutUs === 'Other'
                      ? 'How did you hear about us?'
                      : ''
            }
            value={value.heardAboutUsDetail}
            onChange={onChange('heardAboutUsDetail')}
            errors={errors.fieldErrors?.heardAboutUsDetail}
          />
        </fieldset>
        <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 mt-8">
          {/* <Input
            name="idNumber"
            className="sm:col-span-2"
            label="Photo ID number *"
            value={value.idNumber}
            onChange={onChange('idNumber')}
            errors={errors.fieldErrors?.idNumber}
          /> */}
        </div>
        <div className="mt-8">
          <Button
            text={spanish ? 'Ingresar' : 'Submit'}
            onClick={registerSubmit(onSubmit, {
              onSuccess,
            })}
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
