import React, { useCallback, useState } from 'react';
import { Datepicker } from '../datepicker/Datepicker';
import { questionContentVerbiage } from './SurveyQuestionContent';
import {
  SurveyQuestionTypeOptions,
  SurveyQuestionAnswer,
  DateValue,
} from '@chiroup/core/types/Survey.type';

type Props = {
  dateOptions?: SurveyQuestionTypeOptions;
  onSelect: (val: SurveyQuestionAnswer) => Promise<void>;
  disabled: boolean;
  hideNext: boolean;
  language?: 'en' | 'es';
  primaryColor: string;
};

export const SurveyQuestionDate: React.FC<Props> = ({
  dateOptions,
  onSelect,
  disabled,
  hideNext = false,
  language = 'en',
  primaryColor,
}) => {
  const [val, setVal] = useState<DateValue>();

  const onChange = useCallback(
    async (e?: DateValue) => {
      setVal(e);
      if (e && hideNext) {
        console.error({ e });
        await onSelect({ type: 'date', value: e });
        setVal(undefined);
      }
    },
    [hideNext, onSelect],
  );

  const handleSubmit = async () => {
    if (val) {
      await onSelect({ type: 'date', value: val });
      setVal(undefined);
    }
  };

  return (
    <div className="flex justify-center">
      <fieldset className="mt-6 text-xl sm:text-2xl font-medium text-gray-500">
        <div className="mt-4">
          <Datepicker
            options={dateOptions}
            onChange={onChange}
            primaryColor={primaryColor}
            language={language}
          />
          {!hideNext && (
            <button
              disabled={!val || disabled}
              onClick={handleSubmit}
              className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-4"
              style={{
                backgroundColor: primaryColor,
              }}
              data-cy="next-button"
            >
              {questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
            </button>
          )}
        </div>
      </fieldset>
    </div>
  );
};
