import { Genders } from '@chiroup/core/enums/Genders.enum';
import { Patient } from '@chiroup/core/types/Patient.type';
import { HealthcomInsurance } from '@chiroup/core/types/PatientInsurance.type';
import { RequestStep } from '@chiroup/core/types/Request.type';
import {
  SurveyQuestionAnswer,
  SurveyQuestion,
  SurveyProgress,
} from '@chiroup/core/types/Survey.type';
import HTTPClient from '../components/common/HTTPClient';

const requestService = () => {
  const apiUrl = import.meta.env.VITE_HEALTHCOM_API;

  const get = async (
    id: string,
  ): Promise<{
    steps: RequestStep[];
    language: 'en' | 'es';
    gender: Genders;
    age: number;
    id: string;
    ccsFollowups?: string[];
    guardian: boolean;
  }> => {
    return HTTPClient.get(`${apiUrl}/requests/${id}`);
  };

  const uploadPhotoId = async (
    requestId: string,
    validationCode: string | number,
    file: File,
    side: 'front' | 'back',
    prMap?: { [key: string]: string },
  ): Promise<void> => {
    const type = file.type.split('/')[1];
    const pr = prMap?.uploadPhotoId || requestId;
    const resp = await fetch(
      `${apiUrl}/requests/${pr}/upload-photo-id?fileType=${type}&validationCode=${validationCode}&side=${side}`,
      {},
    );
    const res: {
      fields: { [key: string]: string };
      url: string;
    } = await resp.json();
    const form = new FormData();
    Object.entries(res.fields).forEach(([key, val]) => {
      form.append(key, val);
    });
    form.append('file', file);
    // This doesn't get a response...
    await fetch(res.url, { method: 'POST', body: form });
  };

  const skipUploadPhotoId = async (
    requestId: string,
    validationCode: string | number,
  ) => {
    return HTTPClient.get(
      `${apiUrl}/requests/${requestId}/upload-photo-id?skip=true&validationCode=${validationCode}`,
    );
  };

  const uploadInsuranceCard = async (
    requestId: string,
    validationCode: string | number,
    file: File,
    side: string,
    prMap: { [key: string]: string },
    surveyType?: string,
  ): Promise<string> => {
    const type = file.type.split('/')[1];
    const pr = prMap?.uploadInsuranceCard || requestId;
    const resp = await fetch(
      `${apiUrl}/requests/${pr}/upload-insurance-card?fileType=${type}&validationCode=${validationCode}&side=${side}&surveyType=${
        surveyType ?? 'insuranceCard'
      }`,
      {},
    );
    const res: {
      fields: { [key: string]: string };
      url: string;
    } = await resp.json();
    const form = new FormData();
    Object.entries(res.fields).forEach(([key, val]) => {
      form.append(key, val);
    });
    form.append('file', file);
    // This doesn't get a response...
    await fetch(res.url, { method: 'POST', body: form });
    return res.fields.key;
  };

  const insuranceCreate = async (
    requestId: string,
    validationCode: string | number,
    insurances: Partial<HealthcomInsurance>[],
  ): Promise<Response> => {
    return fetch(
      `${apiUrl}/requests/${requestId}/insurance?validationCode=${validationCode}`,
      {
        method: 'PUT',
        body: JSON.stringify(insurances),
      },
    );
  };

  const skipInsuranceCreate = async (
    requestId: string,
    validationCode: string | number,
  ) => {
    return HTTPClient.put(
      `${apiUrl}/requests/${requestId}/insurance?skip=true&validationCode=${validationCode}`,
      {},
    );
  };

  const skipUploadInsuranceCard = async (
    requestId: string,
    validationCode: string | number,
  ) => {
    return HTTPClient.get(
      `${apiUrl}/requests/${requestId}/upload-insurance-card?skip=true&validationCode=${validationCode}`,
    );
  };

  const submitInformation = async (
    requestId: string,
    data: Partial<Patient>,
    validationCode: string | number,
    prMap?: { [key: string]: string },
  ): Promise<Response> => {
    const pr = prMap?.basicInformation || requestId;
    return fetch(
      `${apiUrl}/requests/${pr}/information?validationCode=${validationCode}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      },
    );
  };

  const submitSurveyAnswer = async (
    requestId: string,
    patientSurveyId: string,
    patientSurveyQuestionId: string,
    patientSurveyQuestionAnswer: SurveyQuestionAnswer,
    validationCode: string | number,
    prMap: { [key: string]: string },
    remainingSteps: number[],
    kiosk: boolean,
    encounterId: string,
  ): Promise<{ question: SurveyQuestion; progress: SurveyProgress }> => {
    const pr = prMap?.[patientSurveyId] || requestId;
    return HTTPClient.put(
      `${apiUrl}/requests/${pr}/survey/${patientSurveyId}/${patientSurveyQuestionId}`,
      {
        patientSurveyQuestionAnswer,
        validationCode,
        remainingSteps,
        prMap,
        kiosk,
        encounterId,
      },
    );
  };

  const submitSurvey = async (
    requestId: string,
    patientSurveyId: string,
    questions: SurveyQuestion[],
    kiosk: boolean,
    prMap?: { [key: string]: string },
    spanishQuestions?: SurveyQuestion[],
    ccsFollowups?: string[],
    validationCode?: string | number,
    encounterId?: string,
  ): Promise<
    | {
        question?: SurveyQuestion;
        progress?: SurveyProgress;
      }
    | {
        steps: RequestStep[];
        language: 'en' | 'es';
        gender: Genders;
        age: number;
        id: string;
      }
  > => {
    const pr = prMap?.[patientSurveyId] || requestId;

    return await HTTPClient.put(
      `${apiUrl}/requests/${pr}/survey/${patientSurveyId}`,
      {
        questions,
        spanishQuestions,
        ccsFollowups,
        validationCode,
        prMap,
        kiosk,
        encounterId,
      },
    );
  };

  const getQuestion = async (
    requestId: string,
    patientSurveyId: string,
    questionId: string,
  ): Promise<{
    question: SurveyQuestion;
    previousQuestion: string;
    progress: SurveyProgress;
  }> => {
    return HTTPClient.get(
      `${apiUrl}/requests/${requestId}/survey/${patientSurveyId}/previous/${questionId}`,
    );
  };

  const submitConsent = async (
    requestId: string,
    patientConsentId: string,
    consentUrl: string,
    signature: any,
    validationCode: string | number,
    remainingSteps?: number[],
    prMap?: { [key: string]: string },
    preview?: boolean,
  ): Promise<any> => {
    const pr = prMap?.[patientConsentId] || requestId;
    return HTTPClient.put(
      `${apiUrl}/requests/${pr}/consent/${patientConsentId}`,
      {
        consent: consentUrl,
        signature,
        preview,
        validationCode,
        remainingSteps,
      },
    );
  };

  const authRequest = async ({
    requestId,
    validationCode,
    method,
  }: {
    requestId: string;
    validationCode?: number;
    method?: string;
  }) => {
    const params: { [key: string]: string | number } = {
      requestId,
    };
    if (validationCode && requestId) {
      params.validationCode = validationCode;
    } else if (method && requestId) {
      params.method = method;
    }
    return await HTTPClient.get(
      `${apiUrl}/requests/auth${Object.entries(params).reduce(
        (str, [key, val]) => {
          return `${str}${key}=${val}&`;
        },
        '?',
      )}`,
    );
  };

  return {
    get,
    uploadPhotoId,
    skipUploadPhotoId,
    uploadInsuranceCard,
    skipUploadInsuranceCard,
    submitInformation,
    submitSurveyAnswer,
    getQuestion,
    submitSurvey,
    submitConsent,
    authRequest,
    insuranceCreate,
    skipInsuranceCreate,
  };
};

export default requestService();
