import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import mildPain from '../images/mildPain.png';
import moderatePain from '../images/moderatePain.png';
import noPain from '../images/noPain.png';
import severePain from '../images/severePain.png';
import verySeverePain from '../images/verySeverePain.png';
import worstPain from '../images/worstPain.png';
import { questionContentVerbiage } from './SurveyQuestionContent';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import { isEmpty } from '@chiroup/core/functions/isEmpty';
import {
  SurveyQuestionTypeOptions,
  SurveyQuestionAnswer,
} from '@chiroup/core/types/Survey.type';

const painIcons = [
  { value: 'No pain', icon: noPain },
  { value: 'Mild pain', icon: mildPain },
  { value: 'Moderate pain', icon: moderatePain },
  { value: 'Severe pain', icon: severePain },
  { value: 'Very severe pain', icon: verySeverePain },
  { value: 'Worst pain', icon: worstPain },
];

type Props = {
  sliderOptions?: SurveyQuestionTypeOptions;
  onSelect: (val: SurveyQuestionAnswer) => Promise<void>;
  hideNext: boolean;
  language?: 'en' | 'es';
  primaryColor: string;
  disabled: boolean;
};

export const SurveyQuestionSlider: React.FC<Props> = ({
  sliderOptions,
  onSelect,
  hideNext = false,
  language = 'en',
  primaryColor,
  disabled,
}) => {
  const { min, max, left, right } = sliderOptions as {
    min: number;
    max: number;
    left: string;
    right: string;
    start: number;
  };

  useEffect(() => {
    const sheetToBeRemoved = document.getElementById('slider-styles');
    const sheetParent = sheetToBeRemoved?.parentNode;
    if (sheetParent) {
      sheetParent.removeChild(sheetToBeRemoved);
    }
    const sheet = document.createElement('style');
    sheet.setAttribute('id', 'slider-styles');
    sheet.innerHTML = `
		.range-slider__range::-webkit-slider-thumb {
			appearance: none;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: ${primaryColor};
			cursor: pointer;
			transition: background 0.15s ease-in-out;
		}
		.range-slider__range::-webkit-slider-thumb:hover {
			background: ${primaryColor};
		}
		.range-slider__range:active::-webkit-slider-thumb {
			background: ${primaryColor};
		}
		.range-slider__range::-moz-range-thumb {
			width: 20px;
			height: 20px;
			border: 0;
			border-radius: 50%;
			background: ${primaryColor};
			cursor: pointer;
			transition: background 0.15s ease-in-out;
		}
		.range-slider__range::-moz-range-thumb:hover {
			background: ${primaryColor};
		}
		.range-slider__range:active::-moz-range-thumb {
			background: ${primaryColor};
		}
		.range-slider__range:focus::-webkit-slider-thumb {
			box-shadow: 0 0 0 3px #fff, 0 0 0 6px ${primaryColor};
		}
		.range-slider__value {
			display: inline-block;
			position: relative;
			width: 60px;
			color: #fff;
			line-height: 20px;
			text-align: center;
			border-radius: 0.375rem;
			background: ${primaryColor};
			padding: 5px 10px;
		}
		`;
    document.body.appendChild(sheet);
    return () => {
      const sheetToBeRemoved = document.getElementById('slider-styles');
      const sheetParent = sheetToBeRemoved?.parentNode;
      if (sheetParent) {
        sheetParent.removeChild(sheetToBeRemoved);
      }
    };
  }, [primaryColor]);

  const [value, setVal] = useState<number>(
    hasProperty(sliderOptions, 'notLessThan') &&
      typeof sliderOptions?.notLessThan === 'number'
      ? sliderOptions.notLessThan
      : hasProperty(sliderOptions, 'notMoreThan') &&
          typeof sliderOptions?.notMoreThan === 'number'
        ? sliderOptions.notMoreThan
        : hasProperty(sliderOptions, 'start') &&
            typeof sliderOptions?.start === 'number'
          ? sliderOptions.start
          : min,
  );

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = +event.target.value;
      if (
        typeof sliderOptions?.notLessThan === 'number' &&
        newValue < sliderOptions.notLessThan
      ) {
        setVal(sliderOptions.notLessThan);
        return;
      }
      if (
        typeof sliderOptions?.notMoreThan === 'number' &&
        newValue > sliderOptions.notMoreThan
      ) {
        setVal(sliderOptions.notMoreThan);
        return;
      }
      setVal(newValue);
      if (hideNext) {
        await onSelect({
          type: 'slider',
          value: newValue,
        });
      }
    },
    [hideNext, onSelect, sliderOptions],
  );

  const handleSubmit = async () => {
    if (!isEmpty(value)) {
      await onSelect({ type: 'slider', value });
    }
  };

  return (
    <>
      <div className="py-14">
        <div className="flex flex-row w-full justify-between p-2 text-xs text-gray-500 sm:text-sm md:text-md">
          <div>{left}</div>
          <div>{right}</div>
        </div>
        <div className="relative w-full rounded-full">
          <div className="range-slider">
            <input
              className="range-slider__range"
              type="range"
              value={value}
              min={min}
              max={max}
              step={sliderOptions?.step || 1}
              onChange={onChange}
            />
            <div
              className="flex flex-row absolute w-full justify-between pointer-events-none"
              style={{ top: 0, padding: '13px 6px' }}
            >
              {Array.from(
                Array(
                  sliderOptions?.step
                    ? (max - min) / sliderOptions.step + 1
                    : max - min + 1,
                ),
              ).map((item, i) => (
                <div key={i} className="rounded-full bg-white h-2 w-2 z-0" />
              ))}
            </div>
          </div>
        </div>
        {sliderOptions?.iconScale && (
          <div className="w-full flex justify-between">
            {painIcons.map((icon, i) => (
              <span className="">
                <img src={icon.icon} alt={icon.value} className="h-6 sm:h-12" />
              </span>
            ))}
          </div>
        )}

        <div className="flex justify-center mt-4">
          <span className="range-slider__value">{value}</span>
        </div>
      </div>
      {!hideNext && (
        <button
          disabled={isEmpty(value) || disabled}
          onClick={handleSubmit}
          className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8"
          style={{
            backgroundColor: primaryColor,
          }}
          data-cy="next-button"
        >
          {questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
        </button>
      )}
    </>
  );
};
