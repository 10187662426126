import {
  Checkbox,
  Input,
  InputMasked,
  Select,
  ZipCodeInput,
} from '@chiroup/components';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { MeContext } from '../../../contexts/me.context';
import requestService from '../../../services/request.service';
import Button, { ButtonColors } from '../../common/Button';
import DatabaseAutocomplete from '../../common/DatabaseAutocomplete';
import UploadInsuranceSide from './UploadInsuranceSide';
import {
  CANADIAN_PROVINCES,
  STATES,
} from '@chiroup/core/constants/UsStatesAndTerritories';
import {
  PatientInsuranceTypes,
  HealthcomInsurance,
  PatientInsuranceTypesSpanish,
  PrimaryInsuredTypesSpanishSurvey,
  PrimaryInsuredTypesSurvey,
  InsuranceSexTypesSpanish,
  InsuranceSexTypes,
} from '@chiroup/core/types/PatientInsurance.type';
import { RequestStep } from '@chiroup/core/types/Request.type';
import { enumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import { SuffixOptions } from '@chiroup/core/constants/Suffixes';
import { COUNTRIES } from '@chiroup/core/constants/countries';

type Insurance = {
  id: string;
  type: PatientInsuranceTypes;
};

type Props = {
  step: RequestStep;
  requestId: string;
  complete: (goToNext?: boolean) => Promise<void>;
  validationCode: string | number;
  prMap: { [key: string]: string };
  clinicId?: number;
  language: 'en' | 'es';
};

const insuredVerbiage = {
  en: {
    title: 'Primary Insured',
    fnameLabel: 'First Name',
    mnameLabel: 'Middle Name',
    lnameLabel: 'Last Name',
    suffix: 'Suffix',
    dateOfBirthLabel: 'Date of Birth',
    sexLabel: 'Sex',
    streetAddressLabel: 'Street Address',
    cityLabel: 'City',
    stateLabel: 'State',
    zipCodeLabel: 'Zip/Postal Code',
    country: 'Country',
    sameAsSelf: 'Address same as self',
  },
  es: {
    title: 'Asegurado primario',
    fnameLabel: 'Nombre de pila',
    mnameLabel: 'Segundo nombre',
    lnameLabel: 'Apellido',
    suffix: 'Sufijo',
    dateOfBirthLabel: 'Fecha de nacimiento',
    sexLabel: 'Sexo',
    streetAddressLabel: 'Dirección',
    cityLabel: 'Ciudad',
    stateLabel: 'Estado',
    zipCodeLabel: 'Código Postal',
    country: 'País',
    sameAsSelf: 'Dirección igual que yo',
  },
};

const verbiage = {
  en: {
    skip: 'I have no insurance coverage to report',
    expired: 'This request has expired.',
    uploadInsurance:
      'Our office needs a copy of your current insurance card or policy declarations page. Please make sure the photo or scan or your information is clear and easy to read.',
    payorLabel: 'Payor',
    memberIdLabel: 'Member ID Number *',
    planNumberLabel: 'Plan or Group Number',
    typeLabel: 'Insurance Type',
    relationshipLabel: 'Relationship to Insured *',
    next: 'Next',
    addAnother: 'Add Another Policy',
    tryAgain: 'Try again',
    memberIdRequired: 'Member ID is required',
    typeRequired: 'Insurance type is required',
    primaryInsuredRequired: 'Relationship to insured is required',
  },
  es: {
    skip: 'No tengo cobertura de seguro para reportar',
    uploadInsurance:
      'Nuestra oficina necesita una copia de su tarjeta o póliza de seguro actual. página de declaraciones. Asegúrese de que la foto o el escaneo o su la información es clara y fácil de leer.',
    payorLabel: 'Empresa de Seguro',
    memberIdLabel: 'Número de identificación de miembro *',
    planNumberLabel: 'Número de plan o grupo',
    typeLabel: 'Tipo de seguro',
    relationshipLabel: 'Relación con el asegurado *',
    next: 'Continuar',
    addAnother: 'Agregar Póliza',
    tryAgain: 'Intentar otra vez',
    memberIdRequired: 'Se requiere identificación de miembro',
    typeRequired: 'Se requiere tipo de seguro',
    primaryInsuredRequired: 'Se requiere relación con el asegurado',
  },
};

const Insurance: React.FC<Props> = ({
  step,
  requestId,
  complete,
  validationCode,
  prMap,
  clinicId = -1,
  language = 'en',
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);

  const [insurances, setInsurances] = useState<
    Partial<
      HealthcomInsurance & {
        errors?: {
          payor?: { message: string };
          memberId?: { message: string };
          planGroupId?: { message: string };
          type?: { message: string };
          primaryInsuredType?: { message: string };
          primaryInsured?: {
            fname?: { message: string };
            mname?: { message: string };
            lname?: { message: string };
            dateOfBirth?: { message: string };
            sex?: { message: string };
            addressSameAsPatient?: { message: string };
            streetAddress?: { message: string };
            streetAddress2?: { message: string };
            city?: { message: string };
            state?: { message: string };
            country?: { message: string };
            postalCode?: { message: string };
            phone?: { message: string };
            countrty?: { message: string };
          };
        };
      }
    >[]
  >([{}]);
  const [skipping, setSkipping] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getTypesOptions = useMemo(
    () => (index: 1 | 2 | 3) => {
      const options = enumToArrayOfOptions(
        language === 'es'
          ? PatientInsuranceTypesSpanish
          : PatientInsuranceTypes,
      );

      const filteredOptions = options.filter((option) => {
        const chosenInForm = insurances.find(
          (insurance: any, insuranceIndex: number) =>
            insurance.type === option.value && insuranceIndex !== index,
        );
        return !chosenInForm;
      });

      return filteredOptions;
    },
    [insurances, language],
  );

  const buttonRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const skip = async () => {
    try {
      setSkipping(true);
      const requestIdTouse = prMap?.insurance || requestId;
      await requestService.skipInsuranceCreate(requestIdTouse, validationCode);
      await complete(true);
    } catch (err) {
      console.error(err);
    }
    setSkipping(false);
  };

  const onSubmit = async () => {
    const validation = {
      memberId: {
        required: {
          message: verbiage[language === 'es' ? 'es' : 'en'].memberIdRequired,
        },
      },
      primaryInsuredType: {
        required: {
          message:
            verbiage[language === 'es' ? 'es' : 'en'].primaryInsuredRequired,
        },
      },
    };

    const errors: any = {};
    insurances.forEach((insurance, index) => {
      const insuranceErrors: any = {};
      if (!insurance.memberId) {
        insuranceErrors.memberId = validation.memberId.required;
      }
      // if (!insurance.type) {
      //   insuranceErrors.type = validation.type.required;
      // }
      if (!insurance.primaryInsuredType) {
        insuranceErrors.primaryInsuredType =
          validation.primaryInsuredType.required;
      }
      if (Object.keys(insuranceErrors).length) {
        errors[index] = insuranceErrors;
      }
    });
    if (Object.keys(errors).length) {
      setInsurances((prevInsurances) => {
        const updatedInsurances = [...prevInsurances];
        updatedInsurances.forEach((insurance, index) => {
          if (errors[index]) {
            insurance.errors = errors[index];
          }
        });
        return updatedInsurances;
      });
      return;
    }

    try {
      setIsSubmitting(true);
      const requestIdTouse = prMap?.insurance || requestId;
      await requestService.insuranceCreate(
        requestIdTouse,
        validationCode,
        insurances?.map((insurance) => {
          delete insurance.errors;
          delete insurance.insuranceFrontPreview;
          delete insurance.insuranceBackPreview;
          return insurance;
        }),
      );
      await complete(true);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const onUploadInsuranceCard = async (
    side: 'front' | 'back',
    url: string,
    index: 1 | 2 | 3,
  ) => {
    const prop = side === 'front' ? 'insuranceFront' : 'insuranceBack';
    setInsurances((prevInsurances) => {
      const updatedInsurances = [...prevInsurances];
      updatedInsurances[index] = {
        ...updatedInsurances[index],
        [prop]: url,
      };
      return updatedInsurances;
    });
  };

  const addPolicy = () => {
    setInsurances((prevInsurances) => {
      return [...prevInsurances, {}];
    });
  };

  const onChangeInsurance = (index: number, property: string) => {
    return (value: any) =>
      setInsurances((prevInsurances) => {
        const updatedInsurances = [...prevInsurances];
        updatedInsurances[index] = {
          ...updatedInsurances[index],
          [property]: value,
        };
        return updatedInsurances;
      });
  };

  const onChangeInsurancePrimaryInsured = (index: number, property: string) => {
    return (value: any) =>
      setInsurances((prevInsurances) => {
        const updatedInsurances = [...prevInsurances];
        updatedInsurances[index] = {
          ...updatedInsurances[index],
          primaryInsured: {
            ...updatedInsurances[index].primaryInsured,
            [property]: value,
          },
        };
        return updatedInsurances;
      });
  };

  return (
    <div className="flex items-center justify-center px-4 sm:min-h-screen">
      <div className="max-w-4xl py-8">
        <h2 className="text-4xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          <span className="flex flex-row">
            {language === 'es' ? 'Seguro' : step.title}
            {step.complete && (
              <svg
                className="w-10 h-10 ml-2 opacity-50 place-self-center"
                style={{
                  color: primaryColor,
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>
        </h2>
        <h3 className="mt-2 text-xl font-extrabold leading-6 tracking-tight text-gray-900 sm:text-2xl sm:leading-none md:text-4xl">
          <span
            style={{
              color: primaryColor,
            }}
          >
            {language === 'es'
              ? 'Información sobre tu seguro'
              : step.description}
          </span>
        </h3>

        <form>
          {!!insurances.length &&
            insurances?.map((insurance, index) => (
              <div key={index} className="relative">
                {insurances.length > 1 && (
                  <XMarkIcon
                    className="absolute top-6 right-0 w-6 h-6 mt-2 mr-2 text-gray-400 hover:text-gray-500 cursor-pointer"
                    onClick={() => {
                      setInsurances((prevInsurances) => {
                        const updatedInsurances = [...prevInsurances];
                        updatedInsurances.splice(index, 1);
                        return updatedInsurances;
                      });
                    }}
                  />
                )}
                <div className="pt-8 space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                  <DatabaseAutocomplete
                    value={insurance.payor}
                    className="col-span-4"
                    onChange={onChangeInsurance(index, 'payor')}
                    buttonRef={buttonRef}
                    searchRef={searchRef}
                    clinicId={clinicId}
                    label={verbiage[language === 'es' ? 'es' : 'en'].payorLabel}
                    error={insurance.errors?.payor}
                    limit={10000}
                    hideEndOfList
                    unshiftNotListedOption={true}
                    language={language}
                  />
                  <Input
                    name={`memberId[${index}]`}
                    value={insurance.memberId}
                    label={
                      verbiage[language === 'es' ? 'es' : 'en'].memberIdLabel
                    }
                    className="col-span-2"
                    onChange={onChangeInsurance(index, 'memberId')}
                    errors={insurance.errors?.memberId}
                  />
                  <Input
                    name={`planNumber[${index}]`}
                    label={
                      verbiage[language === 'es' ? 'es' : 'en'].planNumberLabel
                    }
                    value={insurance.planGroupId}
                    className="col-span-2"
                    onChange={onChangeInsurance(index, 'planGroupId')}
                    errors={insurance.errors?.planGroupId}
                  />
                  <Select
                    name={`type[${index}]`}
                    label={verbiage[language === 'es' ? 'es' : 'en'].typeLabel}
                    options={getTypesOptions(index as 1 | 2 | 3)}
                    value={insurance.type}
                    onChange={onChangeInsurance(index, 'type')}
                    className="col-span-2"
                    limit={1}
                    errors={insurance.errors?.type}
                    clearable
                    noDarkMode
                  />
                  <Select
                    name={`relationship[${index}]`}
                    label={
                      verbiage[language === 'es' ? 'es' : 'en']
                        .relationshipLabel
                    }
                    options={enumToArrayOfOptions(
                      language === 'es'
                        ? PrimaryInsuredTypesSpanishSurvey
                        : PrimaryInsuredTypesSurvey,
                    )}
                    value={insurance.primaryInsuredType}
                    onChange={onChangeInsurance(index, 'primaryInsuredType')}
                    className="col-span-2"
                    limit={1}
                    errors={insurance.errors?.primaryInsuredType}
                    noDarkMode
                  />
                  {insurance?.primaryInsuredType &&
                    insurance?.primaryInsuredType !==
                      PrimaryInsuredTypesSurvey.Self && (
                      <div className="col-span-4 border rounded-md">
                        <div className={'p-4'}>
                          <div className={`rounded-lg`}>
                            <div className="mb-6 sm:mb-0">
                              <h3 className="text-lg font-medium leading-6 text-gray-900 print:text-md">
                                {
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].title
                                }
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="p-4 space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                          <Input
                            name="pramiryInsuredName"
                            className="col-span-2"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .fnameLabel
                            }
                            value={insurance.primaryInsured?.fname}
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'fname',
                            )}
                          />
                          <Input
                            name="pramiryInsuredName"
                            className="col-span-2"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .mnameLabel
                            }
                            value={insurance.primaryInsured?.mname}
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'mname',
                            )}
                          />
                          <Input
                            name="pramiryInsuredName"
                            className="col-span-2"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .lnameLabel
                            }
                            value={insurance.primaryInsured?.lname}
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'lname',
                            )}
                          />
                          <Select
                            name="suffix"
                            className="col-span-2"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .suffix
                            }
                            options={SuffixOptions}
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'suffix',
                            )}
                            value={insurance.primaryInsured?.suffix}
                            limit={1}
                          />
                          <InputMasked
                            className="col-span-2"
                            value={insurance.primaryInsured?.dateOfBirth}
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'dateOfBirth',
                            )}
                            name="dateOfBirth"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .dateOfBirthLabel
                            }
                            patternFormat="##/##/####"
                            placeholder="MM/DD/YYYY"
                          />
                          <Select
                            name="sex"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .sexLabel
                            }
                            className="col-span-2"
                            value={insurance.primaryInsured?.sex}
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'sex',
                            )}
                            options={enumToArrayOfOptions(
                              language === 'es'
                                ? InsuranceSexTypesSpanish
                                : InsuranceSexTypes,
                            )}
                            limit={1}
                            noDarkMode
                          />
                          <Checkbox
                            name="addressSameAsPatient"
                            label={
                              insuredVerbiage[language === 'es' ? 'es' : 'en']
                                .sameAsSelf
                            }
                            className="col-span-4 pt-2"
                            value={
                              insurance.primaryInsured?.addressSameAsPatient
                            }
                            onChange={onChangeInsurancePrimaryInsured(
                              index,
                              'addressSameAsPatient',
                            )}
                          />
                          {!insurance.primaryInsured?.addressSameAsPatient && (
                            <>
                              <Input
                                name="streetAddress"
                                className="col-span-2"
                                label={
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].streetAddressLabel
                                }
                                value={insurance.primaryInsured?.streetAddress}
                                onChange={onChangeInsurancePrimaryInsured(
                                  index,
                                  'streetAddress',
                                )}
                              />
                              <Input
                                name="address2"
                                className="col-span-2"
                                label="Address cont."
                                value={insurance.primaryInsured?.address2}
                                onChange={onChangeInsurancePrimaryInsured(
                                  index,
                                  'address2',
                                )}
                              />

                              <Input
                                name="city"
                                label={
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].cityLabel
                                }
                                value={insurance.primaryInsured?.city}
                                onChange={onChangeInsurancePrimaryInsured(
                                  index,
                                  'city',
                                )}
                                className="col-span-2"
                              />

                              <Select
                                name="state"
                                className="col-span-2"
                                label={`${
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].country === 'CAN'
                                    ? 'Province'
                                    : 'State'
                                }`}
                                value={insurance.primaryInsured?.state}
                                onChange={onChangeInsurancePrimaryInsured(
                                  index,
                                  'state',
                                )}
                                // errors={fieldErrors?.state}
                                options={
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].country === 'CAN'
                                    ? CANADIAN_PROVINCES
                                    : STATES
                                }
                                limit={1}
                                autocomplete
                              />
                              <ZipCodeInput
                                name="zip"
                                label={
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].zipCodeLabel
                                }
                                className="col-span-2"
                                value={insurance.primaryInsured?.postalCode}
                                onChange={onChangeInsurancePrimaryInsured(
                                  index,
                                  'postalCode',
                                )}
                                country={
                                  insurance.primaryInsured?.country || 'US'
                                }
                              />

                              <Select
                                name="country"
                                label={
                                  insuredVerbiage[
                                    language === 'es' ? 'es' : 'en'
                                  ].country
                                }
                                className="col-span-2"
                                value={insurance.primaryInsured?.country}
                                onChange={onChangeInsurancePrimaryInsured(
                                  index,
                                  'country',
                                )}
                                options={COUNTRIES}
                                limit={1}
                                noDarkMode
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                </div>
                <div className="flex flex-col w-full gap-4 mt-10 sm:flex-row sm:h-64">
                  <UploadInsuranceSide
                    side={`front`}
                    language={language}
                    requestId={requestId}
                    validationCode={validationCode}
                    prMap={prMap}
                    imagePreview={insurance.insuranceFrontPreview || null}
                    onChangeImagePreview={(url: string | null) => {
                      setInsurances((prevInsurances) => {
                        const updatedInsurances = [...prevInsurances];
                        updatedInsurances[index] = {
                          ...updatedInsurances[index],
                          insuranceFrontPreview: url,
                        };
                        return updatedInsurances;
                      });
                    }}
                    onUploadInsuranceCard={(url: string) =>
                      onUploadInsuranceCard('front', url, index as 1 | 2 | 3)
                    }
                    className="max-w-sm overflow-hidden shadow-lg border-gray-300 border rounded-md max-h-64"
                  />

                  <UploadInsuranceSide
                    side={`back`}
                    language={language}
                    requestId={requestId}
                    validationCode={validationCode}
                    prMap={prMap}
                    imagePreview={insurance.insuranceBackPreview || null}
                    onChangeImagePreview={(url: string | null) => {
                      setInsurances((prevInsurances) => {
                        const updatedInsurances = [...prevInsurances];
                        updatedInsurances[index] = {
                          ...updatedInsurances[index],
                          insuranceBackPreview: url,
                        };
                        return updatedInsurances;
                      });
                    }}
                    onUploadInsuranceCard={(url: string) =>
                      onUploadInsuranceCard('back', url, index as 1 | 2 | 3)
                    }
                    className="max-w-sm overflow-hidden rounded shadow-lg max-h-64"
                  />
                </div>
                {index !== insurances.length && <hr className="my-8" />}
              </div>
            ))}

          <div className="flex flex-col mt-16">
            {insurances.length < 3 && (
              <div className="mb-8">
                <Button
                  icon={<PlusIcon />}
                  text={verbiage[language === 'es' ? 'es' : 'en'].addAnother}
                  onClick={addPolicy}
                  color={ButtonColors.plain}
                  disabled={isSubmitting || skipping}
                />
              </div>
            )}

            <div className="flex justify-between">
              <Button
                text={verbiage[language === 'es' ? 'es' : 'en'].skip}
                onClick={skip}
                disabled={isSubmitting || skipping}
                loading={skipping}
              />

              <Button
                text={verbiage[language === 'es' ? 'es' : 'en'].next}
                type="button"
                onClick={onSubmit}
                disabled={isSubmitting || skipping}
                loading={isSubmitting}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Insurance;
