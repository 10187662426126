import { Input } from '@chiroup/components';
import React, { useState } from 'react';
import { questionContentVerbiage } from './SurveyQuestionContent';
import { SurveyQuestionAnswer } from '@chiroup/core/types/Survey.type';

type Props = {
  id: string;
  onSelect: (val: SurveyQuestionAnswer) => Promise<void>;
  disabled: boolean;
  language?: 'en' | 'es';
  type?: string;
  primaryColor: string;
};

export const SurveyQuestionInput: React.FC<Props> = ({
  id,
  onSelect,
  disabled,
  language = 'en',
  type = 'text',
  primaryColor,
}) => {
  const [val, setVal] = useState<string>('');

  const onChange = (val: string) => {
    setVal(val);
  };

  const handleSubmit = async () => {
    await onSelect({ type: 'input', value: val });
    setVal('');
  };

  return (
    <>
      <Input
        name={`question-${id}`}
        className=""
        value={val}
        onChange={onChange}
        disabled={disabled}
        type={type}
        autoFocus
      />
      <button
        disabled={!val || disabled}
        onClick={handleSubmit}
        className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8"
        style={{
          backgroundColor: primaryColor,
        }}
      >
        {questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
      </button>
    </>
  );
};
