import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import Button, { ButtonColors } from '../common/Button';
import { RequestStep } from '@chiroup/core/types/Request.type';

const verbiage = {
  en: {
    allDone: 'All done!',
    expired: 'This request has expired.',
    kiosk: 'A staff member will be with you shortly.',
    contact: 'Our office has everything that we need.',
    expiredContact:
      'Our office will contact you if further information is required.',
    questionsOrConcerns:
      'Please let us know if you have any questions or concerns. We will see you at your next appointment!',
    kioskQuestionsOrConcerns:
      'Please let us know if you have any questions or concerns.',
    thankYou: 'Thank you for your time.',
    logout: 'Logout',
  },
  es: {
    allDone: 'Listo!',
    expired: 'Esta solicitud ha caducado.',
    kiosk: 'Un miembro del personal estará con usted en breve.',
    contact: 'Ya recibimos toda la información necesaria.',
    expiredContact:
      'Nuestra oficina se comunicará con usted si se requiere más información.',
    questionsOrConcerns:
      'Por favor contáctanos si tienes alguna pregunta. Nos vemos pronto en tu próxima cita!',
    kioskQuestionsOrConcerns:
      'Háganos saber si tiene alguna pregunta o inquietud.',
    thankYou: 'Gracias por tu tiempo!',
    logout: 'Cerrar sesión',
  },
};

type Props = {
  language: 'en' | 'es';
  kiosk?: boolean;
  expired?: boolean;
  complete?: boolean;
  steps?: RequestStep[];
};

const RequestComplete: React.FC<Props> = ({
  language = 'en',
  kiosk,
  expired = false,
  complete = true,
  steps,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const navigate = useNavigate();

  useEffect(() => {
    const allStepsComplete = steps?.every((step) => step?.complete);
    if (allStepsComplete && kiosk) {
      setTimeout(() => {
        navigate('/kiosk');
        window.location.reload();
      }, 10000);
    }
  }, [navigate, steps, kiosk]);

  return (
    <div className="w-full justify-center">
      <div className="flex items-center justify-center px-4 sm:min-h-screen">
        <div className="max-w-4xl py-8">
          <h2
            className="text-4xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
            data-cy={!expired && complete ? 'complete-message' : null}
          >
            {expired && !complete
              ? verbiage[language === 'es' ? 'es' : 'en'].expired
              : verbiage[language === 'es' ? 'es' : 'en'].allDone}
          </h2>
          <h3 className="mt-2 text-xl font-extrabold leading-6 tracking-tight text-gray-900 sm:text-2xl sm:leading-none md:text-4xl">
            <span
              style={{
                color: primaryColor,
              }}
            >
              {verbiage[language === 'es' ? 'es' : 'en'].thankYou}
            </span>
          </h3>
          <p className="mt-8">
            {kiosk
              ? `${verbiage[language === 'es' ? 'es' : 'en'].kiosk}`
              : expired && !complete
                ? `${verbiage[language === 'es' ? 'es' : 'en'].expiredContact}`
                : `${verbiage[language === 'es' ? 'es' : 'en'].contact}`}{' '}
            {kiosk
              ? verbiage[language === 'es' ? 'es' : 'en']
                  .kioskQuestionsOrConcerns
              : verbiage[language === 'es' ? 'es' : 'en'].questionsOrConcerns}
          </p>
          {!!kiosk && (
            <p className="mt-8">
              <Button
                text="Logout"
                color={ButtonColors.plain}
                className="mt-4"
                type="button"
                onClick={() => {
                  navigate('/kiosk');
                  window.location.reload();
                }}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestComplete;
