import { Select, SelectOption } from '@chiroup/components';
import React, { useEffect, useState } from 'react';
import useHealthcareProvidersAll from './useHealthcareProvidersAll';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { HealthcareProvider } from '@chiroup/core/types/HealthcareProvider.type';

type Props = {
  name: string;
  value?: number | { $add: string };
  onChange: (val: number) => void;
  onChangeFull?: (val: any) => void;
  errors?: FormError;
  className?: string;
  requestId: string;
  label?: string;
};

const HealthcareProviderSelect: React.FC<Props> = ({
  name,
  value,
  onChange,
  onChangeFull,
  errors,
  className = 'col-span-2 sm:col-span-1',
  requestId,
  label = 'Name of your primary care provider *',
}) => {
  const { data } = useHealthcareProvidersAll(requestId);
  const [searchOptions, setSearchOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const options = [
      {
        text: 'None',
        value: '',
      },
      ...(data || []).map((prov: HealthcareProvider) => ({
        value: prov.ID,
        text: String(prov.lastFirst || 'N/A'),
      })),
    ];
    setSearchOptions(options);
  }, [data]);

  useEffect(() => {
    const fullProvider = data?.find((item) => item.ID === value);
    if (onChangeFull) {
      onChangeFull(fullProvider);
    }
  }, [value, data, onChangeFull]);

  return (
    <Select
      name={name}
      className={className}
      label={label}
      value={value}
      onChange={onChange}
      errors={errors}
      options={searchOptions}
      limit={1}
      autocomplete
      addInline
      noDarkMode
    />
  );
};

export default HealthcareProviderSelect;
