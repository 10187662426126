import {
  Side,
  LeftOrRight,
  Part,
  BodyPartsTouching,
  BodyParts,
} from '../types/Survey.type';
import { hasProperty } from './hasProperty';

export type BodyPart = {
  side: Side;
  leftOrRight: LeftOrRight;
  part: Part;
};

export type BodyRegion = {
  name: string;
  values: BodyPart[];
};

export const partsOrder: any = {
  head: 0,
  neck: 2,
  chest: 4,
  rib: 6,
  abdomen: 8,
  groin: 10,
  'upper-back': 12,
  'upper-lower-back': 14,
  'lower-back': 16,
  hip: 18,
  thigh: 20,
  knee: 22,
  leg: 24,
  ankle: 26,
  foot: 28,
  shoulder: 30,
  arm: 32,
  elbow: 34,
  forearm: 36,
  wrist: 38,
  hand: 40,
};

export const touchingParts: BodyPartsTouching = {
  front: {
    hand: {
      left: ['rear-left-hand', 'front-left-wrist', 'rear-left-wrist'],
      right: ['rear-right-hand', 'front-right-wrist', 'rear-right-wrist'],
    },
    foot: {
      left: ['rear-left-foot', 'front-left-ankle', 'rear-left-ankle'],
      right: ['rear-right-foot', 'front-right-ankle', 'rear-right-ankle'],
    },
    ankle: {
      left: [
        'rear-left-ankle',
        'front-left-leg',
        'front-left-foot',
        'rear-left-leg',
        'rear-left-foot',
      ],
      right: [
        'rear-right-ankle',
        'front-right-leg',
        'front-right-foot',
        'rear-right-leg',
        'rear-right-foot',
      ],
    },
    leg: {
      left: [
        'rear-left-leg',
        'front-left-knee',
        'front-left-ankle',
        'rear-left-knee',
        'rear-left-ankle',
      ],
      right: [
        'rear-right-leg',
        'front-right-knee',
        'front-right-ankle',
        'rear-right-knee',
        'rear-right-ankle',
      ],
    },
    knee: {
      left: [
        'rear-left-knee',
        'front-left-thigh',
        'front-left-leg',
        'rear-left-thigh',
        'rear-left-leg',
      ],
      right: [
        'rear-right-knee',
        'front-right-thigh',
        'front-right-leg',
        'rear-right-thigh',
        'rear-right-leg',
      ],
    },
    thigh: {
      left: [
        'rear-left-thigh',
        'front-left-hip',
        'front-left-knee',
        'rear-left-hip',
        'rear-left-knee',
      ],
      right: [
        'rear-right-thigh',
        'front-right-hip',
        'front-right-knee',
        'rear-right-hip',
        'rear-right-knee',
      ],
    },
    hip: {
      left: [
        'rear-left-hip',
        'front-left-abdomen',
        'front-left-groin',
        'front-left-thigh',
        'front-right-hip',
        'rear-left-abdomen',
        'rear-left-groin',
        'rear-left-thigh',
        'rear-right-hip',
      ],
      right: [
        'rear-right-hip',
        'front-right-abdomen',
        'front-right-groin',
        'front-right-thigh',
        'front-left-hip',
        'rear-right-abdomen',
        'rear-right-groin',
        'rear-right-thigh',
        'rear-left-hip',
      ],
    },
    groin: {
      left: [
        'rear-left-groin',
        'front-left-abdomen',
        'front-left-hip',
        'front-right-groin',
        'rear-left-abdomen',
        'rear-left-hip',
        'rear-right-groin',
      ],
      right: [
        'rear-right-groin',
        'front-right-abdomen',
        'front-right-hip',
        'front-left-groin',
        'rear-right-abdomen',
        'rear-right-hip',
        'rear-left-groin',
      ],
    },
    abdomen: {
      left: [
        'front-left-rib',
        'front-left-chest',
        'front-right-chest',
        'front-left-groin',
        'front-right-groin',
        'front-left-hip',
        'front-right-hip',
        'front-right-abdomen',
        'rear-left-hip',
        'rear-left-lower-back',
        'rear-left-upper-lower-back',
        'rear-left-upper-back',
      ],
      right: [
        'front-right-rib',
        'front-right-chest',
        'front-left-chest',
        'front-right-groin',
        'front-left-groin',
        'front-right-hip',
        'front-left-abdomen',
        'rear-right-hip',
        'rear-right-lower-back',
        'rear-right-upper-lower-back',
        'rear-right-upper-back',
      ],
    },
    rib: {
      left: [
        'front-right-rib',
        'rear-left-rib',
        'front-left-abdomen',
        'front-right-abdomen',
        'front-left-chest',
        'front-right-chest',
      ],
      right: [
        'front-right-rib',
        'rear-right-rib',
        'front-right-abdomen',
        'front-left-abdomen',
        'front-right-chest',
        'front-left-chest',
      ],
    },
    wrist: {
      left: [
        'rear-left-wrist',
        'front-left-hand',
        'front-left-forearm',
        'rear-left-hand',
        'rear-left-forearm',
      ],
      right: [
        'rear-right-wrist',
        'front-right-hand',
        'front-right-forearm',
        'rear-right-hand',
        'rear-right-forearm',
      ],
    },
    forearm: {
      left: [
        'rear-left-forearm',
        'front-left-wrist',
        'front-left-elbow',
        'rear-left-wrist',
        'rear-left-elbow',
      ],
      right: [
        'rear-right-forearm',
        'front-right-wrist',
        'front-right-elbow',
        'rear-right-wrist',
        'rear-right-elbow',
      ],
    },
    elbow: {
      left: [
        'rear-left-elbow',
        'front-left-forearm',
        'front-left-arm',
        'rear-left-forearm',
        'rear-left-arm',
      ],
      right: [
        'rear-right-elbow',
        'front-right-forearm',
        'front-right-arm',
        'rear-right-forearm',
        'rear-right-arm',
      ],
    },
    arm: {
      left: [
        'rear-left-arm',
        'front-left-elbow',
        'front-left-shoulder',
        'rear-left-elbow',
        'rear-left-shoulder',
      ],
      right: [
        'rear-right-arm',
        'front-right-elbow',
        'front-right-shoulder',
        'rear-right-elbow',
        'rear-right-shoulder',
      ],
    },
    chest: {
      left: [
        'rear-left-upper-back',
        'front-left-shoulder',
        'front-left-neck',
        'front-left-rib',
        'front-left-abdomen',
        'front-right-neck',
        'front-right-rib',
        'front-right-abdomen',
        'front-right-chest',
      ],
      right: [
        'rear-right-upper-back',
        'front-right-shoulder',
        'front-right-neck',
        'front-right-rib',
        'front-right-abdomen',
        'front-left-neck',
        'front-left-rib',
        'front-left-abdomen',
        'front-left-chest',
      ],
    },
    shoulder: {
      left: [
        'rear-left-shoulder',
        'front-left-arm',
        'front-left-chest',
        'front-left-neck',
        'rear-left-arm',
        'rear-left-chest',
        'rear-left-neck',
      ],
      right: [
        'rear-right-shoulder',
        'front-right-arm',
        'front-right-chest',
        'front-right-neck',
        'rear-right-arm',
        'rear-right-chest',
        'rear-right-neck',
      ],
    },
    head: {
      left: [
        'front-right-head',
        'rear-left-head',
        'front-left-neck',
        'front-right-neck',
        'rear-left-neck',
      ],
      right: [
        'front-left-head',
        'rear-right-head',
        'front-right-neck',
        'front-left-neck',
        'rear-right-neck',
      ],
    },
    neck: {
      left: [
        'rear-left-neck',
        'front-left-shoulder',
        'front-left-chest',
        'front-right-neck',
        'front-left-head',
        'front-right-chest',
        'rear-left-head',
        'front-right-head',
      ],
      right: [
        'rear-right-neck',
        'front-right-shoulder',
        'front-right-chest',
        'front-left-neck',
        'front-right-head',
        'front-left-chest',
        'rear-right-head',
        'front-left-head',
      ],
    },
  },
  rear: {
    hand: {
      left: ['front-left-hand', 'rear-left-wrist', 'front-left-wrist'],
      right: ['front-right-hand', 'rear-right-wrist', 'front-right-wrist'],
    },
    foot: {
      left: ['front-left-foot', 'rear-left-ankle', 'front-left-ankle'],
      right: ['front-right-foot', 'rear-right-ankle', 'front-right-ankle'],
    },
    ankle: {
      left: [
        'front-left-ankle',
        'rear-left-leg',
        'rear-left-foot',
        'front-left-leg',
        'front-left-foot',
      ],
      right: [
        'front-right-ankle',
        'rear-right-leg',
        'rear-right-foot',
        'front-right-leg',
        'front-right-foot',
      ],
    },
    leg: {
      left: [
        'front-left-leg',
        'rear-left-knee',
        'rear-left-ankle',
        'front-left-knee',
        'front-left-ankle',
      ],
      right: [
        'front-right-leg',
        'rear-right-knee',
        'rear-right-ankle',
        'front-right-knee',
        'front-right-ankle',
      ],
    },
    knee: {
      left: [
        'front-left-knee',
        'rear-left-thigh',
        'rear-left-leg',
        'front-left-thigh',
        'front-left-leg',
      ],
      right: [
        'front-right-knee',
        'rear-right-thigh',
        'rear-right-leg',
        'front-right-thigh',
        'front-right-leg',
      ],
    },
    thigh: {
      left: [
        'front-left-thigh',
        'rear-left-hip',
        'rear-left-knee',
        'front-left-hip',
        'front-left-knee',
      ],
      right: [
        'front-right-thigh',
        'rear-right-hip',
        'rear-right-knee',
        'front-right-hip',
        'front-right-knee',
      ],
    },
    hip: {
      left: [
        'front-left-hip',
        'rear-left-abdomen',
        'rear-left-groin',
        'rear-left-thigh',
        'rear-left-lower-back',
        'rear-right-hip',
        'front-left-abdomen',
        'front-left-groin',
        'front-left-thigh',
        'front-right-hip',
      ],
      right: [
        'front-right-hip',
        'rear-right-abdomen',
        'rear-right-groin',
        'rear-right-thigh',
        'rear-right-lower-back',
        'rear-left-hip',
        'front-right-abdomen',
        'front-right-groin',
        'front-right-thigh',
        'front-left-hip',
      ],
    },
    groin: {
      left: [
        'front-left-groin',
        'front-left-abdomen',
        'rear-left-hip',
        'rear-right-groin',
        'rear-left-abdomen',
        'front-left-hip',
        'front-right-groin',
      ],
      right: [
        'front-right-groin',
        'front-right-abdomen',
        'rear-right-hip',
        'rear-left-groin',
        'rear-right-abdomen',
        'front-right-hip',
        'front-left-groin',
      ],
    },
    rib: {
      //upper back and lower back?
      left: ['rear-right-rib', 'front-left-rib', 'rear-left-abdomen'],
      right: ['rear-right-rib', 'front-right-rib', 'rear-right-abdomen'],
    },
    wrist: {
      left: [
        'front-left-wrist',
        'rear-left-hand',
        'rear-left-forearm',
        'front-left-hand',
        'front-left-forearm',
      ],
      right: [
        'front-right-wrist',
        'rear-right-hand',
        'rear-right-forearm',
        'front-right-hand',
        'front-right-forearm',
      ],
    },
    forearm: {
      left: [
        'front-left-forearm',
        'rear-left-wrist',
        'rear-left-elbow',
        'front-left-wrist',
        'front-left-elbow',
      ],
      right: [
        'front-right-forearm',
        'rear-right-wrist',
        'rear-right-elbow',
        'front-right-wrist',
        'front-right-elbow',
      ],
    },
    elbow: {
      left: [
        'front-left-elbow',
        'rear-left-forearm',
        'rear-left-arm',
        'front-left-forearm',
        'rear-left-arm',
      ],
      right: [
        'front-right-elbow',
        'rear-right-forearm',
        'rear-right-arm',
        'front-right-forearm',
        'front-right-arm',
      ],
    },
    arm: {
      left: [
        'front-left-arm',
        'rear-left-elbow',
        'rear-left-shoulder',
        'front-left-elbow',
        'front-left-shoulder',
      ],
      right: [
        'front-right-arm',
        'rear-right-elbow',
        'rear-right-shoulder',
        'front-right-elbow',
        'front-right-shoulder',
      ],
    },
    //what is the rear chest?
    chest: {
      left: [
        'front-left-upper-back',
        'rear-left-shoulder',
        'rear-left-neck',
        'rear-right-chest',
      ],
      right: [
        'front-right-upper-back',
        'rear-right-shoulder',
        'rear-right-neck',
        'rear-right-rib',
        'rear-left-chest',
      ],
    },
    shoulder: {
      left: [
        'front-left-shoulder',
        'rear-left-arm',
        'rear-left-chest',
        'rear-left-neck',
        'front-left-arm',
        'front-left-chest',
        'front-left-neck',
      ],
      right: [
        'front-right-shoulder',
        'rear-right-arm',
        'rear-right-chest',
        'rear-right-neck',
        'front-right-arm',
        'front-right-chest',
        'front-right-neck',
      ],
    },
    head: {
      left: [
        'rear-right-head',
        'front-left-head',
        'rear-left-neck',
        'front-left-neck',
        'rear-right-neck',
      ],
      right: [
        'rear-left-head',
        'front-right-head',
        'rear-right-neck',
        'front-right-neck',
        'rear-left-neck',
      ],
    },
    neck: {
      left: [
        'front-left-neck',
        'rear-left-shoulder',
        'rear-left-chest',
        'rear-right-neck',
        'rear-left-upper-back',
        'rear-left-head',
        'rear-right-head',
        'rear-right-upper-back',
      ],
      right: [
        'front-right-neck',
        'rear-right-shoulder',
        'rear-right-chest',
        'rear-left-neck',
        'rear-right-upper-back',
        'rear-right-head',
        'rear-left-upper-back',
        'rear-left-head',
      ],
    },
    'upper-back': {
      left: [
        'front-left-chest',
        'rear-left-shoulder',
        'rear-left-neck',
        'rear-left-upper-lower-back',
        'rear-right-upper-back',
        'front-left-abdomen',
        'front-left-rib',
        'rear-right-upper-lower-back',
        'rear-right-neck',
      ],
      right: [
        'front-right-chest',
        'rear-right-shoulder',
        'rear-right-neck',
        'rear-right-upper-lower-back',
        'rear-left-upper-back',
        'front-right-abdomen',
        'front-right-rib',
        'rear-left-upper-lower-back',
        'rear-left-neck',
      ],
    },
    'upper-lower-back': {
      left: [
        'rear-left-upper-back',
        'rear-right-upper-lower-back',
        'rear-left-lower-back',
        'front-left-abdomen',
        'rear-right-upper-back',
        'rear-right-lower-back',
        'front-left-rib',
      ],
      right: [
        'rear-right-upper-back',
        'rear-left-upper-lower-back',
        'rear-right-lower-back',
        'front-right-abdomen',
        'front-right-rib',
        'rear-left-lower-back',
        'rear-left-upper-back',
      ],
    },
    'lower-back': {
      left: [
        'rear-left-upper-lower-back',
        'rear-left-hip',
        'rear-right-lower-back',
        'front-left-abdomen',
        'front-left-rib',
        'rear-right-upper-lower-back',
        'rear-right-hip',
      ],
      right: [
        'rear-right-upper-lower-back',
        'rear-right-hip',
        'rear-left-lower-back',
        'front-right-abdomen',
        'front-right-rib',
        'rear-left-upper-lower-back',
        'rear-left-hip',
      ],
    },
  },
};

export const dedupeArrays = (input: string[][]) => {
  let existsAlready: string[] = [];
  return input.reduce((arr: string[][], group: string[]) => {
    const newGroup = group.filter((part: string) => {
      return !existsAlready.includes(part);
    });
    existsAlready = existsAlready.concat(group);
    if (newGroup?.length) {
      arr.push(newGroup);
    }
    return arr;
  }, []);
};

export const generalizeParts = (input: string[]) => {
  return input.reduce((arr: string[], part: string) => {
    if (part) {
      const basePart = part
        .replace('left-', '')
        .replace('right-', '')
        .replace('rear-', '')
        .replace('front-', '')
        .replace('-', ' ');
      if (!arr.includes(basePart)) {
        arr.push(basePart);
      }
    }
    return arr;
  }, []);
};

const locations = {
  left: ['front-left-rib', 'front-left-chest'],
  right: ['front-right-rib', 'front-right-chest'],
  both: ['rib', 'chest'],
};

export const reorganizeGroups = (input: string[][]) => {
  const partGroups: {
    [key: string]: {
      chest?: { groupIndex: number; part: string };
      rib?: { groupIndex: number; part: string };
    };
  } = {
    left: {},
    right: {},
    both: {},
  };

  input.forEach((group, groupIndex) => {
    group.forEach((part) => {
      if (locations.left.includes(part)) {
        const basePart = part.replace('front-left-', '') as 'chest' | 'rib';
        partGroups.left[basePart] = { groupIndex, part };
      }
      if (locations.right.includes(part)) {
        const basePart = part.replace('front-right-', '') as 'chest' | 'rib';
        partGroups.right[basePart] = { groupIndex, part };
      }
      if (locations.both.includes(part)) {
        const basePart = part as 'chest' | 'rib';
        partGroups.both[basePart] = { groupIndex, part };
      }
    });
  });

  const itemsToMessWith = Object.keys(partGroups).reduce(
    (
      obj: { [key: number]: { add?: string[]; remove?: string[] } },
      location,
    ) => {
      const reg = partGroups[location];
      if (hasProperty(reg, 'rib') && hasProperty(reg, 'chest')) {
        // If chest and rib are both selected, move rib to chest group
        if (reg.rib?.groupIndex !== reg.chest?.groupIndex) {
          // It isn't already there, so move it
          if (reg.rib?.part && reg.chest?.part) {
            obj[reg.rib.groupIndex] = obj[reg.rib.groupIndex] || {};
            obj[reg.rib.groupIndex].remove =
              obj[reg.rib.groupIndex].remove || [];
            obj[reg.rib.groupIndex].remove?.push(reg.rib.part);

            obj[reg.chest.groupIndex] = obj[reg.chest.groupIndex] || {};
            obj[reg.chest.groupIndex].add = obj[reg.chest.groupIndex].add || [];
            obj[reg.chest.groupIndex].add?.push(reg.rib.part);
          }
        }
      }
      return obj;
    },
    {},
  );

  // return input;
  return input.reduce(
    (arr: string[][], group: string[], groupIndex: number) => {
      if (itemsToMessWith[groupIndex]) {
        if (itemsToMessWith[groupIndex]?.remove?.length) {
          group = group.filter(
            (part) => !itemsToMessWith[groupIndex].remove?.includes(part),
          );
        }
        const stuffToAdd = itemsToMessWith[groupIndex]?.add;
        if (stuffToAdd?.length) {
          group = group.concat(stuffToAdd);
        }
      }
      if (group?.length) {
        arr.push(group);
      }
      return arr;
    },
    [],
  );

  // let existsAlready: string[] = [];
  // return input.reduce((arr: string[][], group: string[]) => {
  //   const newGroup = group.filter((part: string) => {
  //     return !existsAlready.includes(part);
  //   });
  //   existsAlready = existsAlready.concat(group);
  //   if (newGroup?.length) {
  //     arr.push(newGroup);
  //   }
  //   return arr;
  // }, []);
};

export const splitOffThorax = (input: string[][]) => {
  return input.reduce((arr: string[][], group) => {
    const hasLeftThorax = group.includes('left-thorax');
    const hasRightThorax = group.includes('right-thorax');
    const hasThorax = group.includes('thorax');
    if (hasLeftThorax || hasRightThorax || hasThorax) {
      const thoraxLessGroup = group.filter((part) => !part.includes('thorax'));
      if (thoraxLessGroup?.length) {
        arr.push(group);
      }
      if (hasLeftThorax) {
        arr.push(['left-thorax']);
      } else if (hasRightThorax) {
        arr.push(['right-thorax']);
      } else {
        arr.push(['thorax']);
      }
    } else {
      arr.push(group);
    }
    return arr;
  }, []);
};

// Iterate through all selected parts...we will want to remove the items we find from the array as we go...we will go down all paths, then all their paths as long as one is found, then do the next one.

export const bodyParts: {
  [key: string]: {
    [key: string]: {
      part: Part;
      leftOrRight: 'left' | 'right';
      d: string;
    }[];
  };
} = {
  male: {
    front: [
      {
        part: 'hand',
        leftOrRight: 'left',
        d: `M390.01,566.34c3.13,2.74,6.1,6.59,8.99,10.99c1.41,4.44,2.56,8.62,3.09,12.17
			c0.98,3.42,1.58,8.02,2.09,12.9c0.32,3.55,0,4.64-1,5.63c-3.12,0.39-5.47,0.32-6.99-2.09c-0.85-1.8-1.46-3.65-1.82-5.54
			c-0.27,13.53,0.03,25.18,0.82,35.24c0.54,10.5-0.81,16.29-4.45,16.44c-2.57-0.14-4.32-2.44-5.18-7.08
			c-0.09-14.9-1.24-25.26-3.63-30.33c-1.75,0.88-2.43,7.22-1.63,21.16c-0.65,12.12-2.2,21.32-6.63,21.07
			c-2.81-0.52-4.41-2.74-4.45-7.17c0.32-12.25,0.41-22.74,0-29.6c-1.41-6.31-3.01-7.29-4.9,0.09c-0.19,17.89-0.85,32.92-5.81,31.69
			c-2.44-0.77-3.92-2.46-4.18-5.36c-0.96-11.59-0.97-23.01,0-34.24c-0.71-2.25-1.61-2.93-3,0.18c-1.35,7.11-2.22,14.95-2.27,24.07
			c-0.78,2.06-1.77,3.4-3.09,3.63c-1.86-0.75-3.12-2.47-3.72-5.27c-0.91-40.41,0.37-55.27,2.59-59.21L390.01,566.34z`,
      },
      {
        part: 'foot',
        leftOrRight: 'left',
        d: `M279.99,1100.95c0.01,20.58,4.82,41.99,14.86,64.3c2.76,6.97,2.66,13.56,0,19.8
			c-1.24,2.97-3.22,4.19-6.47,2.36c-2.33,2.08-4.96,3.23-8.4,1.82c-2.75,2.92-6.07,3.45-10.08,1.09c-4.37,3.81-8.33,4.42-11.64,0
			c-0.79,1.56-2.17,2.26-4.8,1.09c2.22,4.19,0.45,7-8.13,7.45c-6.92,0.39-11.18-2.05-13.02-7.08c-1.6-8.94,0.97-27.48,4.7-47.95
			c-0.02-8.42-0.59-16.73-2.03-24.88c-1.62-6.08-2.23-12.06-2.03-17.98H279.99z`,
      },
      {
        part: 'ankle',
        leftOrRight: 'left',
        d: `M276.39,1047.19c-0.15,5.02-0.19,7.26,0.11,13.3c0.08,1.54,0.53,10.02,0.86,11.58
			c2.3,10.9,2.7,18.32,2.63,28.88h-47.06c-1.02-13.05,0.92-30.84,5.12-53.76H276.39`,
      },
      {
        part: 'leg',
        leftOrRight: 'left',
        d: `M301,876.19c5.83,23.21-1.98,77.06-19.67,139.67c-0.9,3.2-0.84,6.75-1.29,10.08
			c-0.96,7.11-2.73,14.04-3.69,21.25h-38.41c-0.19-16.45-1.15-32.08-1.29-47.31c-0.04-3.92,0.23-8.22-0.46-11.99
			c-6.95-37.64-3.2-78.65,0.83-111.7H301z`,
      },
      {
        part: 'knee',
        leftOrRight: 'left',
        d: `M300.2,786.29c-4.61,26.55-4.15,56.32,0.73,89.9h-63.96c0.56-7.18,0.45-15.3-0.43-27.33
			c-0.1-1.35-0.54-4.82-0.91-6.08c-2.6-8.81-9.44-28.44-12.62-56.49H300.2z`,
      },
      {
        part: 'thigh',
        leftOrRight: 'left',
        d: `M311.78,583.32l-109.26,10.35c-0.26,1.24,0.79-0.1,2.1,5c0.24,0.94,0.63,8.64,0.64,9.71
			c0.22,20.87-3.09,59.39,12.7,128.02c1.02,4.44,0.93,8.92,1.29,13.37c0.94,11.62,1.3,24.53,3.81,36.51h77.1
			C314.09,708.57,317.41,641.46,311.78,583.32z`,
      },
      {
        part: 'hip',
        leftOrRight: 'left',
        d: `M301.32,498.41l-70.28,32.11c0.66,28.01-5.57,51.37-28.54,63.07l109.54-10.28
			C311.82,558.68,307.68,529.53,301.32,498.41z`,
      },
      {
        part: 'groin',
        leftOrRight: 'right',
        d: `M203.25,543.27l-28.68-13.08c-0.25,30.47,6.14,52.02,27.92,63.34L203.25,543.27z`,
      },
      {
        part: 'abdomen',
        leftOrRight: 'left',
        d: `M203.25,344.12v199.15l97.95-44.95l-7.22-57.48c-29.66-7.16-55.53-35.64-75.62-96.72H203.25z`,
      },
      {
        part: 'rib',
        leftOrRight: 'left',
        d: `M299.3,344.12h-81.74c17.21,51.69,41.12,87.62,76.42,96.72C292.1,397.15,291.7,358.1,299.3,344.12z`,
      },
      {
        part: 'wrist',
        leftOrRight: 'left',
        d: `M382.38,537.41c-0.59,13.24,1.16,23.69,7.36,28.88l-45.22,9.26
			c1.58-11.2,3.72-23.66,3.54-32.28L382.38,537.41z`,
      },
      {
        part: 'forearm',
        leftOrRight: 'left',
        d: `M378.56,423.4l-51.54,8.26c1.07,18.15,14.41,94.88,21.25,111.61l34.33-5.86
			C385.1,483.25,384.44,442.34,378.56,423.4z`,
      },
      {
        part: 'elbow',
        leftOrRight: 'left',
        d: `M373.06,376.27c0.29,7.8,0.93,16.25,2.06,25.24c0.98,7.78,2.23,15.06,3.62,21.8l-51.58,8.35
			c-1.1-7.97-2.21-15.98-3.35-24.01c-1.11-7.83-2.23-15.63-3.37-23.4C337.99,381.6,355.53,378.93,373.06,376.27z`,
      },
      {
        part: 'arm',
        leftOrRight: 'left',
        d: `M367.28,317.78l-58.03,4.36c1.43,20.83,5.8,41.57,11.28,62.12l52.59-7.99
			C370.39,352.43,368.43,330.88,367.28,317.78z`,
      },
      {
        part: 'chest',
        leftOrRight: 'left',
        d: `M231.4,172.42l-28.37,13.41l-0.75,158.29h96.95l9.8-22.34
			C311.79,255.16,289.4,203.33,231.4,172.42z`,
      },
      {
        part: 'shoulder',
        leftOrRight: 'left',
        d: `M245.89,165.85l-14.42,6.56c48.11,26.26,81.29,69.26,77.89,149.36l57.76-4.09
			c-3.26-43.97,0.73-83.96-26.7-106.52C321.3,189.38,252.59,178.26,245.89,165.85z`,
      },
      {
        part: 'head',
        leftOrRight: 'left',
        d: `M203.01,0.85c0.08,24.66,0.16,49.58,0.24,74.24c0.08,23.1,0.15,46.5,0.23,69.6
			c-0.45,7,0.64,4.18,6.16,3.06c11.64-3.82,22.06-11.65,28.73-22.13c4.45-6.2,5.94-13.91,8.36-20.84
			c6.19-4.17,10.64-9.62,11.75-17.4c1.16-6.03,2.42-22.97-7.38-19.92c-0.27,0.08-0.13-0.41-0.11-0.57
			C253.2,37.66,236.47-1.28,203.01,0.85z`,
      },
      {
        part: 'neck',
        leftOrRight: 'left',
        d: `M202.71,185.7l42.79-20.02c-8.78-5.8-8.76-21.33-4.27-41.59
			c-13.5,13.86-26.74,25.52-38.52,25.18V185.7z`,
      },
      {
        part: 'hand',
        leftOrRight: 'right',
        d: `M15.23,566.34c-3.13,2.74-6.1,6.59-8.99,10.99c-1.41,4.44-2.56,8.62-3.09,12.17
			c-0.98,3.42-1.58,8.02-2.09,12.9c-0.32,3.55,0,4.64,1,5.63c3.12,0.39,5.47,0.32,6.99-2.09c0.85-1.8,1.46-3.65,1.82-5.54
			c0.27,13.53-0.03,25.18-0.82,35.24c-0.54,10.5,0.81,16.29,4.45,16.44c2.57-0.14,4.32-2.44,5.18-7.08
			c0.09-14.9,1.24-25.26,3.63-30.33c1.75,0.88,2.43,7.22,1.63,21.16c0.65,12.12,2.2,21.32,6.63,21.07c2.81-0.52,4.41-2.74,4.45-7.17
			c-0.32-12.25-0.41-22.74,0-29.6c1.41-6.31,3.01-7.29,4.9,0.09c0.19,17.89,0.85,32.92,5.81,31.69c2.44-0.77,3.92-2.46,4.18-5.36
			c0.96-11.59,0.97-23.01,0-34.24c0.71-2.25,1.61-2.93,3,0.18c1.35,7.11,2.22,14.95,2.27,24.07c0.78,2.06,1.77,3.4,3.09,3.63
			c1.86-0.75,3.12-2.47,3.72-5.27c0.91-40.41-0.37-55.27-2.59-59.21L15.23,566.34z`,
      },
      {
        part: 'foot',
        leftOrRight: 'right',
        d: `M125.65,1100.95c-0.01,20.58-4.74,41.99-14.61,64.3c-2.71,6.97-2.61,13.56,0,19.8
			c1.22,2.97,3.16,4.19,6.36,2.36c2.29,2.08,4.87,3.23,8.26,1.82c2.7,2.92,5.96,3.45,9.91,1.09c4.3,3.81,8.19,4.42,11.44,0
			c0.78,1.56,2.14,2.26,4.72,1.09c-2.18,4.19-0.44,7,7.99,7.45c6.8,0.39,10.99-2.05,12.8-7.08c1.57-8.94-0.95-27.48-4.62-47.95
			c0.01-8.42,0.58-16.73,2-24.88c1.6-6.08,2.2-12.06,2-17.98H125.65z`,
      },
      {
        part: 'ankle',
        leftOrRight: 'right',
        d: `M129.2,1047.19c0.15,5.02,0.19,7.26-0.11,13.3c-0.08,1.54-0.52,10.02-0.85,11.58
			c-2.26,10.9-2.66,18.32-2.59,28.88h46.36c1-13.05-0.91-30.84-5.04-53.76H129.2`,
      },
      {
        part: 'leg',
        leftOrRight: 'right',
        d: `M104.95,876.19c-5.73,23.21,1.95,77.06,19.34,139.67c0.89,3.2,0.83,6.75,1.27,10.08
			c0.94,7.11,2.68,14.04,3.63,21.25h37.78c0.19-16.45,1.13-32.08,1.27-47.31c0.04-3.92-0.23-8.22,0.45-11.99
			c6.84-37.64,3.14-78.65-0.82-111.7H104.95z`,
      },
      {
        part: 'knee',
        leftOrRight: 'right',
        d: `M104.95,786.29c4.59,26.55,4.86,56.32,0,89.9h62.93c-0.56-7.18-0.85-16.81,0.03-28.84
			c0.1-1.35,0.04-2.84,0.41-4.1c2.59-8.81,10.29-28.92,13.46-56.96H104.95z`,
      },
      {
        part: 'thigh',
        leftOrRight: 'right',
        d: `M93.24,583.32l109.26,10.35c0.26,1.24-0.79-0.1-2.1,5c-0.24,0.94-0.63,8.64-0.64,9.71
			c-0.22,20.87,3.09,59.39-12.7,128.02c-1.02,4.44-0.93,8.92-1.29,13.37c-0.94,11.62-1.3,24.53-3.81,36.51h-77.1
			C90.93,708.57,87.6,641.46,93.24,583.32z`,
      },
      {
        part: 'hip',
        leftOrRight: 'right',
        d: `M104.68,498.41l69.9,31.78c-0.66,28.01,4.95,51.7,27.92,63.39L93.24,583.23
			C93.46,558.62,98.32,529.53,104.68,498.41z`,
      },
      {
        part: 'groin',
        leftOrRight: 'left',
        d: `M203.25,543.27l27.79-12.76c0.25,30.47-6.76,51.7-28.54,63.02L203.25,543.27z`,
      },
      {
        part: 'abdomen',
        leftOrRight: 'right',
        d: `M203.25,344.12v199.15l-98.57-44.95l7.26-57.48c29.85-7.16,55.89-35.64,76.1-96.72H203.25z`,
      },
      {
        part: 'rib',
        leftOrRight: 'right',
        d: `M106.3,344.12h81.74c-17.21,51.69-41.12,87.62-76.42,96.72C113.5,397.15,113.9,358.1,106.3,344.12z`,
      },
      {
        part: 'wrist',
        leftOrRight: 'right',
        d: `M22.54,537.41c0.59,13.24-1.16,23.69-7.36,28.88l45.22,9.26
			c-1.58-11.2-3.72-23.66-3.54-32.28L22.54,537.41z`,
      },
      {
        part: 'forearm',
        leftOrRight: 'right',
        d: `M26.58,423.4l51.54,8.26c-1.07,18.15-14.41,94.88-21.25,111.61l-34.33-5.86
			C20.04,483.25,20.7,442.34,26.58,423.4z`,
      },
      {
        part: 'elbow',
        leftOrRight: 'right',
        d: `M32.76,376.45c-1.3,17.92-3.45,33.28-6.18,46.86l51.58,8.35c2.23-16.19,4.47-32.02,6.72-47.4L32.76,376.45z`,
      },
      {
        part: 'arm',
        leftOrRight: 'right',
        d: `M38.57,317.78l57.71,4.36c-2.53,20.65-5.89,39.76-11.22,62.12l-52.31-7.99
			C34.52,351.94,36.39,330.34,38.57,317.78z`,
      },
      {
        part: 'chest',
        leftOrRight: 'right',
        d: `M174.13,172.42l28.37,13.41l0.75,158.29H106.3l-9.8-22.34
			C93.74,255.16,116.13,203.33,174.13,172.42z`,
      },
      {
        part: 'shoulder',
        leftOrRight: 'right',
        d: `M159.71,165.85l14.42,6.56c-48.11,26.26-81.29,69.26-77.89,149.36l-57.76-4.09
			c3.26-43.97-0.73-83.96,26.7-106.52C84.3,189.38,153.02,178.26,159.71,165.85z`,
      },
      {
        part: 'head',
        leftOrRight: 'right',
        d: `M203.25,0.85c-0.08,24.66-0.17,49.58-0.25,74.24c-0.08,23.1-0.16,46.5-0.24,69.6
			c0.47,7-0.67,4.18-6.38,3.06c-12.05-3.82-22.85-11.65-29.75-22.13c-4.6-6.2-6.16-13.91-8.66-20.84
			c-6.42-4.17-11.02-9.62-12.16-17.4c-1.2-6.03-2.51-22.97,7.65-19.92c0.28,0.08,0.13-0.41,0.12-0.57
			C151.27,37.66,168.6-1.28,203.25,0.85z`,
      },
      {
        part: 'neck',
        leftOrRight: 'right',
        d: `M202.5,185.7l-42.79-20.02c8.78-5.8,8.76-21.33,4.27-41.59
			c13.5,13.86,26.74,25.52,38.52,25.18V185.7z`,
      },
    ],
    rear: [
      {
        part: 'hand',
        leftOrRight: 'right',
        d: `M390.01,566.34c3.13,2.74,6.1,6.59,8.99,10.99c1.41,4.44,2.56,8.62,3.09,12.17
			c0.98,3.42,1.58,8.02,2.09,12.9c0.32,3.55,0,4.64-1,5.63c-3.12,0.39-5.47,0.32-6.99-2.09c-0.85-1.8-1.46-3.65-1.82-5.54
			c-0.27,13.53,0.03,25.18,0.82,35.24c0.54,10.5-0.81,16.29-4.45,16.44c-2.57-0.14-4.32-2.44-5.18-7.08
			c-0.09-14.9-1.24-25.26-3.63-30.33c-1.75,0.88-2.43,7.22-1.63,21.16c-0.65,12.12-2.2,21.32-6.63,21.07
			c-2.81-0.52-4.41-2.74-4.45-7.17c0.32-12.25,0.41-22.74,0-29.6c-1.41-6.31-3.01-7.29-4.9,0.09c-0.19,17.89-0.85,32.92-5.81,31.69
			c-2.44-0.77-3.92-2.46-4.18-5.36c-0.96-11.59-0.97-23.01,0-34.24c-0.71-2.25-1.61-2.93-3,0.18c-1.35,7.11-2.22,14.95-2.27,24.07
			c-0.78,2.06-1.77,3.4-3.09,3.63c-1.86-0.75-3.12-2.47-3.72-5.27c-0.91-40.41,0.37-55.27,2.59-59.21L390.01,566.34z`,
      },
      {
        part: 'foot',
        leftOrRight: 'right',
        d: `M279.99,1100.95c0.01,20.58,4.82,41.99,14.86,64.3c2.76,6.97,2.66,13.56,0,19.8
			c-1.24,2.97-3.22,4.19-6.47,2.36c-2.33,2.08-4.96,3.23-8.4,1.82c-2.75,2.92-6.07,3.45-10.08,1.09c-4.37,3.81-8.33,4.42-11.64,0
			c-0.79,1.56-2.17,2.26-4.8,1.09c2.22,4.19,0.45,7-8.13,7.45c-6.92,0.39-11.18-2.05-13.02-7.08c-1.6-8.94,0.97-27.48,4.7-47.95
			c-0.02-8.42-0.59-16.73-2.03-24.88c-1.62-6.08-2.23-12.06-2.03-17.98H279.99z`,
      },
      {
        part: 'ankle',
        leftOrRight: 'right',
        d: `M276.39,1047.19c-0.15,5.02-0.19,7.26,0.11,13.3c0.08,1.54,0.53,10.02,0.86,11.58
			c2.3,10.9,2.7,18.32,2.63,28.88h-47.06c-1.02-13.05,0.92-30.84,5.12-53.76H276.39`,
      },
      {
        part: 'leg',
        leftOrRight: 'right',
        d: `M301,876.19c5.83,23.21-1.98,77.06-19.67,139.67c-0.9,3.2-0.84,6.75-1.29,10.08
			c-0.96,7.11-2.73,14.04-3.69,21.25h-38.41c-0.19-16.45-1.15-32.08-1.29-47.31c-0.04-3.92,0.23-8.22-0.46-11.99
			c-6.95-37.64-3.2-78.65,0.83-111.7H301z`,
      },
      {
        part: 'knee',
        leftOrRight: 'right',
        d: `M300.2,786.29c-4.61,26.55-4.15,56.32,0.73,89.9h-63.96c0.56-7.18,0.45-15.3-0.43-27.33
			c-0.1-1.35-0.54-4.82-0.91-6.08c-2.6-8.81-9.44-28.44-12.62-56.49H300.2z`,
      },
      {
        part: 'thigh',
        leftOrRight: 'right',
        d: `M311.78,583.32l-109.26,10.35c-0.26,1.24,0.79-0.1,2.1,5c0.24,0.94,0.63,8.64,0.64,9.71
			c0.22,20.87-3.09,59.39,12.7,128.02c1.02,4.44,0.93,8.92,1.29,13.37c0.94,11.62,1.3,24.53,3.81,36.51h77.1
			C314.09,708.57,317.41,641.46,311.78,583.32z`,
      },
      {
        part: 'hip',
        leftOrRight: 'left',
        d: `M202.69,580.32c-3.65,0.19-6.91,6.91-6.15,12.61c-34.44-3.21-68.88-6.43-103.33-9.64
				c0.23-24.61,5.2-53.86,11.6-84.98l97.87,44.95`,
      },
      {
        part: 'groin',
        leftOrRight: 'left',
        d: `M202.69,580.32c-3.9,0.56-6.74,6.89-6.15,12.61c0,0-0.01,0.01,0,0.01c0-0.01,5.97,0.73,5.97,0.73
				L202.69,580.32z`,
      },
      {
        part: 'upper-lower-back',
        leftOrRight: 'left',
        d: `M203.25,398.63v-54.51h-96.68c6,16.38,7.55,34.78,5.45,54.84L203.25,398.63z`,
      },
      {
        part: 'lower-back',
        leftOrRight: 'left',
        d: `M203.25,398.63c-0.19,48.21-0.38,96.43-0.56,144.64l-97.95-44.95c1.08-9.46,6.95-15.82,5.85-31.19c-1.66-23.17,1.14-53.29,1.44-68.17C142.44,398.85,172.84,398.74,203.25,398.63z`,
      },
      {
        part: 'wrist',
        leftOrRight: 'right',
        d: `M382.38,537.41c-0.59,13.24,1.16,23.69,7.36,28.88l-45.22,9.26
			c1.58-11.2,3.72-23.66,3.54-32.28L382.38,537.41z`,
      },
      {
        part: 'forearm',
        leftOrRight: 'right',
        d: `M378.56,423.4l-51.54,8.26c1.07,18.15,14.41,94.88,21.25,111.61l34.33-5.86
			C385.1,483.25,384.44,442.34,378.56,423.4z`,
      },
      {
        part: 'elbow',
        leftOrRight: 'right',
        d: `M373.06,376.27c0.29,7.8,0.93,16.25,2.06,25.24c0.98,7.78,2.23,15.06,3.62,21.8l-51.58,8.35
			c-1.1-7.97-2.21-15.98-3.35-24.01c-1.11-7.83-2.23-15.63-3.37-23.4C337.99,381.6,355.53,378.93,373.06,376.27z`,
      },
      {
        part: 'arm',
        leftOrRight: 'right',
        d: `M367.28,317.78l-58.03,4.36c1.43,20.83,5.8,41.57,11.28,62.12l52.59-7.99
			C370.39,352.43,368.43,330.88,367.28,317.78z`,
      },
      {
        part: 'upper-back',
        leftOrRight: 'left',
        d: `M174.13,172.42l28.37,13.41l0.75,158.29H106.3l-9.8-22.34
				C151.33,301.18,180.55,258.64,174.13,172.42z`,
      },
      {
        part: 'shoulder',
        leftOrRight: 'left',
        d: `M159.6,165.85l14.42,6.56c3.65,60.17-3.13,120.46-77.89,149.36l-57.76-4.09
				c3.26-43.97-0.73-83.96,26.7-106.52C84.19,189.38,152.9,178.26,159.6,165.85z`,
      },
      {
        part: 'head',
        leftOrRight: 'left',
        d: `M202.9,103.56
				c0,0,0.03-9.49,0.1-28.47c0.08-24.66,0.17-49.58,0.25-74.24c-34.65-2.13-51.98,36.81-49.68,66.03c0.02,0.16,0.17,0.65-0.12,0.57
				c-10.16-3.05-8.84,13.89-7.65,19.92c0.31,2.13,0.89,4.09,1.68,5.89c2.86,6.45,7.91,9.99,10.48,11.51
				c0.43,1.42,1.06,3.41,1.9,5.76L202.9,103.56z`,
      },
      {
        part: 'neck',
        leftOrRight: 'left',
        d: `M202.5,185.7l-42.79-20.02c2.5-3.17,5.85-8.37,7.11-15.37
				c1.07-5.96,0.05-11.07-2.03-21.04c-1-4.79-2.53-11.22-4.92-18.73c7.29-1.16,14.59-2.33,21.9-3.52c7.06-1.14,14.1-2.29,21.12-3.45
				C202.77,130.94,202.63,158.32,202.5,185.7z`,
      },
      {
        part: 'hand',
        leftOrRight: 'left',
        d: `M15.23,566.34c-3.13,2.74-6.1,6.59-8.99,10.99c-1.41,4.44-2.56,8.62-3.09,12.17
			c-0.98,3.42-1.58,8.02-2.09,12.9c-0.32,3.55,0,4.64,1,5.63c3.12,0.39,5.47,0.32,6.99-2.09c0.85-1.8,1.46-3.65,1.82-5.54
			c0.27,13.53-0.03,25.18-0.82,35.24c-0.54,10.5,0.81,16.29,4.45,16.44c2.57-0.14,4.32-2.44,5.18-7.08
			c0.09-14.9,1.24-25.26,3.63-30.33c1.75,0.88,2.43,7.22,1.63,21.16c0.65,12.12,2.2,21.32,6.63,21.07c2.81-0.52,4.41-2.74,4.45-7.17
			c-0.32-12.25-0.41-22.74,0-29.6c1.41-6.31,3.01-7.29,4.9,0.09c0.19,17.89,0.85,32.92,5.81,31.69c2.44-0.77,3.92-2.46,4.18-5.36
			c0.96-11.59,0.97-23.01,0-34.24c0.71-2.25,1.61-2.93,3,0.18c1.35,7.11,2.22,14.95,2.27,24.07c0.78,2.06,1.77,3.4,3.09,3.63
			c1.86-0.75,3.12-2.47,3.72-5.27c0.91-40.41-0.37-55.27-2.59-59.21L15.23,566.34z`,
      },
      {
        part: 'foot',
        leftOrRight: 'left',
        d: `M125.65,1100.95c-0.01,20.58-4.74,41.99-14.61,64.3c-2.71,6.97-2.61,13.56,0,19.8
			c1.22,2.97,3.16,4.19,6.36,2.36c2.29,2.08,4.87,3.23,8.26,1.82c2.7,2.92,5.96,3.45,9.91,1.09c4.3,3.81,8.19,4.42,11.44,0
			c0.78,1.56,2.14,2.26,4.72,1.09c-2.18,4.19-0.44,7,7.99,7.45c6.8,0.39,10.99-2.05,12.8-7.08c1.57-8.94-0.95-27.48-4.62-47.95
			c0.01-8.42,0.58-16.73,2-24.88c1.6-6.08,2.2-12.06,2-17.98H125.65z`,
      },
      {
        part: 'ankle',
        leftOrRight: 'left',
        d: `M129.2,1047.19c0.15,5.02,0.19,7.26-0.11,13.3c-0.08,1.54-0.52,10.02-0.85,11.58
			c-2.26,10.9-2.66,18.32-2.59,28.88h46.36c1-13.05-0.91-30.84-5.04-53.76H129.2`,
      },
      {
        part: 'leg',
        leftOrRight: 'left',
        d: `M104.95,876.19c-5.73,23.21,1.95,77.06,19.34,139.67c0.89,3.2,0.83,6.75,1.27,10.08
			c0.94,7.11,2.68,14.04,3.63,21.25h37.78c0.19-16.45,1.13-32.08,1.27-47.31c0.04-3.92-0.23-8.22,0.45-11.99
			c6.84-37.64,3.14-78.65-0.82-111.7H104.95z`,
      },
      {
        part: 'knee',
        leftOrRight: 'left',
        d: `M104.95,786.29c4.59,26.55,4.86,56.32,0,89.9h62.93c-0.56-7.18-0.85-16.81,0.03-28.84
			c0.1-1.35,0.04-2.84,0.41-4.1c2.59-8.81,10.29-28.92,13.46-56.96H104.95z`,
      },
      {
        part: 'thigh',
        leftOrRight: 'left',
        d: `M93.24,583.32l109.26,10.35c0.26,1.24-0.79-0.1-2.1,5c-0.24,0.94-0.63,8.64-0.64,9.71
			c-0.22,20.87,3.09,59.39-12.7,128.02c-1.02,4.44-0.93,8.92-1.29,13.37c-0.94,11.62-1.3,24.53-3.81,36.51h-77.1
			C90.93,708.57,87.6,641.46,93.24,583.32z`,
      },
      {
        part: 'hip',
        leftOrRight: 'right',
        d: `M202.69,580.32c3.63,0.19,6.87,6.91,6.11,12.61c34.23-3.21,68.46-6.43,102.68-9.64
				c-0.23-24.61-4.36-53.77-10.72-84.89l-98.07,44.86`,
      },
      {
        part: 'groin',
        leftOrRight: 'right',
        d: `M202.69,580.32c3.72,0.34,7.29,6.69,6.67,12.61l-6.86,0.73L202.69,580.32z`,
      },
      {
        part: 'upper-lower-back',
        leftOrRight: 'right',
        d: `M203.25,344.12l95.98,0c-6.63,9.17-8.1,34.79-7.65,54.84l-88.33-0.33V344.12z`,
      },
      {
        part: 'lower-back',
        leftOrRight: 'right',
        d: `M203.25,398.63c30.08,0.11,58.25,0.22,88.33,0.33c1.48,21.76,2.76,44.39,2.01,63.6c-0.61,15.58,5.57,28.43,7.19,35.76l-97.95,44.95L203.25,398.63z`,
      },
      {
        part: 'wrist',
        leftOrRight: 'left',
        d: `M22.54,537.41c0.59,13.24-1.16,23.69-7.36,28.88l45.22,9.26
			c-1.58-11.2-3.72-23.66-3.54-32.28L22.54,537.41z`,
      },
      {
        part: 'forearm',
        leftOrRight: 'left',
        d: `M26.58,423.4l51.54,8.26c-1.07,18.15-14.41,94.88-21.25,111.61l-34.33-5.86
			C20.04,483.25,20.7,442.34,26.58,423.4z`,
      },
      {
        part: 'elbow',
        leftOrRight: 'left',
        d: `M32.76,376.45c-1.3,17.92-3.45,33.28-6.18,46.86l51.58,8.35c2.23-16.19,4.47-32.02,6.72-47.4L32.76,376.45z`,
      },
      {
        part: 'arm',
        leftOrRight: 'left',
        d: `M38.57,317.78l57.71,4.36c-2.53,20.65-5.89,39.76-11.22,62.12l-52.31-7.99
			C34.52,351.94,36.39,330.34,38.57,317.78z`,
      },
      {
        part: 'upper-back',
        leftOrRight: 'right',
        d: `M231.4,172.42l-28.37,13.41l-0.75,158.29h96.95l9.8-22.34
				C263.55,303.72,223.73,269.43,231.4,172.42z`,
      },
      {
        part: 'shoulder',
        leftOrRight: 'right',
        d: `M245.89,165.85l-14.42,6.56c-3.65,60.17,3.13,120.46,77.89,149.36l57.76-4.09
				c-3.26-43.97,0.73-83.96-26.7-106.52C321.3,189.38,252.59,178.26,245.89,165.85z`,
      },
      {
        part: 'head',
        leftOrRight: 'right',
        d: `M244.85,110.68
				c0.84-2.41,1.46-4.45,1.88-5.91c2.43-1.5,7.13-4.92,9.92-11.05c0.88-1.93,1.5-4.03,1.83-6.35c1.16-6.03,2.42-22.97-7.38-19.92
				c-0.27,0.08-0.13-0.41-0.11-0.57c2.22-29.22-14.52-68.16-47.97-66.03c0.08,24.66,0.16,49.58,0.24,74.24
				c0.09,28.56,0.09,28.56,0.09,28.56L244.85,110.68z`,
      },
      {
        part: 'neck',
        leftOrRight: 'right',
        d: `M202.71,185.7l42.79-20.02c-2.27-3.02-5.57-8.23-7.11-15.37
				c-1.55-7.14-0.6-12.79,0.51-19.5c0.83-4.97,2.46-12.02,5.95-20.12c-7.43-1.25-14.86-2.5-22.29-3.76
				c-6.55-1.11-13.1-2.23-19.65-3.36C202.84,130.94,202.77,158.32,202.71,185.7z`,
      },
    ],
  },
};

export const defaultBodyParts: BodyParts = {
  front: {
    hand: {
      left: false,
      right: false,
    },
    foot: {
      left: false,
      right: false,
    },
    ankle: {
      left: false,
      right: false,
    },
    leg: {
      left: false,
      right: false,
    },
    knee: {
      left: false,
      right: false,
    },
    thigh: {
      left: false,
      right: false,
    },
    hip: {
      left: false,
      right: false,
    },
    groin: {
      left: false,
      right: false,
    },
    abdomen: {
      left: false,
      right: false,
    },
    rib: {
      left: false,
      right: false,
    },
    wrist: {
      left: false,
      right: false,
    },
    forearm: {
      left: false,
      right: false,
    },
    elbow: {
      left: false,
      right: false,
    },
    arm: {
      left: false,
      right: false,
    },
    chest: {
      left: false,
      right: false,
    },
    shoulder: {
      left: false,
      right: false,
    },
    head: {
      left: false,
      right: false,
    },
    neck: {
      left: false,
      right: false,
    },
  },
  rear: {
    hand: {
      left: false,
      right: false,
    },
    foot: {
      left: false,
      right: false,
    },
    ankle: {
      left: false,
      right: false,
    },
    leg: {
      left: false,
      right: false,
    },
    knee: {
      left: false,
      right: false,
    },
    thigh: {
      left: false,
      right: false,
    },
    hip: {
      left: false,
      right: false,
    },
    groin: {
      left: false,
      right: false,
    },
    'lower-back': {
      left: false,
      right: false,
    },
    'upper-lower-back': {
      left: false,
      right: false,
    },
    wrist: {
      left: false,
      right: false,
    },
    forearm: {
      left: false,
      right: false,
    },
    elbow: {
      left: false,
      right: false,
    },
    arm: {
      left: false,
      right: false,
    },
    'upper-back': {
      left: false,
      right: false,
    },
    shoulder: {
      left: false,
      right: false,
    },
    head: {
      left: false,
      right: false,
    },
    neck: {
      left: false,
      right: false,
    },
  },
};

export const getPartsSelected = (selected: BodyParts) => {
  return Object.entries(selected || {}).reduce(
    (arr: string[], [frontOrBack, selectedParts]: any) => {
      arr = arr.concat(
        Object.entries(selectedParts).reduce(
          (arr2: string[], [part, leftOrRightSide]: any) => {
            if (leftOrRightSide.left) {
              arr2.push(`${frontOrBack}-left-${part}`);
            }
            if (leftOrRightSide.right) {
              arr2.push(`${frontOrBack}-right-${part}`);
            }
            return arr2;
          },
          [],
        ),
      );
      return arr;
    },
    [],
  );
};

export const getPartsSelectedExtra = (selected: BodyParts) => {
  return Object.entries(selected).reduce(
    (
      arr: {
        frontOrBack: Side;
        part: Part;
        leftOrRight: LeftOrRight;
        partString: string;
        connectedParts: string[];
      }[],
      [frontOrBack, selectedParts],
    ) => {
      const frontOrBackGTG = frontOrBack as Side;
      arr = arr.concat(
        Object.entries(selectedParts).reduce(
          (
            arr2: {
              frontOrBack: Side;
              part: Part;
              leftOrRight: LeftOrRight;
              partString: string;
              connectedParts: string[];
            }[],
            [part, leftOrRightSide],
          ) => {
            const partGTG = part as Part;
            if (leftOrRightSide.left) {
              arr2.push({
                frontOrBack: frontOrBackGTG,
                part: part as Part,
                leftOrRight: 'left',
                partString: `${frontOrBack}-left-${part}`,
                connectedParts:
                  touchingParts[frontOrBackGTG][partGTG]?.left || [],
              });
            }
            if (leftOrRightSide.right) {
              arr2.push({
                frontOrBack: frontOrBack as Side,
                part: part as Part,
                leftOrRight: 'right',
                partString: `${frontOrBack}-right-${part}`,
                connectedParts:
                  touchingParts[frontOrBackGTG][partGTG]?.right || [],
              });
            }
            return arr2;
          },
          [],
        ),
      );
      return arr;
    },
    [],
  );
};

export const getTheRest = (
  previousPartsLeft: any,
  partsConnectedToFirstPart: any,
  previousParts: any,
  partChecker: any,
) => {
  const partsConnectedToWhatGotConnected = previousPartsLeft.filter(
    (partLeft: any) => {
      return partsConnectedToFirstPart.some((partConnected: any) => {
        return partChecker[partConnected].includes(partLeft);
      });
    },
  );
  let contiguousParts = [...previousParts, ...partsConnectedToWhatGotConnected];
  let partsLeft = previousPartsLeft.filter((part: any) => {
    return !contiguousParts.includes(part);
  });

  if (partsLeft?.length && partsConnectedToWhatGotConnected?.length) {
    const [contig, left] = getTheRest(
      partsLeft,
      partsConnectedToWhatGotConnected,
      contiguousParts,
      partChecker,
    );
    contiguousParts = contig;
    partsLeft = left;
  }

  return [contiguousParts, partsLeft];
};

export const getGroup = (parts: string[], partChecker: any): string[][] => {
  const firstPart = parts.shift() as string;
  const partsConnectedToFirstPart = parts.filter(
    (part) => partChecker[firstPart]?.includes(part),
  );

  const contiguousParts = [firstPart, ...partsConnectedToFirstPart];
  const partsLeft = parts.filter((part) => {
    return !contiguousParts?.includes(part);
  });

  const [contig, left] = getTheRest(
    partsLeft,
    partsConnectedToFirstPart,
    contiguousParts,
    partChecker,
  );

  if (left?.length) {
    return [contig, ...getGroup(left, partChecker)];
  }

  return [contig];
};

export const getContiguousParts = (parts: string[]) => {
  return getGroup(parts, touchingParts);
};

export const createGroups = (
  groupParts: string[],
  selectedParts: string[][],
  groupName: string,
  requiredParts?: string[],
  numberOfRequiredParts = 2,
) => {
  const tmpArr: string[] = [];
  const groupCount: { [key: number]: number } = {};
  if (
    requiredParts?.length &&
    !selectedParts.some((partGroup) =>
      partGroup.some((part) => requiredParts.includes(part)),
    )
  ) {
    return false;
  }
  return {
    groups: selectedParts.reduce((arr: string[], group, groupIndex) => {
      group.forEach((part) => {
        if (groupParts.includes(part)) {
          groupCount[groupIndex] = (groupCount[groupIndex] || 0) + 1;
          tmpArr.push(part);
        }
      });
      if (Object.keys(groupCount)?.length >= numberOfRequiredParts) {
        arr = arr.concat(tmpArr);
      }
      // TODO: This feels like cheating...I'm not sure why but sometimes there are dupes (select both left and right shoudler and elbow)
      return Array.from(new Set(arr));
    }, []),
    groupName,
  };
};

export const createAnsweredPrelimQuestion = (val: any, question: any) => {
  val.question = question.question;
  val.primaryGroup = question.groupName;
  val.parts = question.parts;

  return val;
};

export enum SpineParts {
  'C0' = 'C0',
  'C1' = 'C1',
  'C2' = 'C2',
  'C3' = 'C3',
  'C4' = 'C4',
  'C5' = 'C5',
  'C6' = 'C6',
  'C7' = 'C7',
  'T1' = 'T1',
  'T2' = 'T2',
  'T3' = 'T3',
  'T4' = 'T4',
  'T5' = 'T5',
  'T6' = 'T6',
  'T7' = 'T7',
  'T8' = 'T8',
  'T9' = 'T9',
  'T10' = 'T10',
  'T11' = 'T11',
  'T12' = 'T12',
  'L1' = 'L1',
  'L2' = 'L2',
  'L3' = 'L3',
  'L4' = 'L4',
  'L5' = 'L5',
  'S' = 'S',
  'C' = 'C',
  'PL' = 'PL',
  'PR' = 'PR',
  'LLI' = 'LLI',
}

export const spinePartsTopPercent: { [key in SpineParts]: string } = {
  [SpineParts.C0]: '0%',
  [SpineParts.C1]: '5.4%',
  [SpineParts.C2]: '7.5%',
  [SpineParts.C3]: '10.2%',
  [SpineParts.C4]: '13%',
  [SpineParts.C5]: '15.7%',
  [SpineParts.C6]: '18.3%',
  [SpineParts.C7]: '21.2%',
  [SpineParts.T1]: '24.4%',
  [SpineParts.T2]: '27.5%',
  [SpineParts.T3]: '30.7%',
  [SpineParts.T4]: '33.8%',
  [SpineParts.T5]: '37.1%',
  [SpineParts.T6]: '40.7%',
  [SpineParts.T7]: '44%',
  [SpineParts.T8]: '47.6%',
  [SpineParts.T9]: '51.3%',
  [SpineParts.T10]: '55.1%',
  [SpineParts.T11]: '59.1%',
  [SpineParts.T12]: '62.9%',
  [SpineParts.L1]: '66.5%',
  [SpineParts.L2]: '69.9%',
  [SpineParts.L3]: '73.3%',
  [SpineParts.L4]: '76.8%',
  [SpineParts.L5]: '80.2%',
  [SpineParts.S]: '86%',
  [SpineParts.C]: '91.4%',
  [SpineParts.PL]: '94.5%',
  [SpineParts.PR]: '94.5%',
  [SpineParts.LLI]: 'nothing',
};

export const spineParts: {
  part: SpineParts;
  d: string;
}[] = [
  {
    part: SpineParts.C0,
    d: `M230.3,33c0,0,9.6,16,3.9,41
  c-3.9,17.4,17.5,11.9,26.9,5c9.4-6.9,15.4-0.8,16.5,2.4s9.6,1.3,16.2-5s33.2-13,49.1-5.1s17,14.2,26.1,5.4s33.4,16.2,41.3,6.7
  s-3.7-11.6,1.5-34.3s15.3-25.2,5.9-32.2S382.2-0.5,369.5,0.4s-29.9,13.5-45,13.6S297.7,2.1,282,1.9s-37,6.7-51.7,18.5
  c0,0-5.8,2.9-3.4,6.9S230.3,33,230.3,33z`,
  },
  {
    part: SpineParts.C1,
    d: `M322.3,108h26.9c2.4,0,4.9-0.4,7.2-1.1
  c4.4-1.4,12.4-3.1,16.4,1.5c5.6,6.5,17,17.4,27.9,11.2c1.7-1.4,3-3.4,3.4-5.5c1-3.9,0.2-5.6-4.2-9.2
  c-5.9-4.8-10.8-10.7-14.4-17.3c-1.9-3.4-3.3-6-4.2-7.8c-1.3-2.5-3.4-4.6-5.9-5.9c-3.7-2.1-7.5-3.8-11.5-5.1
  c-5.9-1.6-6.1,3.9-10.8,5s-10.8-7.3-15.7-9.2s-18.2-4-31.1,1.9c-5.5,2.5-8,8-13.3,8.6c-4.4,0.5-5.5-4.7-8.7-4.6
  c-2.3,0.1-6.9,1.8-11.6,4.1c-3.2,1.6-5.7,4.1-7.3,7.3c-2.2,4.4-4.8,8.6-7.7,12.6c-5.9,7.8-16.5,12.8-14.3,19.5
  s8.6,11.2,22.5,3.7c7.4-4,8.4-13.3,17.6-11.9s8.9,2.7,13.2,2.6C300.6,108.2,322.3,108,322.3,108z`,
  },
  {
    part: SpineParts.C2,
    d: `M321.9,163.2h37c1.8,0,3.4-1,4.1-2.6
  c0.2-0.4,0.3-0.9,0.3-1.3c0.1-2.6-1.3-3-1.3-3s5.9-2.7,0.2-15.1c5.2,1.5,24.1,3.9,24.4-7.7s-14.8-13.5-25.2-10.6
  c0,0,3-10.8,0.7-15.1c-0.5-0.8-1.3-1.3-2.2-1.3h-71.2c-1.5,0-2.9,0.9-3.5,2.4c-0.8,2.2-1.2,6.2,0.4,13.7
  c-1.7,0.2-22-5-24.8,9.9c0,0-0.7,10.3,11.2,9.6s12.9-0.6,12.9-0.6s-4.4,9.1,0.2,14.5c-1,0.8-1.1,4,0.1,6
  c0.5,0.8,1.4,1.3,2.4,1.3L321.9,163.2z`,
  },
  {
    part: SpineParts.C3,
    d: `M321.9,219.5h37c1.8,0,3.4-1,4.1-2.6
  c0.2-0.4,0.3-0.9,0.3-1.3c0.1-2.6-1.3-3-1.3-3s5.9-2.7,0.2-15.1c5.2,1.5,24.1,3.9,24.4-7.7s-14.8-13.5-25.2-10.6
  c0,0,3-10.8,0.7-15.1c-0.4-0.8-1.3-1.3-2.2-1.3h-71.2c-1.5,0-2.9,0.9-3.5,2.4c-0.8,2.2-1.2,6.2,0.4,13.7
  c-1.7,0.2-22-5-24.8,9.9c0,0-0.7,10.3,11.2,9.6s12.9-0.6,12.9-0.6s-4.4,9.1,0.2,14.5c-1,0.8-1.1,4.1,0.1,6
  c0.5,0.8,1.4,1.3,2.4,1.3L321.9,219.5z`,
  },
  {
    part: SpineParts.C4,
    d: `M321.9,276.2h37c1.8,0,3.4-1,4.1-2.6
  c0.2-0.4,0.3-0.9,0.3-1.3c0.1-2.6-1.3-3-1.3-3s5.9-2.7,0.2-15.1c5.2,1.5,24.1,3.9,24.4-7.7s-14.8-13.5-25.2-10.6
  c0,0,3-10.8,0.7-15.1c-0.4-0.8-1.3-1.3-2.2-1.3h-71.2c-1.5,0-2.9,0.9-3.5,2.4c-0.8,2.2-1.2,6.2,0.4,13.7
  c-1.7,0.2-22-5-24.8,9.9c0,0-0.7,10.3,11.2,9.6s12.9-0.6,12.9-0.6s-4.4,9.1,0.2,14.5c-1,0.8-1.1,4,0.1,6
  c0.5,0.8,1.4,1.3,2.4,1.3L321.9,276.2z`,
  },
  {
    part: SpineParts.C5,
    d: `M321.9,332.9h37.6c1.8,0,3.4-1,4.1-2.6
  c0.2-0.4,0.3-0.9,0.3-1.3c0-0.6,0-1.2-0.2-1.8c-0.3-0.8-0.2-1.8,0.4-2.5c1-1.4,2-4.2,0.5-9.4c-0.4-1.5,0.4-3,1.9-3.5
  c0.4-0.1,0.8-0.1,1.2-0.1c7.6,1,19.8,0.6,20-8.6c0.3-10.1-11.5-12.8-21.5-11.4c-1.5,0.2-2.9-0.8-3.2-2.3c0-0.3,0-0.6,0-0.9
  c0.4-2,0.6-4,0.7-6c0-3.5-2.9-6.3-6.4-6.3c0,0,0,0,0,0h-69.6c-1,0-2,0.5-2.5,1.4c-0.9,1.6-1.7,4.8-0.7,11.1
  c0.2,1.5-0.8,2.9-2.4,3.1c-0.2,0-0.5,0-0.7,0c-6.5-0.7-19-1.2-21.3,10c0,0.2-0.1,0.4-0.1,0.6c0,1.8,0.6,9.9,11.4,9.3
  c4.2-0.2,7-0.4,9-0.5c1.5-0.1,2.8,1.1,2.9,2.6c0,0.3,0,0.6-0.1,0.9c-0.7,2.8-1.2,6.6,0.6,9.7c0.4,0.7,0.5,1.4,0.2,2.2
  c-0.4,1.7-0.2,3.5,0.7,5c0.6,0.8,1.5,1.3,2.5,1.3L321.9,332.9z`,
  },
  {
    part: SpineParts.C6,
    d: `M321.9,389.6H360c1.8,0,3.4-1,4.2-2.6
  c0.2-0.4,0.3-0.9,0.3-1.3c0.1-2.6-1.3-3-1.3-3s6.1-2.7,0.2-15.1c5.4,1.5,24.8,3.9,25.2-7.7s-15.3-13.5-26-10.5
  c0,0,2.4-8.4,1.3-13.4c-0.4-1.8-2-3-3.8-3h-73.3c-0.8,0-1.6,0.4-2.1,1.1c-1.1,1.6-2.3,5.6-0.3,15c-1.7,0.2-22.6-5-25.5,9.9
  c0,0-0.7,10.3,11.6,9.6s13.3-0.6,13.3-0.6s-4.5,9.1,0.2,14.5c-1.1,0.8-1.1,4,0.1,6c0.6,0.8,1.5,1.3,2.5,1.3L321.9,389.6z`,
  },
  {
    part: SpineParts.C7,
    d: `M321.5,454.1h41.5c1.2,0,2.3-0.7,2.8-1.9
  c0.3-0.8,0.4-1.6,0.4-2.5c-0.1-2.4-1.4-4.7-3.6-5.9c0,0,5.3,0.6,5.2-5.9c-0.1-4.3-1.3-8.5-3.3-12.3c0,0,5.8-3.5,14.5-1.5
  s23.7,0.9,22.2-11.2s-18.1-14.4-27-11.7s-9.4,4-9.4,4s1.6-7,1-12c-0.3-2.6-2.5-4.5-5.1-4.5c-13.3,0-63.9,0.6-74.5,0.6
  c-1.5,0.1-2.7,1.1-3.2,2.5c-0.8,2.3-1.5,5.5-0.2,12.4l0.1,0.5c0,0-16.3-11.3-32.6,0.5c-8.4,6.1-2.8,21.7,10.3,19.2
  c13-2.5,21.4,0.3,22.2,2.1c-0.6,1.9-5.2,15.1,1.3,17.3c-1.5,0.5-2.8,4.5-2.3,7.3c0.3,1.5,1.5,2.5,3,2.5
  C292.3,454.1,321.5,454.1,321.5,454.1z`,
  },
  {
    part: SpineParts.T1,
    d: `M397.2,470.8c-6.2-4.6-17.3,0.3-23.4,0.4
  c-1.8,0.1-3.7-0.2-5.4-0.9c0.1-0.4,0.1-0.5,0.1-0.5s2.1-7.1,1-11.9c-0.5-2.1-2.4-3.6-4.6-3.6c-13.4,0.1-72-0.1-82.9-0.1
  c-1.5,0-2.8,0.9-3.4,2.2c-0.9,2.1-1.5,6-0.2,13c0.1,0.6,0.2,1.3,0.1,1.9c-0.9,0.5-1.6,0.5-4,0c-4.4-0.9-22.6-6.2-25.6,2.8
  s-1.4,19.1,3.2,18.6c4.3-0.4,18.3-2.9,25.4,0.3l0.3,1c-2.7,5-2.5,12.9,2.2,14.5l1,0.2c0,0-4.2,1.8-3.4,5.7
  c0.3,1.3,0.7,3.6,4.6,3.9l39.5-0.1h44.2c2-0.5,3.4-2.3,3.3-4.4c0-2.1-1.2-4.1-3.1-5c2.6,0.1,4.9-1.9,5-4.6
  c0.4-4.7-0.6-6.4-1.7-10c-0.1-0.2-0.1-0.7-0.2-0.9c4-0.7,13-2.2,16.6-1.6c4.6,0.8,9.9,1.8,12.5,0.9S403.4,475.4,397.2,470.8z`,
  },
  {
    part: SpineParts.T2,
    d: `M398.5,535c-6.3-4.7-17.6,0.3-23.8,0.4
  c-1.9,0.1-3.8-0.2-5.5-0.9l0.1-0.6c0,0,2.2-7.2,1-12.1c-0.5-2.2-2.4-3.7-4.7-3.7c-13.6,0.1-73.3-0.1-84.4-0.1
  c-1.5,0-2.8,0.9-3.4,2.3c-1,2.2-1.5,6.1-0.2,13.3c0.1,0.6,0.2,1.3,0.1,1.9c-1,0.5-1.7,0.5-4,0c-4.5-0.9-23-6.3-26,2.8
  s-1.4,19.5,3.3,19c4.4-0.4,18.7-3,25.9,0.3l0.3,1c-2.8,5-2.6,13.1,2.2,14.7l1,0.2c0,0-4.3,1.8-3.4,5.8c0.3,1.4,0.7,3.6,4.7,4
  l40.3-0.1h45c2-0.5,3.4-2.4,3.4-4.5c0-2.2-1.2-4.2-3.2-5.2c2.7,0.1,4.9-2,5.1-4.7c0.4-4.8-0.6-6.5-1.7-10.2
  c-0.1-0.2-0.1-0.7-0.2-0.9c4-0.7,13.2-2.2,16.9-1.6c4.7,0.8,10,1.8,12.8,0.9S404.8,539.7,398.5,535z`,
  },
  {
    part: SpineParts.T3,
    d: `M399.5,600.3c-6.4-4.7-17.8,0.3-24.1,0.4
  c-1.9,0.2-3.8-0.2-5.5-0.9l0.1-0.6c0,0,2.2-7.3,1-12.3c-0.5-2.2-2.5-3.7-4.7-3.7c-13.8,0.1-74.2-0.1-85.4-0.1
  c-1.5,0-2.9,0.9-3.5,2.3c-1,2.2-1.5,6.2-0.2,13.4c0.2,0.6,0.2,1.3,0.1,2c-1,0.5-1.7,0.5-4.1,0c-4.6-0.9-23.3-6.4-26.4,2.9
  s-1.4,19.7,3.3,19.2c4.4-0.5,18.9-3,26.2,0.3l0.3,1c-2.8,5.1-2.6,13.2,2.2,14.9l1,0.2c0,0-4.3,1.8-3.5,5.9
  c0.3,1.4,0.7,3.7,4.8,4l40.8-0.1h45.6c2.1-0.5,3.5-2.4,3.4-4.5c0-2.2-1.2-4.2-3.2-5.2c2.7,0.1,5-2,5.1-4.7
  c0.4-4.8-0.6-6.6-1.7-10.4c-0.1-0.2-0.1-0.7-0.2-0.9c4.1-0.7,13.4-2.3,17.1-1.6c4.7,0.8,10.2,1.8,13,1S405.8,605.1,399.5,600.3
  z`,
  },
  {
    part: SpineParts.T4,
    d: `M401.3,666.3c-6.6-4.9-18.5,0.3-25,0.5
  c-2,0.1-3.9-0.2-5.8-0.9l0.1-0.6c0,0,2.3-7.6,1-12.8c-0.5-2.3-2.6-3.9-4.9-3.9c-14.3,0.1-77-0.1-88.7-0.1c-1.6,0-3,0.9-3.6,2.4
  c-1,2.3-1.6,6.4-0.2,13.9c0.1,0.7,0.2,1.3,0.1,2c-1,0.5-1.7,0.5-4.2,0c-4.8-0.9-24.2-6.6-27.3,3s-1.4,20.5,3.5,20
  c4.6-0.5,19.6-3.1,27.2,0.3l0.3,1c-2.9,5.3-2.7,13.8,2.3,15.5l1.1,0.2c0,0-4.5,1.9-3.6,6.1c0.3,1.4,0.8,3.8,5,4.2l42.3-0.1
  h47.3c2.1-0.6,3.6-2.5,3.5-4.7c0-2.3-1.3-4.4-3.3-5.4c0,0,4.9,0.1,5.3-4.9s-0.6-6.8-1.8-10.8c-0.1-0.2-0.1-0.8-0.2-1
  c4.3-0.8,13.9-2.4,17.7-1.7c4.9,0.8,10.6,1.9,13.4,1S407.9,671.2,401.3,666.3z`,
  },
  {
    part: SpineParts.T5,
    d: `M403.1,734.6c-6.8-4.9-19,0.3-25.6,0.5
  c-2,0.2-4-0.2-5.9-0.9c0.1-0.4,0.1-0.6,0.1-0.6s2.3-7.6,1.1-12.8c-0.6-2.3-2.6-3.9-5-3.9c-14.6,0.1-78.9-0.1-90.8-0.1
  c-1.6,0-3,0.9-3.7,2.4c-1,2.3-1.6,6.4-0.2,14c0.2,0.7,0.2,1.3,0.1,2c-1,0.5-1.8,0.5-4.3,0c-4.9-0.9-24.8-6.7-28,3
  s-1.5,20.5,3.6,20c4.7-0.5,20.1-3.1,27.8,0.3l0.3,1c-3,5.3-2.8,13.8,2.4,15.6l1.1,0.2c0,0-4.6,1.9-3.7,6.1
  c0.3,1.4,0.8,3.8,5.1,4.2l43.3-0.1h48.5c2.2-0.6,3.7-2.5,3.6-4.7c0-2.3-1.3-4.4-3.4-5.4c0,0,5,0.1,5.5-4.9s-0.6-6.8-1.9-10.8
  c-0.1-0.2-0.1-0.8-0.2-1c4.4-0.8,14.2-2.4,18.1-1.7c5,0.8,10.8,1.9,13.8,1S409.9,739.6,403.1,734.6z`,
  },
  {
    part: SpineParts.T6,
    d: `M405.9,803.7c-7-5-19.6,0.3-26.5,0.5
  c-2.1,0.2-4.2-0.2-6.1-0.9l0.1-0.6c0,0,2.4-7.8,1.1-13c-0.6-2.4-2.7-4-5.2-4c-15.1,0.1-81.6-0.1-93.9-0.1
  c-1.6,0-3.1,0.9-3.8,2.4c-1,2.3-1.7,6.5-0.2,14.2c0.2,0.7,0.2,1.4,0.1,2.1c-1,0.5-1.9,0.5-4.5,0c-5-1-25.6-6.8-29,3
  s-1.5,20.9,3.7,20.4c4.9-0.5,20.8-3.2,28.8,0.3l0.3,1c-3.1,5.4-2.9,14.1,2.5,15.8l1.1,0.2c0,0-4.8,1.9-3.8,6.2
  c0.3,1.5,0.8,3.9,5.2,4.3l44.8-0.1h50.1c2.2-0.6,3.7-2.5,3.8-4.8c0-2.4-1.4-4.5-3.5-5.5c0,0,5.2,0.1,5.6-5s-0.6-7-1.9-11
  c-0.1-0.2-0.1-0.8-0.2-1c4.5-0.8,14.7-2.4,18.8-1.7c5.2,0.8,11.2,1.9,14.2,1S412.9,808.8,405.9,803.7z`,
  },
  {
    part: SpineParts.T7,
    d: `M410.4,874.5c-7.4-5.3-20.7,0.3-27.9,0.5
  c-2.2,0.2-4.4-0.2-6.4-1c0.1-0.4,0.1-0.6,0.1-0.6s2.5-8.2,1.1-13.8c-0.6-2.5-2.9-4.2-5.5-4.2c-16,0.1-86.1-0.1-99.1-0.1
  c-1.7,0-3.3,1-4,2.6c-1.1,2.5-1.8,6.9-0.2,15c0.2,0.7,0.2,1.5,0.1,2.2c-1.1,0.5-2,0.6-4.7,0c-5.3-1-27-7.1-30.5,3.2
  s-1.6,22,3.9,21.5c5.1-0.5,21.9-3.4,30.4,0.3l0.3,1.1c-3.2,5.7-3,14.8,2.6,16.7l1.2,0.3c0,0-5,2-4,6.6c0.3,1.5,0.9,4.1,5.5,4.5
  l47.3-0.1h52.9c2.3-0.6,4-2.7,4-5.1c0-2.5-1.5-4.8-3.7-5.8c0,0,5.5,0.1,6-5.3s-0.7-7.3-2-11.6c-0.1-0.2-0.1-0.8-0.2-1
  c4.8-0.8,15.5-2.5,19.8-1.8c5.5,0.9,11.8,2,15,1.1S417.8,879.8,410.4,874.5z`,
  },
  {
    part: SpineParts.T8,
    d: `M426.6,964.7c-1.1-13.3-5.8-15.1-16.5-15.8
  s-10.6,2.6-20.5,2.8c-6.8,0.2-9.7-1-11.1-1.9c1.4-7,2.2-14.5,1.1-17c-2.1-4.6-2.7-4.1-9.1-3.9s-89.6-0.1-89.6-0.1h-8.6
  c-2.4,0-4.6,1.5-5.4,3.8c-0.9,2.9-1,6-0.4,9c0.5,2.8,0.5,5.9,1.1,8.7c-3.7,1.6-7.8,2-11.8,1.2c-8.6-1.5-28.9-7.1-32.8,5.3
  c-3.7,11.7-1.8,24,6,22.8s24.2-5,36.1,0.7c-1.4,5.7-1.5,10.5,2.3,14.3l0.5,0.4c0,0-3,1.3-2.4,6.1s4.3,5,4.3,5l106.7,0.3
  c0,0,4.5-1.6,4.3-5.7s-1.9-5.5-1.9-5.5s6.2-5.2,2.5-14.4c12.4-5.2,32.1-1.2,37.1-1C423.6,980.3,427.7,978,426.6,964.7z`,
  },
  {
    part: SpineParts.T9,
    d: `M425.6,1041.3c-1.1-13.3-5.8-15.1-16.5-15.7
  s-10.6,2.6-20.5,2.8c-6.8,0.2-9.7-1-11.1-1.9c1.4-7,2.2-14.5,1.1-16.9c-2.1-4.6-2.7-4.1-9.1-3.9s-89.6-0.1-89.6-0.1h-8.6
  c-2.4,0-4.6,1.5-5.4,3.8c-0.9,2.9-1,6-0.4,9c0.5,2.8,0.5,5.9,1.1,8.7c-3.7,1.6-7.8,2-11.8,1.2c-8.6-1.6-28.9-7.1-32.8,5.3
  c-3.7,11.7-1.8,24,6,22.8s24.2-5,36.1,0.7c-1.4,5.7-1.5,10.5,2.3,14.3l0.5,0.4c0,0-3,1.3-2.4,6.1s4.3,5,4.3,5l106.7,0.3
  c0,0,4.5-1.6,4.3-5.7s-1.9-5.4-1.9-5.4s6.2-5.2,2.5-14.4c12.4-5.2,32.1-1.2,37.1-1C422.6,1057,426.7,1054.7,425.6,1041.3z`,
  },
  {
    part: SpineParts.T10,
    d: `M426.8,1119.9c-1.1-13.7-5.9-15.6-16.8-16.2
  s-10.8,2.6-20.9,2.9c-7,0.2-9.9-1-11.4-1.9c1.4-7.2,2.3-14.9,1.2-17.4c-2.1-4.7-2.7-4.2-9.3-4s-91.5-0.1-91.5-0.1h-8.7
  c-2.5,0-4.7,1.5-5.5,3.9c-0.9,3-1.1,6.2-0.4,9.3c0.5,2.9,0.5,6.1,1.1,9c-3.8,1.6-8,2.1-12,1.3c-8.8-1.6-29.5-7.3-33.5,5.5
  c-3.8,12-1.8,24.7,6.1,23.4s24.7-5.1,36.8,0.7c-1.5,5.9-1.6,10.8,2.3,14.7l0.5,0.4c0,0-3.1,1.3-2.4,6.2s4.4,5.2,4.4,5.2
  l109,0.3c0,0,4.6-1.6,4.4-5.8s-2-5.6-2-5.6s6.3-5.4,2.5-14.8c12.6-5.3,32.8-1.2,37.9-1.1
  C423.8,1135.9,427.9,1133.6,426.8,1119.9z`,
  },
  {
    part: SpineParts.T11,
    d: `M428.6,1199.6c-1.1-13.8-6-15.7-17.1-16.3
  s-11,2.7-21.2,2.9c-7.1,0.2-10.1-1-11.5-1.9c1.5-7.2,2.3-15.1,1.2-17.6c-2.2-4.8-2.8-4.2-9.5-4.1s-93-0.1-93-0.1h-8.9
  c-2.5,0-4.7,1.5-5.6,3.9c-0.9,3-1.1,6.3-0.4,9.4c0.5,2.9,0.5,6.1,1.1,9.1c-3.9,1.6-8.1,2.1-12.2,1.3c-9-1.6-29.9-7.4-34,5.5
  c-3.9,12.2-1.8,24.9,6.2,23.7s25.1-5.2,37.4,0.7c-1.5,5.9-1.6,10.9,2.4,14.9l0.5,0.4c0,0-3.1,1.3-2.5,6.3s4.5,5.2,4.5,5.2
  l110.6,0.3c0,0,4.6-1.7,4.5-5.9s-2-5.7-2-5.7s6.4-5.4,2.6-14.9c12.8-5.4,33.3-1.2,38.5-1.1
  C425.5,1215.8,429.7,1213.3,428.6,1199.6z`,
  },
  {
    part: SpineParts.T12,
    d: `M431.1,1280.6c-1.2-14.1-6.1-16.1-17.5-16.7
  s-11.2,2.7-21.8,3c-7.2,0.2-10.3-1-11.8-2c1.5-7.4,2.4-15.4,1.2-18c-2.2-4.9-2.8-4.3-9.7-4.2s-95.2-0.1-95.2-0.1h-9.1
  c-2.6,0-4.9,1.6-5.7,4c-1,3.1-1.1,6.4-0.4,9.6c0.5,3,0.6,6.3,1.2,9.3c-3.9,1.7-8.3,2.1-12.5,1.3c-9.2-1.7-30.7-7.5-34.8,5.7
  c-3.9,12.4-1.9,25.5,6.3,24.2s25.7-5.3,38.3,0.7c-1.5,6.1-1.6,11.1,2.4,15.2l0.6,0.4c0,0-3.2,1.3-2.5,6.5s4.6,5.4,4.6,5.4
  l113.3,0.3c0,0,4.8-1.7,4.6-6s-2.1-5.8-2.1-5.8s6.6-5.6,2.6-15.3c13.1-5.5,34.1-1.3,39.4-1.1
  C428,1297.1,432.3,1294.7,431.1,1280.6z`,
  },
  {
    part: SpineParts.L1,
    d: `M381.3,1327.1c0,0,10.2,5,19.8,3s20.7-10,30.8-5.3
  s6.9,25.7,6.1,28.1s0.1,8.4-12.5,7.6c-11-0.9-22,0.9-32.1,5.3l-7.2,3.4c0,0,1.2,5.8-1.1,8.7c0,0,5.4,8.1-4.3,12.6
  c-4.2,2-34.2,4.3-59.2,4.3s-42.6-1.4-52.7-2.8s-15.3-8.4-11.5-14l0.3-0.4c-1.8-3-2.1-6.6-1-9.9c0,0-15.5-8.6-33.8-7.1
  c-13.3,1.1-16.1-2.6-15.7-18.7s6.9-18.5,13.7-18.5s20.4,6.4,25.9,6.8s13.6-1.9,13.6-1.9c-0.5-2.1-0.2-4.3,0.9-6.2
  c0,0,1,2.6,5.5,2.8c7,0.4,19.7-0.6,55.8-0.7c23.7-0.1,37.4,1.3,45.9,0.8c12.9-0.8,13.1-2.1,13.1-2.1S381.5,1324.5,381.3,1327.1
  z`,
  },
  {
    part: SpineParts.L2,
    d: `M381.3,1397.3c0,0,10.2,5,19.8,3s20.7-10,30.8-5.3
  s6.9,25.7,6.1,28.1s0.1,8.4-12.5,7.6c-11-0.9-22,0.9-32.1,5.3l-7.2,3.4c0,0,1.2,5.8-1.1,8.7c0,0,5.4,8.1-4.3,12.6
  c-4.2,2-34.2,4.3-59.2,4.3s-42.6-1.4-52.7-2.8s-15.3-8.4-11.5-13.9l0.3-0.4c-1.8-3-2.1-6.6-1-9.9c0,0-15.5-8.6-33.8-7.1
  c-13.3,1.1-16.1-2.6-15.7-18.7s6.9-18.5,13.7-18.5s20.4,6.4,25.9,6.8s13.6-1.9,13.6-1.9s-1-7.7,2.6-9.4c0,0,22.9,6,60.1,5.5
  s56.3-5.5,56.3-5.5S383.1,1389.8,381.3,1397.3z`,
  },
  {
    part: SpineParts.L3,
    d: `M383.7,1467.9c0,0,10.6,5.1,20.6,3.1
  s21.6-10.2,32-5.4s7.1,26.2,6.3,28.6s0.1,8.6-13,7.7c-11.4-0.9-22.9,0.9-33.4,5.4l-7.5,3.5c0,0,1.3,5.9-1.1,8.8
  c0,0,5.6,8.3-4.5,12.8c-4.4,2-35.6,4.4-61.7,4.4s-44.3-1.4-54.9-2.9s-15.9-8.5-12-14.2l0.3-0.4c-1.9-3-2.2-6.8-1-10.1
  c0,0-16.1-8.7-35.2-7.2c-13.8,1.1-16.8-2.7-16.4-19.1s7.1-18.9,14.3-18.9s21.2,6.5,27,7s14.2-1.9,14.2-1.9s-1-7.9,2.7-9.6
  c0,0,23.8,6.1,62.5,5.6s58.5-5.6,58.5-5.6S385.6,1460.2,383.7,1467.9z`,
  },
  {
    part: SpineParts.L4,
    d: `M383.7,1540.2c0,0,10.6,5.1,20.6,3.1
  s21.6-10.2,32-5.4s7.1,26.2,6.3,28.6s0.1,8.6-13,7.7c-11.4-0.9-22.9,0.9-33.4,5.4l-7.5,3.5c0,0,1.3,5.9-1.1,8.8
  c0,0,5.6,8.3-4.5,12.8c-4.4,2-35.6,4.4-61.7,4.4s-44.3-1.4-54.9-2.9s-15.9-8.6-12-14.2l0.3-0.4c-1.9-3-2.2-6.8-1-10.1
  c0,0-16.1-8.7-35.2-7.2c-13.8,1.1-16.8-2.7-16.4-19.1s7.1-18.9,14.3-18.9s21.2,6.5,27,7s14.2-1.9,14.2-1.9s-1-7.9,2.7-9.6
  c0,0,23.8,6.1,62.5,5.6s58.5-5.6,58.5-5.6S385.6,1532.5,383.7,1540.2z`,
  },
  {
    part: SpineParts.L5,
    d: `M387.3,1611.5c0,0,11,5.2,21.5,3.1
    s22.5-10.4,33.4-5.5s7.5,26.7,6.6,29.2s0.1,8.7-13.6,7.9c-11.9-0.9-23.8,0.9-34.9,5.5l-7.8,3.6c0,0,1.3,6-1.2,9
    c0,0,5.8,8.4-4.6,13.1c-4.6,2-37.1,4.5-64.3,4.5s-46.2-1.5-57.2-3c-11-1.5-16.6-8.7-12.5-14.5l0.3-0.4c-1.9-3.1-2.3-6.9-1-10.3
    c0,0-16.8-8.9-36.7-7.3c-14.4,1.1-17.5-2.7-17.1-19.5s7.4-19.2,14.9-19.2s22.1,6.7,28.1,7.1s14.8-2,14.8-2s-1.1-8.1,2.8-9.8
    c0,0,24.8,6.2,65.2,5.7s61.1-5.7,61.1-5.7S389.2,1603.6,387.3,1611.5z`,
  },
  {
    part: SpineParts.S,
    d: `M446.5,1754.5c2.3-10.4,5.3-43.2-4.4-59.7s3.5-48.7,3.5-48.7s-32.1,2.5-53.5,19.4c0,0,4,8.7-8.1,11.8 s-59.2,3.8-59.2,3.8s-43-0.3-58.3-3s-12.9-9.9-12.9-9.9s-5,0.1-16.1-8.8s-23.5-13.4-37.7-11.7c0,0,14.7,21.1,1.4,54.1 c-10.2,25.1-2.7,52.5-1.4,58.6s-3.9,7-3.9,7s0.9,4.6,4.5,6.7s10.1,8.7,12.2,11.6s10.4,10.6,14.3,13.2c5.8,3.6,11,8,15.5,13.1 c7.6,8.2,17.6,19.3,20.4,22c6.6,6.3,9.9,8.7,23.5,6.9c7.1-1,20,5.7,35,5.7c4.8,0.1,9.5-0.5,14.1-2c8.3-2.5,18.9-3.2,22.9-2.8 s13.7-1.2,24.7-14.5s16.7-20.7,27.8-27.9s14.5-13.8,22.2-20.7s13.8-12.4,13.8-12.4S444.1,1764.9,446.5,1754.5z M240.7,1716.7 c0.6-11.1,9.5-16,17.4-16c7.6,0.2,13.7,6.4,13.8,14c0,7.4-6.9,13.9-14.3,15.2C246.4,1731.9,240.2,1724.1,240.7,1716.7 L240.7,1716.7z M259.1,1742.8c6.1-1.4,10.2-0.3,13.4,2c0,0,8.5,6.9,3.8,14.6s-20.8,1-22.8-7.3 C252.4,1748,252.9,1744.2,259.1,1742.8L259.1,1742.8z M259.1,1781.5c6.4-6.2,14.9-7.6,21.7,1.2c0,0,9.6,13.1-7,19.2 C259.1,1807.3,252.2,1788.1,259.1,1781.5L259.1,1781.5z M289.3,1825.2c-4.5,7.1-20.4-3.6-16.8-9.8s10.6-3.2,13.1-2 C285.6,1813.3,293.8,1818,289.3,1825.2L289.3,1825.2z M349.3,1825.2c-4.5-7.1,3.7-11.8,3.7-11.8c2.4-1.2,9.4-4.1,13.1,2 S353.8,1832.3,349.3,1825.2L349.3,1825.2z M364.8,1801.9c-16.6-6.1-7-19.2-7-19.2c6.9-8.7,15.3-7.3,21.7-1.2 C386.3,1788.1,379.5,1807.3,364.8,1801.9L364.8,1801.9z M385.1,1752.1c-2,8.3-18,15-22.8,7.3s3.8-14.6,3.8-14.6 c3.3-2.3,7.3-3.4,13.4-2S386.1,1748,385.1,1752.1L385.1,1752.1z M381.1,1729.9c-7.5-1.3-14.3-7.8-14.3-15.2 c0.1-7.6,6.2-13.8,13.8-14c7.9,0,16.8,4.9,17.4,16c0.3,7.4-5.9,15.2-17,13.2L381.1,1729.9z`,
  },
  {
    part: SpineParts.C,
    d: `M362.5,1858.1c-3.2-3.7-14,1.1-14,1.1s7-9.4,8.1-18.1
  c-6.5,0.2-12.8,1.2-19,3.2c-6.7,2.1-10.7,2.2-12.6,2c-1.2-0.1-2.3-0.1-3.5-0.1c-4.3,0.2-8.5-0.3-12.6-1.5
  c-7.8-2.3-13.6-4.2-19.6-4c1.3,6.6,4.4,12.7,8.8,17.7v0.7c0,0-16.3-7.5-17.2,3.7c-0.4,4.8,3.4,6.5,5.3,8.5s6,6.1,8.6,7.4
  c4.9,2.5,9.7,5.3,14.3,8.4c0,0-8.3,0.5-4.9,6c1.8,3,4.2,4.4,8.9,5.4c-2.7,0.4-0.8,9.2,10.4,9.5c0,0,5.1-0.4,8.2-3.6
  s1.7-6.1,1.7-6.1s8.6-1.8,8-8c-0.1-2.6-2.1-4.7-4.6-4.9c0,0,0.2-0.5,4.8-3.3c5-3,9.7-6.4,14.2-10.1c4.6-3.8,6.4-3.8,7.4-5.1
  C365,1864.2,364.7,1860.5,362.5,1858.1z`,
  },
  {
    part: SpineParts.PL,
    d: `M28.6,1621.1c65.4-61.9,170.4-16,177.6,43.7
  c3.4,28.9-5.1,28.4-7.1,52.3c0,0-1.7,24.1,1.8,37.2c1.8,8.5,1.1,11.8-7.2,13.4s-13.1,6.1-7.1,29.8s29.1,53.3,70.8,60.5
  c20.4,3.3,24.8,1.1,24.8,1.1s-3,6.2,2.6,10c0,0,4.2,4.2,6.3,6.1s7.7,4.6,11,6.9s5.8,3.4,6.8,4.7c-6.4,0-8.7,8,3.2,11.3
  c-0.3,0.8-2.7,8.6,9.9,11c0,0,2.4,16.6-19,36.7s-57.3,38.6-84.2,27.3s-40.4-31.1-40.5-41s4.4-15.9,5.6-21.3s1.9-8.2-4.3-16
  s-26.6-42.8-47.9-53.3s-20.3-27.6-37.6-42.2s-59.5-49-69.1-58S-27.1,1673.8,28.6,1621.1z M225.8,1949.3
  c20.3,1.9,49.6-1.6,54.9-24.4s-31.8-39.3-31.8-39.3c-5.6-2.9-8.7-4-16.7-3.3s-10,2.7-8.1,10.8s0.7,16.4-6.8,20.1
  s-11.4,12.8-12.6,22.3S205.6,1947.5,225.8,1949.3z`,
  },
  {
    part: SpineParts.PR,
    d: `M616.7,1621.1c-65.4-61.9-170.4-16-177.5,43.7
  c-3.5,28.9,5.1,28.4,7.1,52.3c0,0,1.7,24.1-1.8,37.2c-1.8,8.5-1.1,11.8,7.2,13.4s13.1,6.1,7.1,29.8s-29.1,53.3-70.8,60.5
  c-20.4,3.3-24.8,1.1-24.8,1.1s3,6.8-3.2,9.3c-2.2,1.4-4.3,3-6.4,4.7c-3.4,2.7-6.9,5.3-10.6,7.7c-3.2,2.2-5.3,3.1-6.3,4.4
  c5.1,0.8,8.5,9.6-3.4,12.8c0.3,0.8,2.7,8.6-9.9,11c0,0-2.4,16.6,19,36.7s57.3,38.6,84.2,27.3s40.4-31.1,40.5-41
  s-4.4-15.9-5.6-21.3s-1.9-8.2,4.3-16s26.6-42.8,47.9-53.3s20.3-27.6,37.6-42.2s59.4-48.9,69.1-57.9S672.3,1673.8,616.7,1621.1z
   M419.5,1949.3c-20.3,1.9-49.6-1.6-54.9-24.4s31.8-39.3,31.8-39.3c5.6-2.9,8.7-4,16.7-3.3s10,2.7,8.1,10.8s-0.7,16.4,6.8,20.1
  s11.4,12.8,12.6,22.3S439.7,1947.5,419.5,1949.3z`,
  },
];

export enum SpineSide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTH = 'BOTH',
  NONE = 'NONE',
}

export type SpinePartsSelected = {
  [key in Exclude<keyof typeof SpineParts, 'LLI'>]: SpineSide;
} & {
  LLI: { side: SpineSide; value: number };
};

export const defaultSpineParts: Partial<SpinePartsSelected> = {
  [SpineParts.C1]: SpineSide.NONE,
  [SpineParts.C2]: SpineSide.NONE,
  [SpineParts.C3]: SpineSide.NONE,
  [SpineParts.C4]: SpineSide.NONE,
  [SpineParts.C5]: SpineSide.NONE,
  [SpineParts.C6]: SpineSide.NONE,
  [SpineParts.C7]: SpineSide.NONE,
  [SpineParts.T1]: SpineSide.NONE,
  [SpineParts.T2]: SpineSide.NONE,
  [SpineParts.T3]: SpineSide.NONE,
  [SpineParts.T4]: SpineSide.NONE,
  [SpineParts.T5]: SpineSide.NONE,
  [SpineParts.T6]: SpineSide.NONE,
  [SpineParts.T7]: SpineSide.NONE,
  [SpineParts.T8]: SpineSide.NONE,
  [SpineParts.T9]: SpineSide.NONE,
  [SpineParts.T10]: SpineSide.NONE,
  [SpineParts.T11]: SpineSide.NONE,
  [SpineParts.T12]: SpineSide.NONE,
  [SpineParts.L1]: SpineSide.NONE,
  [SpineParts.L2]: SpineSide.NONE,
  [SpineParts.L3]: SpineSide.NONE,
  [SpineParts.L4]: SpineSide.NONE,
  [SpineParts.L5]: SpineSide.NONE,
  [SpineParts.S]: SpineSide.NONE,
  [SpineParts.C]: SpineSide.NONE,
  [SpineParts.PL]: SpineSide.NONE,
  [SpineParts.PR]: SpineSide.NONE,
};
export type JointsSelected = {
  [key: string]: { left?: boolean; right?: boolean };
};
