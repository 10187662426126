import { Checkbox, LoadingPage, NotFound } from '@chiroup/components';
import { useWindowDimensions } from '@chiroup/hooks';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';
import requestService from '../../../services/request.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../../contexts/me.context';
import { Consent } from '@chiroup/core/types/Consent.type';
import { RequestStep } from '@chiroup/core/types/Request.type';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
  requestId: string;
  kiosk: boolean;
  patientConsentId?: string;
  consent?: Consent;
  complete: () => Promise<void>;
  remainingSteps?: number[];
  steps: RequestStep[];
  guardian?: boolean;
  patientName?: string;
  guardianName?: string;
  validationCode: string | number;
  prMap: { [key: string]: string };
};

const ConsentComponent: React.FC<Props> = ({
  requestId,
  patientConsentId,
  consent,
  complete,
  remainingSteps,
  steps,
  guardian,
  patientName,
  guardianName,
  kiosk,
  validationCode,
  prMap,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const { height, width } = useWindowDimensions();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [signed, setSigned] = useState(false);
  const [signing, setSigning] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const sigPad = useRef<SignatureCanvas>(null);

  useEffect(() => {
    if (!kiosk || (kiosk && !guardian)) {
      setAcknowledge(true);
    }
  }, [kiosk, guardian]);

  const clear = () => {
    if (!sigPad) {
      return;
    }
    sigPad.current?.clear();
    setSigned(false);
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const showPrevious = useMemo(() => {
    return pageNumber > 1;
  }, [pageNumber]);

  const documentWidth = useMemo(() => {
    const widthBasedOnScreen = width - 30;
    const heightBasedOnScreen = height - 200;
    const heightBasedOnWidth = widthBasedOnScreen * 1.29;
    if (heightBasedOnWidth > height) {
      return heightBasedOnScreen * 0.774;
    }
    return width - 30;
  }, [width, height]);

  const percentDone = useMemo(() => {
    return Math.ceil((pageNumber / (numPages + 1)) * 100);
  }, [pageNumber, numPages]);

  const submit = async (preview?: boolean) => {
    const basePath = pathname.split('/').slice(0, -1).join('/');
    if (disabled || !consent?.url || !signed || !patientConsentId) {
      return;
    }

    try {
      setSigning(true);
      setDisabled(true);
      const res = await requestService.submitConsent(
        requestId,
        patientConsentId,
        consent.url,
        sigPad.current?.getTrimmedCanvas()?.toDataURL('image/png'),
        validationCode,
        remainingSteps,
        prMap,
        preview,
      );
      setDisabled(false);
      setSigning(false);
      if (res?.url) {
        window.open(res.url, '_blank');
      } else {
        await complete();
        clear();
        setPageNumber(1);
        if (
          remainingSteps &&
          getCompleteWithoutDisabled() > 0 &&
          remainingSteps[0] + 1 > getCompleteWithoutDisabled()
        ) {
          navigate(`${basePath}/${remainingSteps[0] + 1}`, { replace: true });
        } else {
          navigate(basePath, { replace: true });
        }
      }
    } catch (err: any) {
      console.error(err);
      setDisabled(false);
      setSigning(false);
      clear();
      setPageNumber(1);
      if (err.message === 'Consent has already been completed.') {
        await complete();
        navigate(basePath, { replace: true });
      }
    }
  };
  const getCompleteWithoutDisabled = () => {
    return (
      steps.filter(({ complete }) => complete).length -
      steps.filter(({ disabled }) => disabled).length
    );
  };

  const handleAcknowledge = (e: boolean) => {
    setAcknowledge(e);
  };

  return (
    <div className="mx-auto items-center justify-center relative h-screen mb-16">
      {signing && (
        <div
          className="absolute top-1/2 left-1/2 transform"
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          <LoadingPage />
        </div>
      )}
      <div
        className={[
          'flex flex-col gap-2 mx-auto h-screen justify-center',
          signing ? 'opacity-10' : '',
        ].join(' ')}
      >
        {consent?.url && numPages > 0 && pageNumber > numPages ? (
          <div className="flex items-center justify-center w-full">
            <div className="max-w-5xl mx-auto">
              <div className="text-center">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Please
                  <br />
                  <span
                    style={{
                      color: primaryColor,
                    }}
                  >
                    sign below
                  </span>
                </h2>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-md mb-6">
                  I hereby acknowledge that I have received, reviewed and agree
                  to this form.
                </p>
                <div className="h-6">
                  {signed && (
                    <div className="flex flex-row justify-between">
                      <div
                        className="text-sm font-medium text-gray-600 hover:text-gray-500 cursor-pointer text-center"
                        onClick={clear}
                      >
                        Clear signature
                      </div>
                      {!kiosk && (
                        <div
                          className="text-sm font-medium hover:opacity-90 cursor-pointer text-center"
                          style={{ color: primaryColor }}
                          onClick={submit.bind(null, true)}
                        >
                          Download copy
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="border-4 border-gray-300 max-w-full h-auto bg-white mt-2"
                  style={{
                    width: documentWidth < 500 ? documentWidth : 500,
                    height: 200,
                  }}
                >
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-white">
                      <SignatureCanvas
                        canvasProps={{ className: 'w-full h-full' }}
                        ref={sigPad}
                        onEnd={() => {
                          setSigned(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : consent?.url ? (
          <Document file={consent.url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              width={documentWidth}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        ) : (
          <NotFound />
        )}
        {pageNumber <= numPages ? (
          <div className="flex flex-row justify-between">
            <div>
              {showPrevious && (
                <div
                  className="text-center flex items-center cursor-pointer gap-1"
                  onClick={() => {
                    if (!disabled) {
                      setPageNumber((prev) => --prev);
                    }
                  }}
                >
                  <ChevronLeftIcon
                    className="h-5 w-5"
                    style={{
                      color: primaryColor,
                    }}
                  />
                  <div className="text-sm font-medium text-gray-500">Prev</div>
                </div>
              )}
            </div>
            <span className="text-sm text-gray-500">
              Page {pageNumber} of {numPages}
            </span>
            <div>
              <div
                className="text-center flex items-center cursor-pointer gap-1"
                onClick={() => {
                  if (!disabled) {
                    setPageNumber((prev) => ++prev);
                  }
                }}
              >
                <div className="text-sm font-medium text-gray-500">Next</div>
                <ChevronRightIcon
                  className="h-5 w-5"
                  style={{
                    color: primaryColor,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <div className="text-sm font-medium text-gray-500 text-center">
              Signature of patient or patient's representative
            </div>
            {kiosk && guardian && numPages > 0 && (
              <div className="flex flex-col items-center pb-2">
                <Checkbox
                  label={`I confirm that I am ${guardianName} the legal guardian of ${patientName}`}
                  value={acknowledge}
                  onChange={handleAcknowledge}
                />
              </div>
            )}
            <div className="flex flex-row justify-between items-center h-12">
              <div>
                {showPrevious && (
                  <div
                    className="text-center flex items-center cursor-pointer gap-1"
                    onClick={() => {
                      if (!disabled) {
                        setPageNumber((prev) => --prev);
                      }
                    }}
                  >
                    <ChevronLeftIcon
                      className="h-5 w-5"
                      style={{
                        color: primaryColor,
                      }}
                    />
                    <div className="text-sm font-medium text-gray-500">
                      Prev
                    </div>
                  </div>
                )}
              </div>
              <div>
                {signed && acknowledge && (
                  <div
                    className="text-center flex items-center cursor-pointer gap-1 rounded-md p-2"
                    style={{
                      backgroundColor: primaryColor,
                    }}
                    onClick={submit.bind(null, false)}
                  >
                    <div className="text-sm font-medium text-white">Submit</div>
                    {/* <PencilAltIcon className="h-5 w-5 text-white" /> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="fixed bottom-0 inset-x-0 px-4 w-full bg-gray-100">
          <div className="flex mb-2 items-center justify-end w-full">
            <div className="text-right">
              <span
                className="text-xs font-semibold inline-block"
                style={{
                  color: primaryColor,
                }}
              >
                {percentDone}%
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded w-full relative">
            <div
              style={{
                width: `${percentDone}%`,
                backgroundColor: primaryColor,
              }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
            />
            <div
              style={{
                backgroundColor: primaryColor,
                opacity: 0.2,
              }}
              className="absolute left-0 top-0 w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center h-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentComponent;
