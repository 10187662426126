import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { useId, useMemo, useState } from 'react';
import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
} from 'react-query';
import { SelectOption } from '@chiroup/components';
import {
  itemToDataDescription,
  itemToOptionText,
} from '@chiroup/core/constants/DatabaseFeatureCommon';

const query = ({ limit = 10 }: { limit: number }) => {
  return async (context: QueryFunctionContext) => {
    const clinicId = Number.parseInt(context.queryKey[1] as string);

    const search = context.queryKey[2];
    const searchResp = await ChiroUpAPI.get(
      'api-noauth',
      `/payors/${clinicId}`,
      {
        queryParams: {
          limit,
          search,
          page: context.pageParam,
        },
      },
    );
    return searchResp;
  };
};

type InstanceReferenceType = {
  [key: string]: any;
  ID: number;
};

const useDatabaseAutocomplete = (clinicId: number, limit = 10) => {
  const [search, setSearch] = useState('');

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery<{
    data: InstanceReferenceType[];
    count: number;
    page: number;
  }>([useId(), clinicId, search], query({ limit }), {
    getNextPageParam: (lastGroup) => {
      if (!lastGroup) return;
      return lastGroup.data?.length === 10
        ? (lastGroup.page || 0) + 1
        : undefined;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    placeholderData: {
      count: 0,
      page: 0,
      data: [] as InstanceReferenceType[],
      pageParams: [],
      pages: [],
    } as InfiniteData<any>,
  });

  const options: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const res = data.pages
      .reduce((acc: any, curr) => {
        return [...acc, ...curr.data];
      }, [] as any[])
      .map((row: any) => ({
        text: itemToOptionText('payors', row),
        value: row.ID,
        data: {
          description: itemToDataDescription('payors', row),
        },
      }));

    return res;
  }, [data]);

  const onChangeSearch = (value: string) => {
    setSearch(value);
  };

  return {
    options,
    isFetching,
    onChangeSearch,
    fetchNextPage,
    hasNextPage,
    search,
  };
};

export default useDatabaseAutocomplete;
