import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SurveyQuestionComponent } from '../survey';
import Body from './Body';
import CCSInstructions from './CCSInstructions';
import { createReevalQuestions } from './ccsQuestions';
import {
  bodyPickerSubmit,
  ccsBodyPickerQuestion,
  ccsBodyPickerQuestionSpanish,
  getNextQuestionIndex,
  submitStuff,
} from './helpers';
import {
  ReevalSurveyQuestionIds,
  CCSBodyPickerQuestionIds,
  CCSPrimaryQuestionIds,
  CCSSecondaryQuestionIds,
} from '@chiroup/core/constants/CCSQuestionIds';
import { Genders } from '@chiroup/core/enums/Genders.enum';
import { RequestStep } from '@chiroup/core/types/Request.type';
import {
  SurveyQuestion,
  SurveyQuestionAnswer,
} from '@chiroup/core/types/Survey.type';

const submitVerbiage = {
  more: {
    en: 'Hang tight, we have a few more things we need to ask...',
    es: 'Espera un momento, tenemos algunas cosas más que debemos preguntar...',
  },
  done: { en: 'Submitting survey', es: 'La encuesta esta siendo enviada' },
};

type Props = {
  complaints: any;
  primaryColor: string;
  language: 'en' | 'es' | undefined;
  complete: () => void;
  ccsFollowups?: string[];
  kiosk: boolean;
  prMap: { [key: string]: string };
  startQuestion: SurveyQuestion;
  markSurveyComplete: () => void;
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  surveyIds?: string[];
  weeks?: number;
  age: number;
  gender: Genders;
  submitSurvey: (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
  ) => Promise<any>;
  myScreen?: boolean;
  setLoadingInterimOrReevalResults?: Dispatch<SetStateAction<boolean>>;
};

export const ReevalSurvey = ({
  complaints,
  primaryColor,
  language,
  complete,
  ccsFollowups,
  markSurveyComplete,
  updateRequest,
  validationCode,
  surveyIds,
  submitSurvey,
  startQuestion,
  weeks,
  kiosk,
  age,
  gender,
  prMap,
  myScreen,
  setLoadingInterimOrReevalResults,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [spanishQuestions, setSpanishQuestions] = useState<SurveyQuestion[]>([
    startQuestion,
  ]);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitText, setSubmitText] = useState<string>(
    submitVerbiage.done[language === 'es' ? 'es' : 'en'],
  );
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [aaWI, setAaWI] = useState<string>();
  const [instructions, setInstructions] = useState<boolean>(true);
  const [surveyCreated, setSurveyCreated] = useState<boolean>(false);
  const modalHeight = useRef(0);
  const progress = useMemo(() => {
    const questionsCopy = [...questions];
    const total = questionsCopy?.length;
    const reverseIndexOfLastAnswer = questionsCopy
      .reverse()
      .findIndex((q) => !!q.answer);
    const reverseIndexOfLastAnswerOrZero =
      reverseIndexOfLastAnswer > -1
        ? reverseIndexOfLastAnswer
        : questionsCopy.length;
    const completed = questionsCopy.length - reverseIndexOfLastAnswerOrZero;
    return {
      completed: completed || 0,
      total: total || 1,
    };
  }, [questions]);

  useEffect(() => {
    const height = document.getElementById('survey-modal');
    if (height) {
      modalHeight.current = height.clientHeight;
    }
  }, [progress]);

  const disableSurvey = async () => {
    return new Promise((resolve, reject) => {
      setDisabled(true);
      setShow(false);
      // Wait 333ms for the question to disappear
      setTimeout(() => {
        resolve(true);
      }, 333);
    });
  };

  const enableSurvey = async () => {
    return new Promise((resolve, reject) => {
      // Wait 333ms for the question to have been changed
      setTimeout(() => {
        setDisabled(false);
        setShow(true);
        resolve(true);
      }, 333);
    });
  };

  useEffect(() => {
    enableSurvey();
  }, []);

  const onSelect = async (val: SurveyQuestionAnswer) => {
    await disableSurvey();
    if (
      val.value ===
        (language === 'es' ? 'Accidente automovilístico' : 'Auto accident') ||
      val.value ===
        (language === 'es'
          ? 'Lesión relacionada con el empleo'
          : 'Employment-related injury')
    ) {
      setAaWI(val.value);
    }

    const currentQuestionIndex = questions.findIndex(
      (q: { id: string }) => q.id === currentQuestion.id,
    );

    const newQuestions = [...questions];
    const newQuestionsSpanish = spanishQuestions ? [...spanishQuestions] : [];

    if (
      currentQuestion.id ===
        ReevalSurveyQuestionIds.reevalNewProblemsQuestionId &&
      val.value === 'Yes'
    ) {
      newQuestions.push(ccsBodyPickerQuestion);
      if (language === 'es') {
        newQuestionsSpanish.push(ccsBodyPickerQuestionSpanish);
      }
    }

    newQuestions[currentQuestionIndex].answer = val;
    if (language === 'es') {
      newQuestionsSpanish[currentQuestionIndex].answer = val;
    }

    const nextQuestionIndex = getNextQuestionIndex({ questions: newQuestions });
    const nextQuestion = ((language === 'es'
      ? newQuestionsSpanish
      : newQuestions) || [])?.[nextQuestionIndex];
    if (!nextQuestion) {
      setDisabled(false);
      setLoadingInterimOrReevalResults?.(true);
      await submitStuff({
        questions: questions,
        kiosk: kiosk,
        spanishQuestions,
        setIsSubmitting,
        isSubmitting,
        submitSurvey,
        complete,
        complaints,
        markSurveyComplete,
        setSubmitText,
        submitVerbiage,
        validationCode,
        language,
        surveyIds,
        ccsFollowups,
        updateRequest,
      });
      setLoadingInterimOrReevalResults?.(false);
      return;
    }
    setQuestions(newQuestions);
    if (setSpanishQuestions && language === 'es') {
      setSpanishQuestions(newQuestionsSpanish);
    }

    if (
      typeof nextQuestion?.typeOptions?.notLessThan === 'string' ||
      typeof nextQuestion?.typeOptions?.notLessThan === 'number'
    ) {
      const val = getValueOfQuestion(currentQuestion.id);
      nextQuestion.typeOptions.notLessThan = val;
    }
    if (
      typeof nextQuestion?.typeOptions?.notMoreThan === 'string' ||
      typeof nextQuestion?.typeOptions?.notMoreThan === 'number'
    ) {
      const val = getValueOfQuestion(currentQuestion.id);
      nextQuestion.typeOptions.notMoreThan = val;
    }

    setCurrentQuestion(nextQuestion);
    await enableSurvey();
    return;
  };

  const getValueOfQuestion = (questionId: string) => {
    const question = questions.find(
      (question: { id: string }) => question.id === questionId,
    );
    if (question) {
      return question.answer?.value;
    }
  };

  useEffect(() => {
    setCurrentQuestion(startQuestion);
    setQuestions([startQuestion]);
  }, [startQuestion]);

  useEffect(() => {
    if (!surveyCreated) {
      if (complaints?.[0]?.bodypicker?.parts) {
        complaints.forEach((complaint: any, index: number) => {
          const parts = complaint?.bodypicker?.parts;
          const problemArea = index + 1;
          const complaintId = complaint?.id;
          const currentQuestionId = currentQuestion?.id;
          const lastQuestionOrder = questions?.length - 1;
          const englishQuestions = createReevalQuestions(
            parts,
            problemArea,
            complaintId,
            lastQuestionOrder,
            currentQuestionId === CCSBodyPickerQuestionIds.primary
              ? CCSPrimaryQuestionIds
              : CCSSecondaryQuestionIds,
            'en',
            aaWI,
            weeks,
          );
          const newEnglishQuestions = questions.concat(englishQuestions);

          const newSpanishQuestions = spanishQuestions
            ? spanishQuestions.concat(
                createReevalQuestions(
                  parts,
                  problemArea,
                  complaintId,
                  lastQuestionOrder,
                  currentQuestionId === CCSBodyPickerQuestionIds.primary
                    ? CCSPrimaryQuestionIds
                    : CCSSecondaryQuestionIds,
                  'es',
                  aaWI,
                  weeks,
                ),
              )
            : [];

          const nextQuestionIndex = getNextQuestionIndex({
            questions: newEnglishQuestions,
          });
          const nextQuestion = ((language === 'es'
            ? newSpanishQuestions
            : newEnglishQuestions) || [])?.[nextQuestionIndex];
          setCurrentQuestion(nextQuestion);
          setQuestions((prev) => {
            return [...newEnglishQuestions, ...prev];
          });
          if (setSpanishQuestions && language === 'es') {
            setSpanishQuestions(newSpanishQuestions || []);
          }
        });
        setSurveyCreated(true);
        setTimeout(() => setShow(true), 333);
      }
    }
  }, [
    complaints,
    aaWI,
    currentQuestion?.id,
    language,
    questions,
    spanishQuestions,
    weeks,
    surveyCreated,
  ]);

  const goBack = async () => {
    await disableSurvey();
    const currentQuestionIndex = questions.findIndex(
      (q: { id: string }) => q.id === currentQuestion.id,
    );
    const mostRecentQuestionThatWasAnswered = questions
      .slice(0, currentQuestionIndex)
      .reverse()
      .find((q) => q.answer);
    const mostRecentQuestionThatWasAnsweredIndex = questions.findIndex(
      (q) => q.id === mostRecentQuestionThatWasAnswered?.id,
    );
    if (!mostRecentQuestionThatWasAnswered) {
      return;
    }
    if (mostRecentQuestionThatWasAnswered?.type === 'bodypicker') {
      const thisProblemArea = mostRecentQuestionThatWasAnswered.problemArea;
      setQuestions((prev) => {
        const questionsBeforeIndex = prev.slice(
          0,
          mostRecentQuestionThatWasAnsweredIndex + 1,
        );
        return questionsBeforeIndex.filter(
          (q) => q.problemArea !== thisProblemArea || q.type === 'bodypicker',
        );
      });
    } else {
      setQuestions((prev) => {
        return prev.map((q, i) => {
          if (i > mostRecentQuestionThatWasAnsweredIndex) {
            return { ...q, answer: undefined };
          }
          return q;
        });
      });
    }
    setCurrentQuestion({ ...mostRecentQuestionThatWasAnswered, answer: null });
    await enableSurvey();
  };

  return instructions && currentQuestion?.type === 'bodypicker' && !myScreen ? (
    <CCSInstructions
      setInstructions={setInstructions}
      language={language}
      primaryColor={primaryColor}
    />
  ) : (
    currentQuestion &&
      (currentQuestion?.type === 'bodypicker' ? (
        <Body
          show={show}
          bodyPickerSubmit={bodyPickerSubmit}
          language={language}
          id={currentQuestion.id}
          primaryColor={primaryColor}
          spanishQuestions={spanishQuestions}
          questions={questions}
          currentQuestion={currentQuestion}
          setSpanishQuestions={setSpanishQuestions}
          setQuestions={setQuestions}
          setCurrentQuestion={setCurrentQuestion}
          aaWI={aaWI}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          complete={complete}
          markSurveyComplete={markSurveyComplete}
          submitSurvey={submitSurvey}
          submitVerbiage={submitVerbiage}
          setSubmitText={setSubmitText}
          validationCode={validationCode}
          surveyIds={surveyIds}
          ccsFollowups={ccsFollowups}
          updateRequest={updateRequest}
          startingProblemArea={complaints.length}
          kiosk={kiosk}
          additionalComplaints={false}
        />
      ) : (
        <SurveyQuestionComponent
          show={show}
          question={currentQuestion.question}
          type={currentQuestion.type}
          typeOptions={currentQuestion.typeOptions}
          options={currentQuestion.options}
          id={currentQuestion.id}
          onSelect={onSelect}
          disabled={disabled || !!currentQuestion.answer}
          images={currentQuestion.images}
          progress={progress}
          language={language}
          height={modalHeight.current}
          primaryColor={primaryColor}
          parts={complaints[currentQuestion.problemArea - 1]?.bodypicker?.parts}
          source={
            complaints[currentQuestion.problemArea - 1]?.bodypicker
              ?.sourceOfPain
          }
          showPrevious
          goBack={goBack}
          myScreen={myScreen}
        />
      ))
  );
};
