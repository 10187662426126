import React, { useState } from 'react';
import {
  questionContentVerbiage,
  SurveyQuestionContent,
} from './SurveyQuestionContent';
import { SurveyQuestionTitle } from './SurveyQuestionTitle';
import {
  GroupAnswer,
  SurveyQuestion,
  GroupValue,
  SurveyQuestionAnswer,
  SurveyQuestionAnswerNonGroup,
} from '@chiroup/core/types/Survey.type';

const verbiage = {
  en: {
    allQuestions: 'Please answer all questions to continue.',
  },
  es: {
    allQuestions: 'Responda todas las preguntas para continuar.',
  },
};

type Props = {
  onSelect: (val: GroupAnswer) => Promise<void>;
  disabled: boolean;
  questions?: SurveyQuestion[];
  language?: 'en' | 'es';
  primaryColor: string;
};

export const SurveyQuestionGroup: React.FC<Props> = ({
  onSelect,
  disabled,
  questions,
  language = 'en',
  primaryColor,
}) => {
  const [val, setVal] = useState<GroupValue>({});
  const [formError, setFormError] = useState<string>();

  const handleSubmit = async () => {
    setFormError('');
    if (
      !questions?.every(
        (question) => question.type === 'checkbox' || val?.[question.id],
      )
    ) {
      setFormError(verbiage[language === 'es' ? 'es' : 'en'].allQuestions);
      return;
    }

    await onSelect({ type: 'group', value: val });
    setVal({});
  };

  const onChange = async (questionId: string, val: SurveyQuestionAnswer) => {
    setVal((prev) => ({
      ...prev,
      [questionId]: val as SurveyQuestionAnswerNonGroup,
    }));
  };

  return (
    <>
      {questions?.map((question) => (
        <div key={question.id} className="mb-10">
          <SurveyQuestionTitle title={question.question} />
          <SurveyQuestionContent
            id={question.id}
            type={question.type}
            typeOptions={question.typeOptions}
            options={question.options}
            onSelect={onChange.bind(null, question.id)}
            disabled={disabled}
            images={question.images}
            isGroup
            primaryColor={primaryColor}
          />
        </div>
      ))}
      <div className="text-red-500">{formError}</div>
      <button
        onClick={handleSubmit}
        disabled={disabled}
        className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-4 mb-8"
        style={{
          backgroundColor: primaryColor,
        }}
      >
        {questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
      </button>
    </>
  );
};
