import { Phone } from '@chiroup/components';
import { Patient } from '@chiroup/core/types/Patient.type';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import Button, { ButtonColors } from '../common/Button';
import KioskPatientSelect from './KioskPatientSelect';

type Props = {
  phone?: string;
  setPhone: (phone: string) => void;
  handlePhoneSubmit: (phone: string) => Promise<void>;
  handlePatientSubmit: (patientId: string) => Promise<boolean | void>;
  noSurveyMessage: string | null;
  handleReset: (changeScreen?: boolean) => void;
  patients?: Partial<Patient>[];
  error?: string;
  loading: boolean;
  clinicName: string;
  clinicLogo: string;
};

const KioskEnterPhone: React.FC<Props> = ({
  phone,
  setPhone,
  handlePhoneSubmit,
  handlePatientSubmit,
  handleReset,
  noSurveyMessage,
  error,
  patients,
  loading,
  clinicName = 'our clinic',
  clinicLogo,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const [phoneError, setPhoneError] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const phoneValidation = (phone: string) => {
    if (
      phone?.[1] === '1' &&
      phone?.split(' ')?.[0]?.length === 6 &&
      phone?.length < 13
    ) {
      return 'Phone number must be 7 digits.';
    } else if (phone?.[1] === '1' && phone?.length < 13) {
      return 'Phone number must be 10 digits.';
    }
    return '';
  };

  const onChangePhone = (phone: string) => {
    setPhone(phone);
    setPhoneError(phoneValidation(phone));
  };

  const onClickSubmit = () => {
    if (phone) {
      handlePhoneSubmit(phone);
    }
  };

  const handleFormReset = () => {
    setPhone('');
    setPhoneError('');
  };

  useEffect(() => {
    if (location.pathname !== '/kiosk') {
      navigate('/kiosk');
    }
  }, []);

  return (
    <div className="h-full min-h-screen py-8 bg-white sm:py-12 lg:py-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-24">
        <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div>
              {!!clinicLogo && (
                <img
                  src={clinicLogo}
                  alt={clinicName}
                  className="h-auto w-64"
                />
              )}
            </div>
            <div className="mt-20 flex flex-col">
              <div>
                <div className="inline-flex space-x-4">
                  <div
                    className="px-2.5 py-1 text-xs font-semibold tracking-wide uppercase relative w-full h-full flex items-center justify-center"
                    style={{
                      color: primaryColor,
                    }}
                  >
                    <div
                      className="absolute top-0 left-0 z-0 w-full h-full rounded opacity-5"
                      style={{
                        backgroundColor: primaryColor,
                      }}
                    />
                    <div className="z-10">Check in</div>
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="grid grid-cols-1 text-4xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-5xl">
                  <span className="col-span-1">
                    Welcome {clinicName ? 'to' : ''}
                  </span>
                  <span className="col-span-1">{clinicName}</span>
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Enter your phone number below to get started.
                </p>
              </div>
              <form
                className="mt-12 sm:max-w-lg sm:w-full flex flex-col sm:flex-row gap-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  onClickSubmit();
                }}
                data-cy="form"
              >
                <div className="flex-1 min-w-0">
                  <Phone
                    className="text-4xl"
                    disabled={!!patients?.length}
                    name="phone"
                    value={phone}
                    onChange={onChangePhone}
                    autoFocus
                    errors={phoneError ? { message: phoneError } : undefined}
                    international={false}
                  />
                </div>

                {!patients?.length && (
                  <div className="self-left sm:ml-3 h-10">
                    <Button
                      text="Submit"
                      color={ButtonColors.primary}
                      type="button"
                      disabled={!phone || !!phoneError}
                      loading={loading}
                      onClick={onClickSubmit}
                      className="cursor-pointer h-full items-center flex"
                      data-cy="button"
                    />
                  </div>
                )}
              </form>
              <div>
                {error && <div className="mt-4 text-red-600">{error}</div>}
              </div>
              {!!patients?.length && (
                <KioskPatientSelect
                  loading={loading}
                  noSurveyMessage={noSurveyMessage}
                  handleReset={handleReset}
                  handlePatientSubmit={handlePatientSubmit}
                  handleFormReset={handleFormReset}
                  patients={patients}
                />
              )}
              <div className="mt-6">
                <div className="flex-1 min-w-0 py-1 text-sm text-gray-500 sm:py-3">
                  <span className="font-medium text-gray-900">Powered</span> by{' '}
                  <span
                    className="font-medium"
                    style={{
                      color: primaryColor,
                    }}
                  >
                    ChiroUp
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 w-screen left-1/2 bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-300"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              {/* <img
                className="w-full max-h-screen rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src={kioskImage}
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KioskEnterPhone;
