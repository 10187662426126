import { RequestStep } from '@chiroup/core/types/Request.type';
import React, { useContext, useState } from 'react';
import { MeContext } from '../../../contexts/me.context';
import requestService from '../../../services/request.service';
import Button, { ButtonColors } from '../../common/Button';
import UploadImgSide from './UploadImgSide';

type Props = {
  step: RequestStep;
  requestId: string;
  complete: (goToNext?: boolean) => Promise<void>;
  nextStep: (id?: string) => void;
  kiosk?: boolean;
  validationCode: string | number;
  prMap: { [key: string]: string };
  language?: string;
};

const UploadPhotoId: React.FC<Props> = ({
  step,
  requestId,
  nextStep,
  complete,
  validationCode,
  prMap,
  kiosk,
  language = 'en',
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const [frontImagePreview, setFrontImagePreview] = useState<string | null>(
    null,
  );
  const [backImagePreview, setBackImagePreview] = useState<string | null>(null);
  const [skipping, setSkipping] = useState<boolean>(false);

  const remove = () => {
    setFrontImagePreview(null);
    setBackImagePreview(null);
  };

  const skip = async () => {
    try {
      setSkipping(true);
      const requestIdTouse = prMap?.uploadPhotoId || requestId;
      await requestService.skipUploadPhotoId(requestIdTouse, validationCode);
      await complete(true);
    } catch (err) {
      console.error(err);
    }
    setSkipping(false);
  };

  return (
    <div className="flex items-center justify-center px-4 mt-8 sm:min-h-screen sm:mt-0">
      <div className="max-w-4xl py-8">
        <h2 className="text-4xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          <span className="flex flex-row">
            {language === 'es' && step?.esTitle ? step.esTitle : step.title}
            {step.complete && (
              <svg
                className="w-10 h-10 ml-2 opacity-50 place-self-center"
                style={{
                  color: primaryColor,
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>
        </h2>
        <h3 className="mt-2 text-xl font-extrabold leading-6 tracking-tight text-gray-900 sm:text-2xl sm:leading-none md:text-4xl">
          <span
            style={{
              color: primaryColor,
            }}
          >
            {language === 'es' && step?.esDescription
              ? step.esDescription
              : step.description}
          </span>
        </h3>
        {language === 'es' ? (
          <p className="max-w-md mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Nuestra oficina necesita una foto oficial de usted (licencia o
            alguna otra identificación) para verificar su identidad, para
            nuestros records y para ser utilizados con su seguro médico. Por
            favor asegurese que se pueda leer claramente.
          </p>
        ) : (
          <p className="max-w-md mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Our office needs a state issued photo ID to verify your identity and
            to have on record for insurance purposes. Please make sure the photo
            or scan or your ID is clear and easy to read.
          </p>
        )}
        <div className="flex-row justify-around hidden mt-4 -mb-8 sm:flex">
          <p className="font-bold">
            {language === 'es' ? 'Parte Frontal' : 'Front'}
          </p>{' '}
          <p className="font-bold">
            {language === 'es' ? 'Parte Posterior' : 'Back'}
          </p>
        </div>

        <div className="flex sm:flex-row flex-col w-full">
          <p className="font-bold mt-4 -mb-8 sm:hidden self-center">
            {language === 'es' ? 'Parte Frontal' : 'Front'}
          </p>
          <UploadImgSide
            side="front"
            requestId={requestId}
            validationCode={validationCode}
            prMap={prMap}
            imagePreview={frontImagePreview}
            setImagePreview={setFrontImagePreview}
            type="photoId"
          />
          <p className="self-center mt-4 -mb-8 font-bold sm:hidden">
            {language === 'es' ? 'Parte Posterior' : 'Back'}
          </p>
          <UploadImgSide
            side="back"
            requestId={requestId}
            validationCode={validationCode}
            prMap={prMap}
            imagePreview={backImagePreview}
            setImagePreview={setBackImagePreview}
            type="photoId"
          />
        </div>
        <div className="mt-16">
          {(frontImagePreview?.length || backImagePreview?.length) && (
            <Button text="Next" onClick={complete} />
          )}
          {frontImagePreview || backImagePreview ? (
            <Button
              text={language === 'es' ? 'Intenta otra vez' : 'Try again'}
              onClick={remove}
              color={ButtonColors.primary}
              className="ml-4"
            />
          ) : step.complete ? null : (
            <Button
              text={language === 'es' ? 'Saltar' : 'Skip'}
              onClick={skip}
              loading={skipping}
              color={ButtonColors.primary}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadPhotoId;
