import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import KioskSelect from './components/kiosk/KioskSelect';
import DirectRequest from './components/request/DirectRequest';
import RequestAuth from './components/request/RequestAuth';
import RequestCodeInput from './components/request/RequestCodeInput';
import requestService from './services/request.service';
import EmailSubscription from './components/email-subscription/EmailSubscription';
import { Genders } from '@chiroup/core/enums/Genders.enum';
import { RequestStep } from '@chiroup/core/types/Request.type';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const queryClient = new QueryClient();

function App() {
  const [authState, setAuthState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [requestId, setRequestId] = useState<string>();
  const [authResponse, setAuthResponse] = useState<string>();
  const [methods, setMethods] = useState<{
    phone: string;
    email: string;
    language: 'en' | 'es';
  }>();
  const [request, setRequest] = useState<any>(null);

  const [timeLeft, setTimeLeft] = useState(300);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    if (pathParts.length && !pathParts.includes('kiosk') && !request) {
      setRequestId(pathParts[2]);
    }
  }, [request]);

  useEffect(() => {
    if (authResponse?.includes('A verification code was sent to')) {
      const countdown = () => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timerIdRef.current!);
            setInvalidCode(false);
            return 0;
          }
        });
      };
      timerIdRef.current = setInterval(countdown, 1000);
    } else {
      if (timerIdRef.current !== null) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    }
    return () => {
      if (timerIdRef.current !== null) {
        clearInterval(timerIdRef.current);
      }
    };
  }, [authResponse]);

  const handleSubmitCode = async (code: number) => {
    if (!code) {
      return;
    }
    await sendValidationCode(code);
  };

  const validate = useCallback(async () => {
    if (!requestId) return;
    setError('');
    setLoading(true);
    try {
      const res = await requestService.authRequest({
        requestId: requestId ?? '',
      });
      setLoading(false);
      if (res?.data?.validationCode) {
        setAuthState(true);
        setRequest({ ...res.data, id: requestId });
        setError('');
      } else {
        setError('');
        if (res.data?.phone && res.data?.email) {
          setMethods({
            phone: res.data.phone,
            email: res.data.email,
            language: res.data.language ?? 'en',
          });
        } else if (res.message) {
          setAuthResponse(res.message);
        }
      }
    } catch (err: any) {
      console.error({ err });
      reset();
      const errMessage = err?.message || 'Failed to get request.';
      setError(errMessage);
    }
  }, [requestId]);

  const sendValidationCode = async (validationCode: number) => {
    setInvalidCode(false);
    setLoading(true);

    try {
      const res = await requestService.authRequest({
        validationCode,
        requestId: requestId ?? '',
      });
      setError('');
      setLoading(false);
      if (res?.data?.validationCode) {
        setAuthState(true);
        setRequest({ ...res.data, id: requestId });
        setError('');
      } else {
        setAuthState(false);
        setError('Something went wrong');
      }
      setLoading(false);
    } catch (err: any) {
      console.error({ err });
      setLoading(false);
      setInvalidCode(true);
      reset();
      const errMessage = err?.message || 'Failed to get request.';
      setError(errMessage);
    }
  };

  const submitMethod = async (method: string) => {
    setInvalidCode(false);
    try {
      setLoading(true);
      const res = await requestService.authRequest({
        method,
        requestId: requestId ?? '',
      });
      setError('');
      setAuthResponse(res.message);
      setLoading(false);
    } catch (err: any) {
      console.error({ err });
      reset();
      const errMessage = err?.message || 'Something went wrong.';
      setError(errMessage);
    }
  };
  const handleNewCode = () => {
    setTimeLeft(300);
    setAuthResponse('');
  };

  const reset = () => {
    setLoading(false);
    setTimeLeft(300);
    setAuthResponse('');
    setAuthState(false);
    setRequest(null);
  };

  const updateRequest = (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => {
    setRequest((prev: any) => ({ ...prev, ...data }));
    if (callback) {
      callback();
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SentryRoutes>
          <Route path="/kiosk/*" element={<KioskSelect />} />
          <Route path="/terminal/*" element={<KioskSelect />} />
          <Route
            path="/requests/:id/*"
            element={
              authState && requestId ? (
                <DirectRequest
                  id={requestId}
                  data={request}
                  updateRequest={updateRequest}
                  handleReset={reset}
                />
              ) : !authResponse ? (
                <RequestAuth
                  handleSubmitMethod={submitMethod}
                  handleSubmit={validate}
                  methods={methods}
                  error={error}
                  loading={loading}
                  invalidCode={invalidCode}
                />
              ) : (
                <RequestCodeInput
                  onHandleNewCode={handleNewCode}
                  loading={loading}
                  authResponse={authResponse}
                  onSubmitCode={handleSubmitCode}
                  language={methods?.language}
                  timeLeft={timeLeft}
                  invalidCode={invalidCode}
                />
              )
            }
          />
          <Route path="/email-subscription" element={<EmailSubscription />} />
        </SentryRoutes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
