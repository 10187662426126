import { Input, InputMasked } from '@chiroup/components';
import { Patient } from '@chiroup/core/types/Patient.type';
import { EmailValidator, useForm } from '@chiroup/hooks';
import React, { useState } from 'react';
import { KioskScreens } from '../../helpers/constants';
import kioskService from '../../services/kiosk.service';
import Button from '../common/Button';
import RequestComplete from '../request/RequestComplete';

const validation = {
  email: {
    required: {
      message: 'Email is required.',
    },
    pattern: EmailValidator,
  },
  dateOfBirth: {
    required: {
      message: 'Date of birth is required.',
    },
  },
  fname: {
    required: {
      message: 'Gender is required.',
    },
  },
  lname: {
    required: {
      message: 'Occupation is required.',
    },
  },
};

type Props = {
  handlePatientSubmit: (patientId: string) => Promise<boolean | void>;
  changeScreens: (newScreen: KioskScreens, logoutAfter?: number) => void;
  phone?: string;
};

const KioskSimpleDemographicForm: React.FC<Props> = ({
  handlePatientSubmit,
  changeScreens,
  phone,
}) => {
  const { value, registerSubmit, errors, onChange } = useForm<Patient>(
    {},
    validation,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noSurvey, setNoSurvey] = useState(false);
  const [existingPatient, setExistingPatient] = useState<{
    language: 'en' | 'es';
  }>({
    language: 'en',
  });

  const onSubmit = async (val: Partial<Patient>) => {
    setIsSubmitting(true);
    const pendingPatient = await kioskService.createPendingPatient({
      ...val,
      phone,
    });
    setExistingPatient({
      language: pendingPatient.language === 'es' ? 'es' : 'en',
    });
    return pendingPatient;
  };

  const onSuccess = async (res: any) => {
    if (res?.existingPatient?.ID) {
      const patientRes = await handlePatientSubmit(res.existingPatient.ID);
      if (patientRes) {
        setIsSubmitting(false);
        setNoSurvey(true);
      }
    } else if (res?.pendingPatient?.ID) {
      changeScreens(KioskScreens.NeedAssistancePending, 15000);
    } else {
      throw new Error('Something went wrong.');
    }
  };

  const onFail = async (err: any) => {
    console.error({ err });
  };

  return !noSurvey ? (
    <div className="flex items-center justify-center px-4 sm:min-h-screen mt-8 sm:mt-0">
      <div className="max-w-4xl py-8">
        <h2 className="text-4xl tracking-tight leading-8 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          <span className="flex flex-row">Tell us about yourself</span>
        </h2>
        <p className="mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
          Our office needs a little more information about you. Please complete
          the following questions to the best of your ability.
        </p>
        <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 mt-8">
          <Input
            name="fname"
            className="sm:col-span-2"
            label="First name *"
            value={value.fname}
            onChange={onChange('fname')}
            errors={errors.fieldErrors?.fname}
          />
          <Input
            name="lname"
            className="sm:col-span-2"
            label="Last name *"
            value={value.lname}
            onChange={onChange('lname')}
            errors={errors.fieldErrors?.lname}
          />
          <Input
            name="email"
            className="sm:col-span-2"
            label="Email *"
            value={value.email}
            onChange={onChange('email')}
            errors={errors.fieldErrors?.email}
          />
          <InputMasked
            className="sm:col-span-2"
            name="dateOfBirth"
            label="Date of birth *"
            value={value.dateOfBirth}
            onChange={onChange('dateOfBirth')}
            errors={errors.fieldErrors?.dateOfBirth}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        </div>
        <div className="mt-8">
          <Button
            text="Submit"
            onClick={registerSubmit(onSubmit, {
              onSuccess,
              onFail,
            })}
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>
  ) : (
    <RequestComplete
      language={existingPatient?.language}
      kiosk={true}
      complete={true}
    />
  );
};

export default KioskSimpleDemographicForm;
